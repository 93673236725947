import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  CometChatThemeContext,
  CometChatConversationsWithMessages,
} from '@cometchat/chat-uikit-react';

import { CometChat } from "@cometchat/chat-sdk-javascript";
import TopBar from "../TopBar";
import { DEFAULT_THEME_COLOR } from "../../constants/theme";
import {useMediaQuery} from "react-responsive";
import {useLocation} from "react-router-dom";

import "./style.css";

const COMET_CHAT_GROUP_USERS_LIMIT = 100;

const CometChatComponent = (props) => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isDesktopDefaultHeight = '95vh';
  const mobileTabletDefaultHeight = '75vh';
  const [chatHeight, setChatHeight] = useState(isDesktop ? isDesktopDefaultHeight : mobileTabletDefaultHeight);
  const location = useLocation();
  const selectedGroupId = location?.state?.selectedGroupId || null;
  const [group, setGroup] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const results = await CometChat.getUnreadMessageCount();
      let unreadMessagesCount = 0;
      if (results && results.users) {
        Object.keys(results.users).forEach(key => {
          unreadMessagesCount += results.users[key];
        });
      }

      if (results && results.groups) {
        Object.keys(results.groups).forEach(key => {
          unreadMessagesCount += results.groups[key];
        });
      }

      setUnreadMessagesCount(unreadMessagesCount);

      setGroup(null);
      if (selectedGroupId) {
        const getGroup = async () => {
          const group = await CometChat.getGroup(selectedGroupId);
          setGroup(group);
        };
        getGroup();
      }
    };
    fetchData();


    const handleResize = () => {
      if (window.innerHeight < 500) {
        setChatHeight('50vh');
      } else {
        setChatHeight(isDesktop ? isDesktopDefaultHeight : mobileTabletDefaultHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { theme } = useContext(CometChatThemeContext);
  theme.palette.setMode(props?.currentUser?.themeMode || props?.newThemeMode || 'dark');
  theme.palette.setPrimary({ light: DEFAULT_THEME_COLOR, dark: DEFAULT_THEME_COLOR });
  theme.palette.setAccent({ light: "#373a3c" });

  const usersRequestBuilder = new CometChat.UsersRequestBuilder()
    .setLimit(COMET_CHAT_GROUP_USERS_LIMIT);

  const groupsRequestBuilder = new CometChat.GroupsRequestBuilder()
    .setLimit(COMET_CHAT_GROUP_USERS_LIMIT);
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 320 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);

  return (
    <>
      <TopBar />
      <div
        style={{
          marginTop: 15, height: chatHeight,
          paddingLeft,
      }}
      >
        <CometChatThemeContext.Provider value={{ theme }}>
          <CometChatConversationsWithMessages
            usersConfiguration={{ usersRequestBuilder: usersRequestBuilder }}
            groupsConfiguration={{ groupsRequestBuilder: groupsRequestBuilder }}
            isMobileView={true}
            group={group}
          />
        </CometChatThemeContext.Provider>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.common.currentUser,
    newThemeMode: state.common.newThemeMode,
    leftNavigationOpen: state.common.leftNavigationOpen,
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CometChatComponent);
