import ListErrors from './ListErrors';
import React from 'react';
import agent from '../agent';
import {connect} from 'react-redux';
import {
  UPDATE_FIELD_AUTH,
  RESET_PASSWORD,
  RESET_PASSWORD_UNLOADED
} from '../constants/actionTypes';
import TopBar from "./TopBar";
import useTranslation from "../customHooks/translations";
import LogoComponent from "./LogoComponent";
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from "react-router-dom";

const ResetPassword = (props) => {
  const translation = useTranslation();
  const recaptchaRef = React.createRef();

  const changeEmail = ev => props.onChangeEmail(ev.target.value);
  const submitForm = (email) => ev => {
    const token = recaptchaRef.current.getValue();
    ev.preventDefault();
    props.onSubmit(email, token);
  };

  return (
    <>
      <TopBar />
      <div className="auth-page">
        <div className="container page">
          <LogoComponent />
          <div className="row">

            <div className="col-md-6 offset-md-3 col-xs-12">
              <h1 className="text-xs-center">{translation.resetPassword.label}</h1>
              <p className="text-xs-center">
                <Link to="/login">
                  {translation.register.haveAnAccount}
                </Link>
              </p>
              <ListErrors errors={props.errors}/>

              <form onSubmit={submitForm(props.email)}>
                <fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="email"
                      placeholder="Email"
                      value={props.email}
                      onChange={changeEmail}/>
                  </fieldset>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECHAPTA_SITE_KEY}
                  />
                  <button
                    className="btn btn-lg btn-primary pull-xs-right"
                    type="submit"
                    disabled={props.inProgress}>
                    {translation.resetPassword.sendEmail}
                  </button>

                </fieldset>
              </form>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({...state.auth});

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({type: UPDATE_FIELD_AUTH, key: 'email', value}),
  onSubmit: (email, token) => {
    const payload = agent.Auth.resetPassword(email, token);
    dispatch({type: RESET_PASSWORD, payload});
    dispatch({type: RESET_PASSWORD_UNLOADED, payload});
  },
  onUnload: () =>
    dispatch({type: RESET_PASSWORD_UNLOADED})
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
