import React from 'react'
import ReactPlayer from 'react-player/youtube';
import Box from "@mui/material/Box";

const YoutubePlayer = (props) => {
  return (
    <Box sx={{ width: "100%", height: 380 }}>
      <ReactPlayer
        url={props.url}
        width={"100%"}
        controls={true}
      />
    </Box>
  );
};

export default YoutubePlayer;
