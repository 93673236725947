import React, { useState, useEffect } from 'react';
import TopBar from "../TopBar";

import agent from "../../agent";
import { useParams } from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import FreakListFollowersComponent from "../List/FreakListFollowersComponent";

const FollowedByView = (props) => {
  const params = useParams();
  const translation = useTranslation();
  const [followedBy, setFollowedBy] = useState([]);
  const username = params.username.replace('@', '');

  useEffect(() => {
    const fetchData = async () => {
      const followedBy = await agent.Profile.getProfileFollowers(username);
      setFollowedBy(followedBy);
    };

    fetchData();
  }, []);

  return (
    <div>
      <TopBar/>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '80vh',
        paddingTop: 30,
      }}>
        {
          followedBy && followedBy.length
            ? <FreakListFollowersComponent
              title={`${translation.followedBy.title}`}
              secondaryTextKey={"bio"}
              secondaryTextToConcat={""}
              data={followedBy}
            />
            : null
        }
      </div>

    </div>
  )
};

export default FollowedByView;
