import React, { useRef, useEffect, useState } from 'react';

import useTranslation from "../../customHooks/translations";
import TextField from "@mui/material/TextField";

const gf = new GiphyFetch('sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh'); // @todo
import { Grid } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'

const GiphyComponent = props => {
  const translation = useTranslation();
  const [term, setTerm] = useState('freak');
  const [limit, setLimit] = useState(10);
  const containerRef = useRef(null);

  const onChange = (event) => {
    setTerm(event.target.value);
  };
  const fetchGifs = (offset) =>  gf.search(term, { offset, limit });

  useEffect(() => {
    const calculateLimit = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight;
        const gifHeight = 150;
        const columns = 3;
        const rows = Math.floor(containerHeight / gifHeight);
        setLimit(columns * rows);
      }
    };

    calculateLimit();
    window.addEventListener('resize', calculateLimit);
    return () => window.removeEventListener('resize', calculateLimit);
  }, []);

  return (
    <>
        <TextField
          value={term}
          placeholder={translation.giphy.placeholders.search}
          onChange={onChange}
          fullWidth
        />
      <div ref={containerRef} style={{ height: '350px', overflowY: 'scroll' }}>
        <Grid
          width={350}
          columns={3}
          noLink={true}
          hideAttribution={true}
          fetchGifs={fetchGifs}
          key={term}
          onGifClick={(gif) => {
            props.createComment(null, gif);
            props.setGifsVisible(false);
          }}
        />
      </div>
    </>
  );
};

export default GiphyComponent;
