const convertToBase64 = (file) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    }
  })
};

module.exports = {
  convertToBase64
};
