import React from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import Grid from "@mui/material/Grid";

const FreakListFollowersComponent = (props) => {
  const navigate = useNavigate();

  const calculateStyles = idx => {
    return {}
  };

  return (
    <>
      <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
        {
          (props.data || []).map((item, idx) => (
            <>
              <ListItem
                alignItems="flex-start"
                onClick={() => navigate(`/@${item.username}`)}
                sx={calculateStyles(idx)}
              >
                <ListItemAvatar>
                  <Avatar alt={item.id} src={item.imageUrl || './icon-48x48.png'} />
                </ListItemAvatar>
                <Grid container>
                  <Grid container xs={12} sx={{ marginTop: 1, }}>
                    <React.Fragment>
                      {item.username}
                      <Avatar
                        alt={item.id}
                        sx={{
                          width: 14,
                          height: 14,
                          marginLeft: 2,
                          marginRight: 0.5,
                          marginTop: 0.5,
                        }}
                        src={'./../icon-48x48.png'}
                      />
                      {item.points}F
                    </React.Fragment>
                  </Grid>
                  <Grid container xs={12}>
                    <React.Fragment>
                      <span style={{
                        color: 'grey',
                        fontSize: 12,
                      }}>
                        {`@${item.username}`}
                      </span>
                    </React.Fragment>
                  </Grid>
                </Grid>

              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))
        }
      </List>
    </>
  )
};

export default FreakListFollowersComponent;
