import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import useTranslation from "../../customHooks/translations";
import {Link, useNavigate, useParams} from "react-router-dom";
import TopBar from "../TopBar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Typography from "@mui/material/Typography";
import ListErrors from "../ListErrors";
import Thumbnails from "../Thumbnails";
import UploadFiles from "../UploadFiles";
import FreakParticipantsList from "./FreakParticipantsList";
import FreakSelect from "./FreakSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import DatePickerComponent from "../DatePickerComponent";
import {
  getYouTubeLinkVideoId, handleAddFacebookLink, handleAddInstagramLink,
  handleAddYouTubeLink,
  validateFacebookLink, validateInstagramLink,
  validateYouTubeLink
} from "../../utils/freakUtils";
import Grid from "@mui/material/Grid";
import CarouselComponent from "../Embed/CarouselComponent";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ThumbnailsEmbed from "../Embed/ThumbnailsEmbed";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  CAMPAIGN_SUBMITTED,
  UPDATE_FIELD_FREAK_CAMPAIGN,
  UPLOAD_FREAK_VIDEO,
  CAMPAIGN_EDITOR_PAGE_LOADED,
  CAMPAIGN_EDITOR_PAGE_UNLOADED
} from "../../constants/actionTypes";
import agent from "../../agent";

const onParticipantSelect = (participants, setParticipants) => {
  const updatedParticipants = participants || [];
  for (const participant of participants) {
    const alreadyExists = updatedParticipants?.find(item => item.username === participant.username);
    if (!alreadyExists) {
      updatedParticipants.push(participant);
    }
  }
  setParticipants(updatedParticipants)
};

const onFreakStartDateChange = (startDate, setStartDate) => {
  setStartDate(startDate);
};

const submitForm = (event, props) => {
  event.preventDefault();

  const campaign = {
    title: props.title,
    description: props.description,
    context: props.context,
    images: props.images?.map(image => image.name),
    videos: props.videos?.map(video => video.name),
    linksYoutube: props.linksYoutube?.filter(linkYoutube => {
      if (!linkYoutube.id && linkYoutube.name) {
        return linkYoutube.name;
      }
      if (!linkYoutube.id && !linkYoutube.name) {
        return linkYoutube;
      }
    }),
    linksFacebook: props.linksFacebook?.filter(linkFacebook => {
      if (!linkFacebook.id && linkFacebook.name) {
        return linkFacebook.name;
      }
      if (!linkFacebook.id && !linkFacebook.name) {
        return linkFacebook;
      }
    }),
    linksInstagram: props.linksInstagram?.filter(linkInstagram => {
      if (!linkInstagram.id && linkInstagram.name) {
        return linkInstagram.name;
      }
      if (!linkInstagram.id && !linkInstagram.name) {
        return linkInstagram;
      }
    }),
    body: props.body,
    participants: props?.participants?.map(participant => participant.username) || [],
    startDate: props.startDate,
    public: props.isPrivate !== undefined ? !props.isPrivate : true,
  };

  const slug = {slug: props.slug};
  const promise = props.slug
    ? agent.Campaigns.update(Object.assign(campaign, slug))
    : agent.Campaigns.create(campaign);

  props.onSubmit(promise);
};

const FreakCampaignsEditor = (props) => {
  const context = props.context;
  const device = props.device;

  const translation = useTranslation();
  const navigate = useNavigate();
  const {slug} = useParams();
  const [title, setTitle] = useState('');
  const [oldTitle, setOldTitle] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [linksYoutube, setLinksYoutube] = useState([]);
  const [linksFacebook, setLinksFacebook] = useState([]);
  const [linksInstagram, setLinksInstagram] = useState([]);
  const [body, setBody] = useState('');
  const [participants, setParticipants] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  // const [isPrivate, setIsPrivate] = useState(false);

  const [isValidYouTubeLink, setIsValidYouTubeLink] = useState(true);
  const [youTubeLink, setYouTubeLink] = useState('');

  const [isValidFacebookLink, setIsValidFacebookLink] = useState(true);
  const [facebookLink, setFacebookLink] = useState('');

  const [isValidInstagramLink, setIsValidInstagramLink] = useState(true);
  const [instagramLink, setInstagramLink] = useState('');

  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // @todo
    };

    fetchData();
  }, []);

  useEffect(() => {
    setSaveDisabled(!(title.trim() && body.trim()));
  }, [title, body]);

  const handleImageUpload = (file) => {
    const newImage = {
      id: file?.data?.id,
      imageUrl: file?.data?.fileUrl,
      name: file?.data?.filename,
    };
    setImages((prevImages) => [...prevImages, newImage]);
  };

  return (
    <>
      <TopBar/>
      <div className="editor-page">
        <div className="banner">
          <IconButton sx={{color: 'white'}} aria-label="back" onClick={() => {
            navigate(-1);
          }}>
            <ArrowBackIosNewOutlinedIcon sx={{
              marginRight: 2,
              color: DEFAULT_THEME_COLOR
            }}
            />
            <Typography variant="h4" sx={{color: 'white'}}>
              {title}
            </Typography>
          </IconButton>
        </div>
        <div className="container page">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-xs-12">
              <ListErrors errors={props.errors}></ListErrors>

              <form>
                <fieldset>
                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      placeholder={translation.campaignEditor.placeholders.freakTitle}
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                  </fieldset>

                  {
                    images && images.length
                      ? <Thumbnails images={images} handleDeleteFn={setImages}/>
                      : null
                  }

                  <fieldset className="form-group">
                    <UploadFiles
                      onFileUpload={handleImageUpload}
                      setSaveDisabled={setSaveDisabled}
                      dest={"campaign"}
                    />
                  </fieldset>

                  <fieldset className="form-group">
                      <textarea
                        className="form-control form-control-lg"
                        rows="8"
                        placeholder={translation.campaignEditor.placeholders.freakContent}
                        value={body}
                        onChange={(event) => {
                          setBody(event?.target?.value);
                        }}
                      >
                      </textarea>
                  </fieldset>

                  {
                    participants && participants.length
                      ? <>
                        <hr/>
                        <FreakParticipantsList participants={participants}/>
                      </>
                      : null
                  }

                  <hr/>
                  <FreakSelect
                    onSelect={(participants => onParticipantSelect(participants, setParticipants))}
                  />

                  {/*<FormGroup sx={{marginTop: 2, marginLeft: 2}}>*/}
                  {/*  <FormControlLabel*/}
                  {/*    control={*/}
                  {/*      <Checkbox*/}
                  {/*        checked={isPrivate}*/}
                  {/*        onChange={(event, value) => {*/}
                  {/*          setIsPrivate(value);*/}
                  {/*        }}*/}
                  {/*      />*/}
                  {/*    }*/}
                  {/*    label={translation.campaignEditor.isPrivateLabel}*/}
                  {/*  />*/}
                  {/*</FormGroup>*/}

                  <hr/>
                  <DatePickerComponent
                    value={startDate}
                    onChange={(startDate) => onFreakStartDateChange(startDate, setStartDate)}
                  />

                  <hr/>
                  {
                    linksYoutube && linksYoutube.length
                      ? <Thumbnails
                        images={linksYoutube.map(link => {
                          return link.id
                            ? {
                              ...link,
                              imageUrl: link.imageUrl || link.thumbnail,
                            }
                            : {
                              name: getYouTubeLinkVideoId(link),
                              imageUrl: link.imageUrl || `https://img.youtube.com/vi/${getYouTubeLinkVideoId(link)}/hqdefault.jpg`,
                            }
                        })}
                        handleDeleteFn={setLinksYoutube}/>
                      : null
                  }

                  <fieldset className="form-group">
                    <Grid container>
                      <Grid item xs={12}>
                        {
                          linksYoutube?.length
                            ? <CarouselComponent youtubeEmbeds={linksYoutube.map(link => link.url)}/>
                            : null
                        }
                      </Grid>
                      <Grid container sx={{
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Grid item xs={2}>
                          <IconButton
                            component={Link}
                            to={"https://www.youtube.com"}
                            aria-label="Navigate"
                          >
                            <YouTubeIcon sx={{color: '#FF0000', width: 40, height: 40}}/>
                          </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            label={translation.linksYoutube.placeholders.add}
                            variant="outlined"
                            fullWidth
                            value={youTubeLink}
                            onChange={(e) => validateYouTubeLink(e.target.value, setIsValidYouTubeLink, setYouTubeLink)}
                            error={!isValidYouTubeLink}
                            helperText={!isValidYouTubeLink && translation.linksYoutube.placeholders.invalidLink}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              setYouTubeLink('');
                              setIsValidYouTubeLink(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            disabled={youTubeLink && !isValidYouTubeLink}
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              handleAddYouTubeLink(youTubeLink, linksYoutube, setLinksYoutube, setYouTubeLink);
                            }}
                          >
                            <AddIcon sx={{color: DEFAULT_THEME_COLOR}}/>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </fieldset>

                  {
                    linksFacebook && linksFacebook.length
                      ? <ThumbnailsEmbed
                        embeds={linksFacebook.map(link => {
                          return link.id
                            ? {
                              ...link,
                              imageUrl: link.url,
                            }
                            : {
                              name: link,
                              imageUrl: link
                            }
                        })}
                        handleDeleteFn={setLinksFacebook}
                        embedType={'facebook'}
                      />
                      : null
                  }

                  <fieldset className="form-group">
                    <Grid container>
                      <Grid item xs={12}>
                        {
                          linksFacebook?.length
                            ? <CarouselComponent facebookEmbeds={linksFacebook.map(link => link.url)}/>
                            : null
                        }
                      </Grid>
                      <Grid container sx={{
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Grid item xs={2}>
                          <IconButton
                            component={Link}
                            to={"https://www.facebook.com"}
                            aria-label="Navigate"
                          >
                            <FacebookIcon sx={{color: '#1877F2', width: 40, height: 40}}/>
                          </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            label={translation.linksFacebook.placeholders.add}
                            variant="outlined"
                            fullWidth
                            value={facebookLink}
                            onChange={(e) => validateFacebookLink(e.target.value, setIsValidFacebookLink, setFacebookLink)}
                            error={!isValidFacebookLink}
                            helperText={!isValidFacebookLink && translation.linksFacebook.placeholders.invalidLink}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              setFacebookLink('');
                              setIsValidFacebookLink(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            disabled={facebookLink && !isValidFacebookLink}
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              handleAddFacebookLink(facebookLink, linksFacebook, setLinksFacebook, setFacebookLink);
                            }}
                          >
                            <AddIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </fieldset>

                  {
                    linksInstagram && linksInstagram.length
                      ? <ThumbnailsEmbed
                        embeds={linksInstagram.map(link => {
                          return link.id
                            ? {
                              ...link,
                              imageUrl: link.url,
                            }
                            : {
                              name: link,
                              imageUrl: link
                            }
                        })}
                        handleDeleteFn={setLinksInstagram}
                        embedType={'instagram'}
                      />
                      : null
                  }

                  <fieldset className="form-group">
                    <Grid container>
                      <Grid item xs={12}>
                        {
                          linksInstagram?.length
                            ? <CarouselComponent instagramEmbeds={linksInstagram.map(link => link.url)}/>
                            : null
                        }
                      </Grid>
                      <Grid container sx={{
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Grid item xs={2}>
                          <IconButton
                            component={Link}
                            to={"https://www.instagram.com"}
                            aria-label="Navigate"
                          >
                            <InstagramIcon sx={{color: 'rgb(30, 48, 80)', width: 40, height: 40}}/>
                          </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            label={translation.linksInstagram.placeholders.add}
                            variant="outlined"
                            fullWidth
                            value={instagramLink}
                            onChange={(e) => validateInstagramLink(e.target.value, setIsValidInstagramLink, setInstagramLink)}
                            error={!isValidInstagramLink}
                            helperText={!isValidInstagramLink && translation.linksInstagram.placeholders.invalidLink}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              setInstagramLink('');
                              setIsValidInstagramLink(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            disabled={instagramLink && !isValidInstagramLink}
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              handleAddInstagramLink(instagramLink, linksInstagram, setLinksInstagram, setInstagramLink);
                            }}
                          >
                            <AddIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </fieldset>

                  <hr/>
                  <button
                    className="btn btn-lg pull-xs-right btn-primary"
                    type="button"
                    disabled={saveDisabled}
                    onClick={(event) => submitForm(event, {
                      ...props,
                      slug,
                      title,
                      context,
                      oldTitle,
                      images,
                      linksYoutube,
                      linksFacebook,
                      linksInstagram,
                      body,
                      participants,
                      startDate,
                      // isPrivate,
                    })}>
                    {translation.campaignEditor.save}
                  </button>

                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state.campaignEditor,
  device: state.common.device,
});

const mapDispatchTopProps = dispatch => ({
  onLoad: payload =>
    dispatch({type: CAMPAIGN_EDITOR_PAGE_LOADED, payload}),
  onUnload: payload =>
    dispatch({type: CAMPAIGN_EDITOR_PAGE_UNLOADED}),
  onUpdateField: (key, value) =>
    dispatch({type: UPDATE_FIELD_FREAK_CAMPAIGN, key, value}),
  onFileUpload: payload => {
    if (payload && payload.data) {
      const videosToUpload = [];
      videosToUpload.push(payload.data.filename)
      dispatch({
        type: UPLOAD_FREAK_VIDEO,
        payload: {
          videosToUpload
        }
      })
    }
  },
  onSubmit: payload =>
    dispatch({type: CAMPAIGN_SUBMITTED, payload}),
});

export default connect(mapStateToProps, mapDispatchTopProps)(FreakCampaignsEditor);
