import React from 'react';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import useTranslation from "../../customHooks/translations";
import StraightenIcon from '@mui/icons-material/Straighten';
import SpeedIcon from '@mui/icons-material/Speed';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import LandscapeIcon from '@mui/icons-material/Landscape';
import { DEFAULT_THEME_COLOR } from "../../constants/theme";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

const FreakActivityComponent = (props) => {
  const translation = useTranslation();

  // web & mobile
  const distance = props?.activity?.distance || 0;
  const duration = props?.activity?.duration || 0;
  const avgSpeed = props?.activity?.avgSpeed || 0;
  const maxSpeed = props?.activity?.maxSpeed || 0;
  const altitude = props?.activity?.altitude || 0;
  const maxAltitude = props?.activity?.maxAltitude || 0;

  // watch
  const calories = props?.activity?.calories || 0;
  const hydration = props?.activity?.hydration || 0;
  const avgHeartRate = props?.activity?.avgHeartRate || 0;
  const maxHeartRate = props?.activity?.maxHeartRate || 0;

  return (
    <>
      {
        duration && distance
          ? <Grid container sx={{ marginLeft: 1, width: '95%'}}>
            <Grid item xs={6}>
              <Card sx={{
                display: 'flex',
                boxShadow: 'none'
              }}>
                <CardMedia sx={{
                  display: 'flex',
                  alignItem: 'center',
                }}>
                  <StraightenIcon sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    maxWidth: 45,
                    maxHeight: 45,
                    marginTop: 2,
                  }}/>
                </CardMedia>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{flex: '1 0 auto'}}>
                    <div
                      style={{
                        // color: 'rgb(82, 88, 102)',
                        fontSize: 13,
                      }}
                    >{translation.freakLocalizationTracker.distance}
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        // color: 'black'
                      }}
                    >{distance}km</div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{
                display: 'flex',
                boxShadow: 'none'
              }}>
                <CardMedia sx={{
                  display: 'flex',
                  alignItem: 'center',
                }}>
                  <AlarmOnIcon sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    maxWidth: 45,
                    maxHeight: 45,
                    marginTop: 2,
                  }}/>
                </CardMedia>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{flex: '1 0 auto'}}>
                    <div
                      style={{
                        // color: 'rgb(82, 88, 102)',
                        fontSize: 13,
                      }}
                    >{translation.freakLocalizationTracker.duration}
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        // color: 'black'
                      }}
                    >{duration}</div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          </Grid>
          : null
      }
      {
        avgSpeed && maxSpeed
          ? <Grid container sx={{ marginLeft: 1,  width: '95%' }}>
            <Grid item xs={6}>
              <Card sx={{
                display: 'flex',
                boxShadow: 'none'
              }}>
                <CardMedia sx={{
                  display: 'flex',
                  alignItem: 'center',
                }}>
                  <AvTimerIcon sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    maxWidth: 45,
                    maxHeight: 45,
                    marginTop: 2,
                  }}/>
                </CardMedia>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{flex: '1 0 auto'}}>
                    <div
                      style={{
                        // color: 'rgb(82, 88, 102)',
                        fontSize: 13,
                      }}
                    >{translation.freakLocalizationTracker.avgSpeed}
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        // color: 'black'
                      }}
                    >{avgSpeed} km/h</div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{
                display: 'flex',
                boxShadow: 'none'
              }}>
                <CardMedia sx={{
                  display: 'flex',
                  alignItem: 'center',
                }}>
                  <SpeedIcon sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    maxWidth: 45,
                    maxHeight: 45,
                    marginTop: 2,
                  }}/>
                </CardMedia>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{flex: '1 0 auto'}}>
                    <div
                      style={{
                        // color: 'rgb(82, 88, 102)',
                        fontSize: 13,
                      }}
                    >{translation.freakLocalizationTracker.maxSpeed}
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        // color: 'black'
                      }}
                    >{maxSpeed} km/h</div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          </Grid>
          : null
      }
      {
        calories && hydration
          ? <Grid container sx={{ marginLeft: 1, width: '95%' }}>
            <Grid item xs={6}>
              <Card sx={{
                display: 'flex',
                boxShadow: 'none'
              }}>
                <CardMedia sx={{
                  display: 'flex',
                  alignItem: 'center',
                }}>
                  <WhatshotIcon sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    maxWidth: 45,
                    maxHeight: 45,
                    marginTop: 2,
                  }}/>
                </CardMedia>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{flex: '1 0 auto'}}>
                    <div
                      style={{
                        // color: 'rgb(82, 88, 102)',
                        fontSize: 13,
                      }}
                    >{translation.freakLocalizationTracker.calories}
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        // color: 'black'
                      }}
                    >{calories} kcal</div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{
                display: 'flex',
                boxShadow: 'none'
              }}>
                <CardMedia sx={{
                  display: 'flex',
                  alignItem: 'center',
                }}>
                  <WaterDropIcon sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    maxWidth: 45,
                    maxHeight: 45,
                    marginTop: 2,
                  }}/>
                </CardMedia>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{flex: '1 0 auto'}}>
                    <div
                      style={{
                        // color: 'rgb(82, 88, 102)',
                        fontSize: 13,
                      }}
                    >{translation.freakLocalizationTracker.hydration}
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        // color: 'black'
                      }}
                    >{hydration} fl. oz</div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          </Grid>
          : null
      }
      {
        avgHeartRate && maxHeartRate
          ? <Grid container sx={{ marginLeft: 1, width: '95%'}}>
            <Grid item xs={6}>
              <Card sx={{
                display: 'flex',
                boxShadow: 'none'
              }}>
                <CardMedia sx={{
                  display: 'flex',
                  alignItem: 'center',
                }}>
                  <MonitorHeartIcon sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    maxWidth: 45,
                    maxHeight: 45,
                    marginTop: 2,
                  }}/>
                </CardMedia>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{flex: '1 0 auto'}}>
                    <div
                      style={{
                        // color: 'rgb(82, 88, 102)',
                        fontSize: 13,
                      }}
                    >{translation.freakLocalizationTracker.avgHeartRate}
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        // color: 'black'
                      }}
                    >{avgHeartRate} bpm</div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{
                display: 'flex',
                boxShadow: 'none'
              }}>
                <CardMedia sx={{
                  display: 'flex',
                  alignItem: 'center',
                }}>
                  <HeartBrokenIcon sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    maxWidth: 45,
                    maxHeight: 45,
                    marginTop: 2,
                  }}/>
                </CardMedia>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{flex: '1 0 auto'}}>
                    <div
                      style={{
                        // color: 'rgb(82, 88, 102)',
                        fontSize: 13,
                      }}
                    >{translation.freakLocalizationTracker.maxHeartRate}
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        // color: 'black'
                      }}
                    >{maxHeartRate} bpm</div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          </Grid>
          : null
      }
      {
        altitude && maxAltitude
          ? <Grid container sx={{ marginLeft: 1, width: '95%'}}>
            <Grid item xs={6}>
              <Card sx={{
                display: 'flex',
                boxShadow: 'none'
              }}>
                <CardMedia sx={{
                  display: 'flex',
                  alignItem: 'center',
                }}>
                  <LandscapeIcon sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    maxWidth: 45,
                    maxHeight: 45,
                    marginTop: 2,
                  }}/>
                </CardMedia>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{flex: '1 0 auto'}}>
                    <div
                      style={{
                        // color: 'rgb(82, 88, 102)',
                        fontSize: 13,
                      }}
                    >{translation.freakLocalizationTracker.altitude}
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        // color: 'black'
                      }}
                    >10</div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{
                display: 'flex',
                boxShadow: 'none'
              }}>
                <CardMedia sx={{
                  display: 'flex',
                  alignItem: 'center',
                }}>
                  <LandscapeIcon sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    maxWidth: 45,
                    maxHeight: 45,
                    marginTop: 2,
                  }}/>
                </CardMedia>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <CardContent sx={{flex: '1 0 auto'}}>
                    <div
                      style={{
                        // color: 'rgb(82, 88, 102)',
                        fontSize: 13,
                      }}
                    >{translation.freakLocalizationTracker.maxAltitude}
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        // color: 'black'
                      }}
                    >16</div>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          </Grid>
          : null
      }
    </>
  );
};

export default FreakActivityComponent;
