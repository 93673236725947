import React, { useState } from 'react';
import TopBar from '../TopBar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import useTranslation from '../../customHooks/translations';
import {useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import agent from "../../agent";

const ReportAbuseView = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { slug} = useParams();
  const [selectedAbuse, setSelectedAbuse] = useState('spam');

  const handleReportAbuse = () => {
    agent.Freaks.reportAbuse(slug, selectedAbuse);

    navigate('/freak');
  };

  return (
    <div>
      <TopBar/>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="70vh"
      >
        <Grid container>
          <FormControl sx={{ marginLeft: 5 }}>
            <FormLabel id="report-abuse-radio-buttons-group-label">{`${translation.reportAbuse.report}: "${slug}"`}</FormLabel>
            <RadioGroup
              aria-labelledby="report-abuse-radio-buttons-group-label"
              defaultValue="spam"
              name="radio-buttons-group"
              value={selectedAbuse}
              onChange={(event) => {
                const selectedAbuse = event.target.value;
                setSelectedAbuse(selectedAbuse);
              }}
            >
              <FormControlLabel value="spam" control={<Radio />} label={translation.reportAbuse.spam} />
              <FormControlLabel value="hate" control={<Radio />} label={translation.reportAbuse.hate} />
              <FormControlLabel value="violence" control={<Radio />} label={translation.reportAbuse.violence} />
              <FormControlLabel value="nudity" control={<Radio />} label={translation.reportAbuse.nudity} />
              <FormControlLabel value="duplicate" control={<Radio />} label={translation.reportAbuse.duplicate} />
            </RadioGroup>
          </FormControl>
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: DEFAULT_THEME_COLOR,
                  color: 'white',
                  alignSelf: 'flex-end',
                  'z-index': '0',
                  borderRadius: 4,
                }}
                onClick={() => handleReportAbuse()}
              >
                {translation.reportAbuse.report}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
};

export default ReportAbuseView;
