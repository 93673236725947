import React, { useState } from 'react';
import {connect} from 'react-redux';
import {Global} from '@emotion/react';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {Card, IconButton} from "@mui/material";
import CardContent from "@mui/joy/CardContent";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {createTheme} from '@mui/material/styles';
import {CONTEXT_CHANGE} from "../../constants/actionTypes";
import useTranslation from "../../customHooks/translations";
import {getFreakIcon} from "../../utils/freakUtils";

const lightTheme = createTheme(
  // {palette: { mode: 'light' }}
);

const StyledBox = styled('div')(({theme}) => ({
  // backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  overflowY: 'auto',
}));

const Puller = styled('div')(({theme}) => ({
  width: 30,
  height: 6,
  // backgroundColor: 'white',
  // borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

const ContextSwipeableDrawerComponent = (props) => {
  const [selectedContext, setSelectedContext] = useState(null);
  const [open, setOpen] = useState(false);
  const context = props?.context || props?.currentUser?.context;
  const translation = useTranslation();

  const handleChange = (value) => {
    setSelectedContext(value);
    props.onContextChange(value);
    setOpen(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const drawerBleeding = 0;

  return (
    <>
      <IconButton
        sx={{
          '&:focus': {
            outline: 'none'
          },
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {getFreakIcon(context, () => setOpen(true))}
      </IconButton>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(100% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller/>
          {
            open
              ? <Card sx={{
                height: '80vh',
                boxShadow: 'none',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflowY: 'auto',
                borderRadius: 0,
              }}>
                <CardContent
                  sx={{
                    marginLeft: 2,
                    marginRight: 2,
                    marginTop: 4,
                    marginBottom: 24,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 5,
                    }}
                  >
                  </Box>
                  <Grid container spacing={2}>
                    {[lightTheme].map((theme, index) => (
                      <Grid item xs={12} key={index}>
                        <Box
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: 2,
                          }}
                        >
                          {[
                            'wof',
                            'bike',
                            'fishing',
                            'trip',
                            'fit',
                            'running',
                            'dogs',
                            'games',
                            'books',
                            'food',
                            'shopping',
                            'tech',
                            'motorization',
                            'gardening',
                            'music',
                            'sport',
                            'winterSports',
                            'horseRiding',
                          ].map((elevation) => (
                            <Item
                              key={elevation}
                              sx={{
                                backgroundColor: DEFAULT_THEME_COLOR,
                                color: 'white',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onClick={(event) => handleChange(elevation)}
                            >
                              {getFreakIcon(elevation)}
                            </Item>
                          ))}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
              : null
          }

        </StyledBox>
      </SwipeableDrawer>
    </>
  )
};

const mapStateToProps = state => ({
  ...state,
  currentUser: state.common.currentUser,
  context: state.common.context,
});

const mapDispatchToProps = dispatch => ({
  onContextChange: payload =>
    dispatch({type: CONTEXT_CHANGE, payload})
});

export default connect(mapStateToProps, mapDispatchToProps)(ContextSwipeableDrawerComponent);
