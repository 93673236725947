import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";

import TopBar from "../TopBar";
import useTranslation from "../../customHooks/translations";
import ListErrors from "../ListErrors";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {DEFAULT_THEME_COLOR, DEFAULT_THEME_COLOR_GREY} from "../../constants/theme";
import Typography from "@mui/material/Typography";
import FreakLocalization from "./FreakLocalization";
import Thumbnails from "../Thumbnails";
import UploadFiles from "../UploadFiles";
import agent from "../../agent";
import {
  FREAK_PLACES_SUBMITTED
} from "../../constants/actionTypes";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FreakParticipantsList from "./FreakParticipantsList";
import FreakSelect from "./FreakSelect";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  validateFacebookPlaceLink,
  validateInstagramLink,
  validatePlaceLink,
  validateYouTubeChannelLink,
} from "../../utils/freakUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from '@mui/icons-material/Language';
import {useMediaQuery} from "react-responsive";

const onParticipantSelect = (participants, setParticipants) => {
  const updatedParticipants = participants || [];
  for (const participant of participants) {
    const alreadyExists = updatedParticipants?.find(item => item.username === participant.username);
    if (!alreadyExists) {
      updatedParticipants.push(participant);
    }
  }
  setParticipants(updatedParticipants)
};

const FreakPlacesEditor = (props) => {
  const translation = useTranslation();
  const context = props.context;
  const navigate = useNavigate();
  const {slug} = useParams();

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [participants, setParticipants] = useState([]);
  const [localization, setLocalization] = useState({});
  const [images, setImages] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);


  const [isValidPlaceLink, setIsValidPlaceLink] = useState(true);
  const [placeLink, setPlaceLink] = useState('');

  const [isValidYouTubeLink, setIsValidYouTubeLink] = useState(true);
  const [youTubeLink, setYouTubeLink] = useState('');

  const [isValidFacebookLink, setIsValidFacebookLink] = useState(true);
  const [facebookLink, setFacebookLink] = useState('');

  const [isValidInstagramLink, setIsValidInstagramLink] = useState(true);
  const [instagramLink, setInstagramLink] = useState('');

  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    setSaveDisabled(!(title.trim() && body.trim()));
  }, [title, body]);

  const handleImageUpload = (file) => {
    const newImage = {
      id: file?.data?.id,
      imageUrl: file?.data?.fileUrl,
      name: file?.data?.filename,
    };
    setImages((prevImages) => [...prevImages, newImage]);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (slug) {
        const formData = await agent.FreaksPlaces.get(slug, context);
        if (formData) {
          setTitle(formData?.freakPlace?.title || '');
          setBody(formData?.freakPlace?.body || '');
          setImages(formData?.freakPlace?.images || []);
          setParticipants(formData?.freakPlace?.participants || []);
          setLocalization(formData?.freakPlace?.map || null);
        }
      }
    };
    fetchData();
  }, []);

  const onLocationSelect = (localizationMeta, setLocalization) => {
    setLocalization(localizationMeta);
  };

  const submitForm = (event, props) => {
    event.preventDefault();

    const freakPlace = {
      title: props.title,
      description: props.description,
      body: props.body,
      participants: props.participants,
      context,
      map: props.map || {},
      images: props.images?.map(image => image.name),
      public: props.public,
      placeLink: props.placeLink,
      youTubeLink: props.youTubeLink,
      facebookLink: props.facebookLink,
      instagramLink: props.instagramLink
    };

    const slug = {slug: props.slug};
    const promise = props.slug
      ? agent.FreaksPlaces.update(Object.assign(freakPlace, slug))
      : agent.FreaksPlaces.create(freakPlace);

    props.onSubmit(promise);
  };
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);

  return (
    <>
      <TopBar/>
      <div
        className="editor-page"
        style={{
          paddingLeft,
        }}
      >
        <div className="banner">
          <IconButton sx={{color: 'white'}} aria-label="back" onClick={() => {
            navigate(-1);
          }}>
            <ArrowBackIosNewOutlinedIcon sx={{
              marginRight: 2,
              color: DEFAULT_THEME_COLOR
            }}
            />
            <Typography variant="h4" sx={{color: 'white'}}>
              {title}
            </Typography>
          </IconButton>
        </div>
        <div className="container page">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-xs-12">

              <ListErrors errors={props.errors}></ListErrors>

              <form>
                <fieldset>
                  <fieldset className="form-group">
                    <input
                      style={{
                        backgroundColor: 'transparent',
                        color: DEFAULT_THEME_COLOR,
                        border: '0.5px solid #F1F1F1',
                      }}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder={translation.freakPlacesEditor.placeholders.title}
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                  </fieldset>

                  <fieldset className="form-group">
                      <textarea
                        style={{
                          backgroundColor: 'transparent',
                          color: DEFAULT_THEME_COLOR,
                          border: '0.5px solid #F1F1F1',
                        }}
                        className="form-control form-control-lg"
                        rows="8"
                        placeholder={translation.freakPlacesEditor.placeholders.content}
                        value={body}
                        onChange={(event) => {
                          setBody(event?.target?.value);
                        }}
                      >
                      </textarea>
                  </fieldset>

                  {participants && participants.length
                    ? <>
                      <hr/>
                      <FreakParticipantsList participants={participants}/>
                    </>
                    : null
                  }

                  <hr/>
                  <FreakSelect
                    onSelect={(participants => onParticipantSelect(participants, setParticipants))}
                  />
                  <hr/>

                  <fieldset className="form-group">
                    <FreakLocalization
                      localization={localization}
                      onSelect={(localization => onLocationSelect(localization, setLocalization))}
                    />
                  </fieldset>

                  {
                    images && images.length
                      ? <Thumbnails images={images} handleDeleteFn={setImages}/>
                      : null
                  }

                  <fieldset className="form-group">
                    <UploadFiles
                      onFileUpload={handleImageUpload}
                      setSaveDisabled={setSaveDisabled}
                      dest={"freakPlaces"}
                    />
                  </fieldset>

                  <fieldset className="form-group">
                    <Grid container>
                      <Grid container sx={{
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Grid item xs={2}>
                          <IconButton
                            component={Link}
                            aria-label="Navigate"
                          >
                            <LanguageIcon sx={{color: 'black', width: 40, height: 40}}/>
                          </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            label={translation.linksPlace.placeholders.add}
                            variant="outlined"
                            fullWidth
                            value={placeLink}
                            onChange={(e) => validatePlaceLink(e.target.value, setIsValidPlaceLink, setPlaceLink)}
                            error={!isValidPlaceLink}
                            helperText={!isValidPlaceLink && translation.linksPlace.placeholders.invalidLink}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              setPlaceLink('');
                              setIsValidPlaceLink(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </fieldset>

                  <fieldset className="form-group">
                    <Grid container>
                      <Grid container sx={{
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Grid item xs={2}>
                          <IconButton
                            component={Link}
                            to={"https://www.youtube.com"}
                            aria-label="Navigate"
                          >
                            <YouTubeIcon sx={{color: '#FF0000', width: 40, height: 40}}/>
                          </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            label={translation.linksYoutube.placeholders.add}
                            variant="outlined"
                            fullWidth
                            value={youTubeLink}
                            onChange={(e) => validateYouTubeChannelLink(e.target.value, setIsValidYouTubeLink, setYouTubeLink)}
                            error={!isValidYouTubeLink}
                            helperText={!isValidYouTubeLink && translation.linksYoutube.placeholders.invalidLink}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              setYouTubeLink('');
                              setIsValidYouTubeLink(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </fieldset>

                  <fieldset className="form-group">
                    <Grid container>
                      <Grid container sx={{
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Grid item xs={2}>
                          <IconButton
                            component={Link}
                            to={"https://www.facebook.com"}
                            aria-label="Navigate"
                          >
                            <FacebookIcon sx={{color: '#1877F2', width: 40, height: 40}}/>
                          </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            label={translation.linksFacebook.placeholders.add}
                            variant="outlined"
                            fullWidth
                            value={facebookLink}
                            onChange={(e) => validateFacebookPlaceLink(e.target.value, setIsValidFacebookLink, setFacebookLink)}
                            error={!isValidFacebookLink}
                            helperText={!isValidFacebookLink && translation.linksFacebook.placeholders.invalidLink}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              setFacebookLink('');
                              setIsValidFacebookLink(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </fieldset>

                  <fieldset className="form-group">
                    <Grid container>
                      <Grid container sx={{
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Grid item xs={2}>
                          <IconButton
                            component={Link}
                            to={"https://www.instagram.com"}
                            aria-label="Navigate"
                          >
                            <InstagramIcon sx={{color: 'rgb(30, 48, 80)', width: 40, height: 40}}/>
                          </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            label={translation.linksInstagram.placeholders.add}
                            variant="outlined"
                            fullWidth
                            value={instagramLink}
                            onChange={(e) => validateInstagramLink(e.target.value, setIsValidInstagramLink, setInstagramLink)}
                            error={!isValidInstagramLink}
                            helperText={!isValidInstagramLink && translation.linksInstagram.placeholders.invalidLink}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              setInstagramLink('');
                              setIsValidInstagramLink(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </fieldset>

                  <fieldset>
                    <FormGroup sx={{marginTop: 2, marginLeft: 2}}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={true}
                            disabled
                          />
                        }
                        label={translation.freakPlacesEditor.isPrivateLabel}
                      />
                    </FormGroup>
                  </fieldset>

                  <button
                    className="btn btn-lg pull-xs-right btn-primary"
                    type="button"
                    style={{
                      marginBottom: 100,
                    }}
                    disabled={saveDisabled}
                    onClick={(event) => submitForm(event, {
                      ...props,
                      slug,
                      title,
                      images,
                      participants,
                      body,
                      map: localization,
                      public: false,
                      placeLink,
                      youTubeLink,
                      facebookLink,
                      instagramLink
                    })}>
                    {translation.freakEditor.save}
                  </button>

                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

const mapStateToProps = state => ({
  ...state.freakPlacesEditor,
  device: state.common.device,
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: payload =>
    dispatch({type: FREAK_PLACES_SUBMITTED, payload}),
});
export default connect(mapStateToProps, mapDispatchToProps)(FreakPlacesEditor);
