import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';

import agent from "../../agent";
import TopBar from "../TopBar";
import Grid from "@mui/material/Grid";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Button from "@mui/material/Button";
import FreakLocalizationTracker from "./FreakLocalizationTracker";
import Typography from "@mui/material/Typography";
import FreakTimeTracker from "./FreakTimeTracker";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
  BOTTOM_NAVIGATION_VISIBILITY,
  RECORDING_FREAK_ROUTE,
  TOP_NAVIGATION_VISIBILITY,
} from "../../constants/actionTypes";
import { calculateDistance, getDistanceFromLatLonInMeters } from "../../utils/freakUtils";
import useTranslation from "../../customHooks/translations";

const getContextTrackingComponent = (
  translations,
  distance = 0.0,
  speed = 0.0,
) => {
  return (
    <>
      <Grid container sx={{
        margin: 3,
      }}>
        <Grid item xs={6}>
          <Grid sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left'
          }}>
            <Typography sx={{
              marginLeft: 1,
              fontSize: 13,
              // color: 'rgb(82, 88, 102)'
            }}>
              {translations.freakLocalizationTracker.distance}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{
              fontSize: 25,
              marginLeft: 1,
              // color: 'black'
            }}>
              {distance} km
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'left'
          }}>
            <Typography sx={{
              marginLeft: 1,
              fontSize: 13,
              // color: 'rgb(82, 88, 102)'
            }}>
              {translations.freakLocalizationTracker.speed}
            </Typography>
          </Grid>
          <Grid item xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'right'
                }}>
            <Typography variant="h6" sx={{
              fontSize: 25,
              marginLeft: 1,
              // color: 'black'
            }}>
              {speed} km/h
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};

const FreakTrackerView = (props) => {
  const navigate = useNavigate();
  const translations = useTranslation();
  const location = useLocation();
  const currentFreak = location?.state?.freak || {};
  const currentPosition = location?.state?.position || [];

  const [currentFreakActivity, setCurrentFreakActivity] = useState(location?.state?.freak?.freakActivity || null);

  const context = props.context || props?.currentUser?.context || 'wof';

  const [started, setStarted] = useState(false);
  const [paused, setPaused] = useState(false);
  const [resumed, setResumed] = useState(false);
  const [finished, setFinished] = useState(false);

  const [action, setAction] = useState('');

  const [position, setPosition] = useState(null);
  const [route, setRoute] = useState(props.route || []);
  const [fullRoute, setFullRoute] = useState([]);
  const [speed, setSpeed] = useState(0);
  const [avgSpeed, setAvgSpeed] = useState(0);
  const [maxSpeed, setMaxSpeed] = useState(0);
  const [altitude, setAltitude] = useState(0);
  const [maxAltitude, setMaxAltitude] = useState(0);
  const [distance, setDistance] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00:00');

  const watchIdRef = useRef(null); // Use a ref to persist watchId
  const speedCounterRef = useRef(0);

  const routeBatchLimit = 10; // Store only 100 coordinates at a time.
  const offloadRoute = (freakSlug, routeBatch) => {
    const sendBatch = async () => {
      const freakRoute = routeBatch?.map(routeObj => [routeObj.latitude, routeObj.longitude]);
      await agent.Freaks.updateRoute(freakSlug, freakRoute);
    };

    sendBatch();
  };

  const offloadActivity = () => {
    // update freak activity
    if (currentFreakActivity) {
      agent.FreakActivities.update({
        slug: currentFreakActivity.slug,
        distance,
        duration: currentTime,
        maxSpeed,
        avgSpeed,
        altitude,
        maxAltitude,
      });
    }
  };

  const calculateAvgSpeed = (avg, curr) => {
    const counter = speedCounterRef.current;
    setAvgSpeed(Math.round((avg * (counter - 1) + curr) / counter));
  };

  useEffect(() => {
    if (route.length >= routeBatchLimit) {
      const batch = route.slice(0, routeBatchLimit);
      offloadRoute(currentFreak.slug, batch);
      setRoute(route.slice(routeBatchLimit));

      offloadActivity(currentFreakActivity.slug);
    }
  }, [route]);

  useEffect(() => {
    setStarted(false);
    setPaused(false);
    setResumed(false);
    setFinished(false);

    // set as '' collect data
    setAction('');
  }, []);

  const startWatchingPosition = () => {
    // Clear previous watch
    if (watchIdRef.current) {
      navigator.geolocation.clearWatch(watchIdRef.current);
    }

    // Start new watch
    watchIdRef.current = navigator.geolocation.watchPosition(
      (position) => {
        const {latitude, longitude, speed, altitude} = position.coords;
        const isRunning = (started || resumed) && !paused && !finished;

        if (isRunning) {
          // Calculate speed in km/h (mobile)
          speedCounterRef.current += 1;
          const speedKmh = parseFloat((speed * 3.6).toFixed(2));
          setSpeed(speedKmh);
          if (speedKmh > maxSpeed) {
            setMaxSpeed(speedKmh);
          }
          calculateAvgSpeed(avgSpeed, speedKmh);

          setAltitude(altitude);

          if (route.length === 0) {
            setPosition({latitude, longitude});
            setRoute((prevRoute) => [...prevRoute, {latitude, longitude}]);
            setFullRoute((prevFullRoute) => [...prevFullRoute, ...route]);
          } else {
            const lastLatitude = route[route.length - 1].latitude;
            const lastLongitude = route[route.length - 1].longitude;
            const isRunning = (started || resumed) && !paused && !finished;

            if ((lastLatitude !== latitude || lastLongitude !== longitude) && isRunning) {
              const minDistanceToTrack = 2; // 2m
              const distanceInMeters = getDistanceFromLatLonInMeters(lastLatitude, lastLongitude, latitude, longitude);
              if (distanceInMeters < minDistanceToTrack) {
                console.log(`Ignored small movement in distance: ${distanceInMeters} m`);
              } else if (distanceInMeters > minDistanceToTrack) {
                // @todo one method to calculate distance
                const calculatedDistance = calculateDistance(lastLatitude, lastLongitude, latitude, longitude);
                setDistance((prevDistance) => {
                  console.log('Previous Distance:', prevDistance);
                  console.log('Calculated Distance:', calculatedDistance);
                  return parseFloat((prevDistance + calculatedDistance).toFixed(2));
                });
              }

              setRoute((prevRoute) => [...prevRoute, {latitude, longitude}]);
              setFullRoute((prevFullRoute) => [...prevFullRoute, ...route]);
              setPosition({latitude, longitude});
            }
          }
        }
      },
      (err) => console.error(err),
      {
        enableHighAccuracy: true,
        maximumAge: 5000,
        timeout: 10000,
        distanceFilter: 3,
      }
    );
  };

  // // cordova-geolocation-plugin
  useEffect(() => {
    function onDeviceReady() {
      if (window.cordova) {
        if (cordova?.plugins?.backgroundMode) {
          cordova.plugins.backgroundMode.enable();

          cordova.plugins.backgroundMode.on('activate', function () {
            cordova.plugins.backgroundMode.disableWebViewOptimizations();
            console.log('Background mode activated');
          });

          cordova.plugins.backgroundMode.on('deactivate', function () {
            console.log('Background mode deactivated');
          });
        } else {
          console.error('Background mode plugin is not available.');
        }

        if (cordova?.plugins?.powerManagement) {
          // Acquire the wake lock
          cordova.plugins.powerManagement.acquire(
            () => {
              console.log('Wakelock acquired.');
            },
            (err) => {
              console.error('Failed to acquire wakelock:', err);
            }
          );
        } else {
          console.error('Power Management plugin is not available.');
        }

        if (cordova?.plugins?.insomnia) {
          window.plugins.insomnia.keepAwake();
        } else {
          console.error('Insomnia plugin is not available.');
        }
      }

      startWatchingPosition();

      return () => {
        if (watchIdRef.current) {
          navigator.geolocation.clearWatch(watchIdRef.current);
          watchIdRef.current = null;
        }
      };
    }

    // determine if cordova or web
    if (window.cordova) {
      document.addEventListener('deviceready', onDeviceReady, false);
    } else {
      onDeviceReady();
    }

    // Clean up function to release wake lock
    return () => {
      if (window?.cordova?.plugins?.powerManagement) {
        cordova.plugins.powerManagement.release(() => {
          console.log('Wakelock released, screen can turn off now.');
        }, (err) => {
          console.error('Failed to release wakelock:', err);
        });
      }

      if (window?.cordova?.plugins?.insomnia) {
        window.plugins.insomnia.allowSleepAgain();
      } else {
        console.error('Failed to release Insomnia.');
      }
    };
  }, [route, speed, started, resumed, paused, finished]);

  // cordova-background-geolocation-lt
  // useEffect(() => {
  //   let bgGeoInitialized = false;
  //
  //   function onDeviceReady () {
  //     if (bgGeoInitialized) return; // Avoid re-initializing
  //
  //     const bgGeo = window.BackgroundGeolocation;
  //
  //     bgGeo.onLocation(function(location) {
  //         console.log('[location] -', location);
  //         const { latitude, longitude, speed, altitude } = location.coords;
  //         const isRunning = (started || resumed) && !paused && !finished;
  //
  //         if (isRunning) {
  //           // Calculate speed in km/h (mobile)
  //           speedCounterRef.current += 1;
  //           const speedKmh = parseFloat((speed * 3.6).toFixed(2));
  //           setSpeed(speedKmh);
  //           if (speedKmh > maxSpeed) {
  //             setMaxSpeed(speedKmh);
  //           }
  //           calculateAvgSpeed(avgSpeed, speedKmh);
  //
  //           setAltitude(altitude);
  //
  //           if (route.length === 0) {
  //             setPosition({latitude, longitude});
  //             setRoute((prevRoute) => [...prevRoute, {latitude, longitude}]);
  //           } else {
  //             const lastLatitude = route[route.length - 1].latitude;
  //             const lastLongitude = route[route.length - 1].longitude;
  //             const isRunning = (started || resumed) && !paused && !finished;
  //
  //             if ((lastLatitude !== latitude || lastLongitude !== longitude) && isRunning) {
  //               const minDistanceToTrack = 2; // 2m
  //               const distanceInMeters = getDistanceFromLatLonInMeters(lastLatitude, lastLongitude, latitude, longitude);
  //               if (distanceInMeters < minDistanceToTrack) {
  //                 console.log(`Ignored small movement in distance: ${distanceInMeters} m`);
  //               } else if (distanceInMeters > minDistanceToTrack) {
  //                 // @todo one method to calculate distance
  //                 const calculatedDistance = calculateDistance(lastLatitude, lastLongitude, latitude, longitude);
  //                 setDistance((prevDistance) => {
  //                   console.log('Previous Distance:', prevDistance);
  //                   console.log('Calculated Distance:', calculatedDistance);
  //                   return parseFloat((prevDistance + calculatedDistance).toFixed(2));
  //                 });
  //               }
  //
  //               setRoute((prevRoute) => [...prevRoute, {latitude, longitude}]);
  //               setPosition({latitude, longitude});
  //             }
  //           }
  //         }
  //       });
  //
  //     bgGeo.onMotionChange(function(event) {
  //         console.log('[motionchange] -', event.isMoving, event.location);
  //       });
  //
  //     bgGeo.onHttp(function(response) {
  //         console.log('[http] - ', response.success, response.status, response.responseText);
  //       });
  //
  //     bgGeo.onProviderChange(function(event) {
  //         console.log('[providerchange] -', event.status, event.enabled, event.gps, event.network);
  //       });
  //
  //       // 2. Execute #ready method:
  //     bgGeo.ready({
  //         reset: true,
  //         // debug: true,
  //         logLevel: bgGeo.LOG_LEVEL_VERBOSE,
  //         desiredAccuracy: bgGeo.DESIRED_ACCURACY_HIGH,
  //         distanceFilter: 10,
  //         // url: 'http://my.server.com/locations',
  //         autoSync: true,
  //         stopOnTerminate: false,
  //         startOnBoot: true
  //       }, function (state) {
  //         // 3.  Start tracking
  //         console.log('BackgroundGeolocation is configured and ready to use');
  //         console.log('pawel__338', { state });
  //
  //         if (!state.enabled) {
  //           bgGeo.start().then(function() {
  //             console.log('- BackgroundGeolocation tracking started');
  //           });
  //         }
  //       });
  //
  //     bgGeoInitialized = true;
  //   }
  //
  //   // determine if cordova or web
  //   if (window.cordova) {
  //     document.addEventListener('deviceready', onDeviceReady, false);
  //   } else {
  //     onDeviceReady();
  //   }
  //
  //   return () => {
  //     if (window.cordova) {
  //       const bgGeo = window.BackgroundGeolocation;
  //       bgGeo.removeListeners(); // This will clean up all event listeners when the component unmounts
  //     }
  //   };
  // }, [started, resumed, paused, finished]);

  const getContextMapComponent = (context, route, position, initPosition, speed, altitude, distance) => {
    const routeCoordinates = route?.map(latLon => [latLon.latitude, latLon.longitude]);

    let currentPosition = null;
    if (position) {
      currentPosition = [position?.latitude, position?.longitude];
    } else {
      currentPosition = initPosition;
    }

    return (
      <FreakLocalizationTracker
        routeCoordinates={routeCoordinates}
        position={currentPosition}
        isRunning={(started || resumed) && !paused && !finished}
        distance={distance}
        zoom={16}
      />
    );
  };

  const createFreakActivity = async () => {
    const results = await agent.FreakActivities.create({
      freakId: currentFreak.id,
      type: context,
      distance,
      duration: currentTime,
      maxSpeed,
      avgSpeed,
      altitude,
      maxAltitude,
    });
    setCurrentFreakActivity(results.freakActivity);
  };

  const handleStartClick = () => {
    setStarted(true);
    setPaused(false);
    setResumed(false);
    setFinished(false);

    // start collect data
    setAction('start');
    // // start location tracking
    // const bgGeo = window.BackgroundGeolocation;
    // bgGeo.start().then(() => {
    //   console.log("Background geolocation started");
    // });

    // update freak activity if exists
    if (currentFreakActivity) {
      agent.FreakActivities.update(currentFreakActivity);
    } else { // create freak activity
      createFreakActivity();
    }

    // hide topbar and bottom navigation
    props.setBottomNavigationVisible(false);
    props.setTopNavigationVisible(false);
  };

  const handlePauseClick = () => {
    setStarted(false);
    setPaused(true);
    setResumed(false);
    setFinished(false);

    // pause collect data
    setAction('pause');
    // // pause location tracking
    // const bgGeo = window.BackgroundGeolocation;
    // bgGeo.stop().then(() => {
    //   console.log("Background geolocation paused");
    // });

    // Clear the geolocation watcher
    if (watchIdRef.current) {
      navigator.geolocation.clearWatch(watchIdRef.current);
      watchIdRef.current = null;
    }

    // update private freak with collected data
    const freakRoute = route?.map(routeObj => [routeObj.latitude, routeObj.longitude]);

    // set recording freak
    props.setRecordingFreakRoute(freakRoute);

    // show topbar and bottom navigation
    props.setBottomNavigationVisible(true);
    props.setTopNavigationVisible(true);
  };

  const handleResumeClick = () => {
    setStarted(false);
    setPaused(false);
    setResumed(true);
    setFinished(false);

    // resume collect data
    setAction('resume');
    // // resume location tracking
    // const bgGeo = window.BackgroundGeolocation;
    // bgGeo.start().then(() => {
    //   console.log("Background geolocation resumed");
    // });

    // hide topbar and bottom navigation
    props.setBottomNavigationVisible(false);
    props.setTopNavigationVisible(false);
  };

  const handleFinishClick = () => {
    setStarted(false);
    setPaused(false);
    setResumed(false);
    setFinished(true);

    // finish collect data
    setAction('finish');
    // // finish location tracking
    // const bgGeo = window.BackgroundGeolocation;
    // bgGeo.stop().then(() => {
    //   console.log("Background geolocation finished");
    // });

    // Clear the geolocation watcher
    if (watchIdRef.current) {
      navigator.geolocation.clearWatch(watchIdRef.current);
      watchIdRef.current = null;
    }

    // update private freak with collected data
    const freakRoute = route?.map(routeObj => [routeObj.latitude, routeObj.longitude]);

    // show topbar and bottom navigation
    props.setBottomNavigationVisible(true);
    props.setTopNavigationVisible(true);

    // set recording freak
    props.setRecordingFreakRoute(freakRoute);

    // update freak route
    agent.Freaks.updateRoute(currentFreak.slug, freakRoute);

    // update freak activity
    agent.FreakActivities.update({
      slug: currentFreakActivity.slug,
      type: context,
      distance,
      duration: currentTime,
      maxSpeed,
      avgSpeed,
      altitude,
      maxAltitude,
    });

    // navigate back to freak
    navigate(`/freak/${currentFreak.slug}`);
  };

  const handleTimeUpdate = (time) => {
    setCurrentTime(time);
  };

  const getButtonAction = () => {
    if (!started) {
      handleStartClick();
    } else if (started && !paused) {
      handlePauseClick();
    } else if (paused) {
      handleResumeClick();
    }
  };

  const getButtonMessage = (translations) => {
    if (paused) {
      return translations.freakTrackerView.resume;
    } else if (!started) {
      return translations.freakTrackerView.start;
    } else if (started) {
      return translations.freakTrackerView.pause;
    } else {
      return ''
    }
  };

  return (
    <>
      <TopBar/>

      {getContextMapComponent(context, (fullRoute?.length ? fullRoute : route), position, currentPosition, speed, altitude, distance)}

      <FreakTimeTracker action={action} onTimeUpdate={handleTimeUpdate}/>

      {getContextTrackingComponent(translations, distance, speed)}

      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
      }}>
        <Button
          variant="contained"
          style={{
            color: 'white',
            backgroundColor: DEFAULT_THEME_COLOR,
            borderColor: DEFAULT_THEME_COLOR,
            'z-index': '0',
            borderRadius: 4,
            width: 150,
            outline: 'none',
          }}
          onClick={() => getButtonAction()}
        >
          {getButtonMessage(translations)}
        </Button>
        {
          paused ?
            <Button
              variant="contained"
              style={{
                color: 'white',
                backgroundColor: DEFAULT_THEME_COLOR,
                borderColor: DEFAULT_THEME_COLOR,
                'z-index': '0',
                borderRadius: 4,
                width: 150,
                outline: 'none',
                marginLeft: 2,
              }}
              onClick={() => handleFinishClick()}
            >
              {translations.freakTrackerView.finish}
            </Button>
            : null
        }
      </div>
    </>
  )
};

const mapStateToProps = state => ({
  ...state,
  currentUser: state.common.currentUser,
  context: state.common.context,
  recordingFreakRoute: state.common.recordingFreakRoute,
});

const mapDispatchToProps = dispatch => ({
  setBottomNavigationVisible: isVisible => dispatch({
    type: BOTTOM_NAVIGATION_VISIBILITY,
    payload: isVisible
  }),
  setTopNavigationVisible: isVisible => dispatch({
    type: TOP_NAVIGATION_VISIBILITY,
    payload: isVisible
  }),
  setRecordingFreakRoute: recordingFreakRoute => dispatch({
    type: RECORDING_FREAK_ROUTE,
    payload: recordingFreakRoute
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakTrackerView);
