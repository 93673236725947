import React from 'react';
import {DEFAULT_THEME_COLOR} from "../constants/theme";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            color: DEFAULT_THEME_COLOR,
            padding: "3em",
            textAlign: "center",
            fontSize: "16px",
            borderRadius: "3px",
            border: `1px solid ${DEFAULT_THEME_COLOR}`,
            margin: "1em",
          }}
        >
          <p>
            Something went wrong. Please contact to:
            freakland.app@gmail.com
          </p>
          <p
            style={{
              fontSize: "12px",
            }}
          >
            {this.state.error}
          </p>
        </div>
      );
    }
    return this.props.children;
  }
};

export default ErrorBoundary;
