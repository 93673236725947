export const STATUS_IN_PROGRESS = 'in_progress';
export const MESSAGE_IN_PROGRESS = 'IN PROGRESS';
export const COLOR_IN_PROGRESS = '#ff8836';
export const STATUS_FINISHED = 'finished';
export const MESSAGE_FINISHED = 'FINISHED';
export const STATUS_CANCELED = 'canceled';
export const MESSAGE_CANCELED = 'CANCELED';
export const COLOR_CANCELED = '#ff8836';
export const COLOR_FINISHED = '#79DDD0';
export const STATUS_PLANNED = 'planned';
export const MESSAGE_PLANNED = 'PLANNED';
export const COLOR_PLANNED = '#36a25f';
export const FREAK_TYPE_PRIVATE = 'private';
export const FREAK_TYPE_PUBLIC = 'public';
export const FEED_DAILY = 'feed_daily';
export const FEED_WEEKLY = 'feed_weekly';
export const FEED_MONTHLY = 'feed_monthly';
export const FEED_YEARLY = 'feed_yearly';
export const CAMPAIGN_TYPE_CAMPAIGN = 'campaign';
export const CAMPAIGN_TYPE_PROMOTION = 'promotion';
export const FREAK_PLACE_TYPE_FISHING_PZW = 'fishing_pzw';
export const FREAK_PLACE_TYPE_FISHING_COMMERCIAL = 'fishing_commercial';
export const FREAK_PLACE_TYPE_FISHING_WHITE_CARD_ONLY = 'fishing_white_card_only';
export const FREAK_PLACE_TYPE_WOF_MOTOCROSS = 'wof_motocross';
