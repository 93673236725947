import React from 'react';
import {Popup} from "react-leaflet";
import Typography from "@mui/material/Typography";
import {Card, CardMedia, IconButton} from "@mui/material";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import CardContent from "@mui/joy/CardContent";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import {getGoogleMapsLink} from "../../utils/freakUtils";
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import useTranslation from "../../customHooks/translations";

import "./styles.css";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Button from "@mui/material/Button";
import {getFreakPlaceTypeBackgroundColor, getFreakPlaceTypeColor} from "../../utils/commonUtils";

const FreakPopup = (props) => {
  const translation = useTranslation()

  return (
    <Popup closeButton={false}>
      <Card sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        width: '100%',
        padding: 0,
        margin: 0,
      }}
      >
        <CardMedia
          component="img"
          sx={{
            width: '100%',
            height: 'auto',
            // borderRadius: 1,
          }}
          image={props.imageUrl || '/logo.png'}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <CardContent sx={{
            flex: '1 0 auto',
            margin: 1,
          }}>
            {
              props.type
                ? <Button
                  variant="contained"
                  style={{
                    minWidth: 18,
                    maxWidth: 150,
                    height: 15,
                    fontSize: 10,
                    backgroundColor: getFreakPlaceTypeBackgroundColor(props.type),
                    color: getFreakPlaceTypeColor(props.type)
                  }}
                  disabled
                >
                  {translation.freakPlaces[props.type.name]}
                </Button>
                : null
            }
            <Typography
              variant="h7"
              color="text.primary"
              component="div"
              sx={{
                wordBreak: 'break-word'
              }}
            >
              {props.title}
            </Typography>
            <Typography
              variant="titile"
              color="text.secondary"
              sx={{
                fontSize: '10px'
              }}
            >
              {translation.freakPlaces.details}
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                  'button:focus': {
                    outline: 'none'
                  }
                }}
              >
                <AdsClickIcon
                  sx={{
                    color: DEFAULT_THEME_COLOR,
                  }}
                  onClick={props.onClick}
                ></AdsClickIcon>
              </IconButton>
              <IconButton
                component={Link}
                target="_blank"
                rel="noopener"
                to={getGoogleMapsLink(props?.map?.coordinates?.latitude, props?.map?.coordinates?.longitude)}
                aria-label="Navigate"

              >
                <AssistantDirectionIcon sx={{
                  color: DEFAULT_THEME_COLOR,
                }}/>
              </IconButton>
            </Typography>

          </CardContent>
        </Box>
      </Card>
    </Popup>
  )
};

export default FreakPopup;
