import {Link} from 'react-router-dom';
import React from 'react';

import ArticleActions from './ArticleActions';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { formatDateAgo } from '../../utils/dateUtils';

const ArticleMeta = props => {
  const article = props.article;
  return (
    <div className="article-meta">
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <Link to={`/@${article.author.username}`}>
              <img src={article.author.imageUrl} alt={article.author.username}/>
            </Link>

            <div className="info">
              <Link to={`/@${article.author.username}`} className="author">
                {article.author.username}
              </Link>
              <span className="date">
                {formatDateAgo(article.createdAt)}
              </span>
            </div>
          </Grid>
          <Grid xs={1}>
            <ArticleActions canModify={props.canModify} article={article}/>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ArticleMeta;
