import React, {useState, useRef, useEffect} from 'react';
import Webcam from 'react-webcam';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import StopIcon from '@mui/icons-material/Stop';
import SaveIcon from '@mui/icons-material/Save';
import ReactPlayer from 'react-player';
import { Typography } from '@mui/material';
import TopBar from "../TopBar";
import useTranslation from "../../customHooks/translations";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";

const ShortsCreatorComponent = (props) => {
  const translation = useTranslation();

  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoSrc, setVideoSrc] = useState(null);
  const [mimeType, setMimeType] = useState('');

  const autoStopCapturingAfter = 30000;

  useEffect(() => {
    if (!capturing && recordedChunks.length) {
      handleSave();
    }
  }, [capturing, recordedChunks]);

  const handleStartCaptureClick = () => {
    setCapturing(true);
    if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
      setMimeType('video/webm;codecs=vp9');
    } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
      setMimeType('video/webm;codecs=vp8');
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      setMimeType('video/webm');
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      setMimeType('video/mp4');
    } else {
      console.log('No supported MIME type found for MediaRecorder.');
      return;
    }

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();

    setTimeout(() => {
      handleStopCaptureClick();
    }, autoStopCapturingAfter);
  };

  const handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  };

  const handleStopCaptureClick = () => {
    if (capturing) {
      mediaRecorderRef.current.stop();
      setCapturing(false);

      handleSave();
    }
  };

  const handleSave = () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: mimeType,
      });
      const url = URL.createObjectURL(blob);
      setVideoSrc(url);
      setRecordedChunks([]);

      // props.afterSaveFn(false); // @todo
    }
  };

  const handleRetake = () => {
    setVideoSrc(null);
  };

  return (
    <>
      <TopBar />
      <div style={{ textAlign: 'center', margin: '20px' }}>
        {!videoSrc ? (
          <>
            <Webcam
              audio={true}
              ref={webcamRef}
              style={{ width: '100%', height: '100%' }}
            />
            <div style={{ marginTop: '10px' }}>
              {capturing ? (
                <IconButton
                  sx={{
                    outline: 'none',
                    '&:focus': {
                      outline: 'none'
                    },
                    '&:hover': {
                      color: DEFAULT_THEME_COLOR,
                      borderColor: DEFAULT_THEME_COLOR,
                    }
                  }}
                  onClick={handleStopCaptureClick}>
                  <StopIcon color="error" fontSize="large" />
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    outline: 'none',
                    '&:focus': {
                      outline: 'none'
                    },
                    '&:hover': {
                      color: DEFAULT_THEME_COLOR,
                      borderColor: DEFAULT_THEME_COLOR,
                    }
                  }}
                  onClick={handleStartCaptureClick}>
                  <VideoCameraFrontIcon color="primary" fontSize="large" />
                </IconButton>
              )}
            </div>
          </>
        ) : (
          <>
            <ReactPlayer
              url={videoSrc}
              controls
              playing
              width="100%"
              height="100%"
            />
            <div style={{ marginTop: '10px' }}>
              <Button
                variant="contained"
                disabled
                sx={{
                  outline: 'none',
                  '&:focus': {
                    outline: 'none'
                  },
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  }
                }}
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleSave}
              >
                {translation.shortsCreator.save}
              </Button>
              <Button
                variant="contained"
                sx={{
                  outline: 'none',
                  '&:focus': {
                    outline: 'none'
                  },
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  }
                }}
                color="secondary"
                style={{ marginLeft: '10px' }}
                onClick={handleRetake}
              >
                {translation.shortsCreator.retake}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ShortsCreatorComponent;
