import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import FreakActions from './FreakActions';
import { formatDateAgo } from '../../utils/dateUtils';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import {getFreakIcon} from "../../utils/freakUtils";

const FreakMeta = props => {
  const freak = props.freak;
  return (
    <div className="article-meta">
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={2} columns={3}>
          <Grid xs={1}>
            <Link to={`/@${freak.author.username}`}>
              <Avatar
                alt={freak.author.username}
                src={freak?.author?.imageUrl}
              />
            </Link>

            <div className="info">
              <Link to={`/@${freak.author.username}`} className="author">
                {freak.author.username}
              </Link>
              <span className="date">
                {formatDateAgo(freak.createdAt)}
              </span>
            </div>
          </Grid>
          <Grid xs={1}>
            <FreakActions canModify={props.canModify} freak={freak}/>
          </Grid>
          <Grid xs={1} sx={{ position: 'relative' }}>
            {
              props.freak?.context
                ? <IconButton
                  style={{
                    color: DEFAULT_THEME_COLOR,
                  }}
                  sx={{
                    '&:focus': {
                      outline: 'none'
                    },
                    marginLeft: '70%',
                  }}
                >
                  {getFreakIcon(props.freak.context)}
                </IconButton>
                : null
            }
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default FreakMeta;
