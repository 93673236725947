import React, {useEffect, useState} from 'react';
import DeleteButton from './DeleteButton';
import { Link } from 'react-router-dom';
import { formatDateAgo } from '../../utils/dateUtils';
import {GiphyFetch} from "@giphy/js-fetch-api";
import {EmojiVariationsList} from "@giphy/react-components";

const gf = new GiphyFetch('sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh'); // @todo

const Comment = props => {
  const comment = props.comment;
  const show = props.currentUser &&
    props.currentUser.username === comment.author.username;

  const [gif, setGif] = useState(null);

  const fetchVariations = (id) => {
    return gf.emojiVariations(id)
  };

  useEffect(() => {
    const fetchGif = async () => {
      const gifId = comment.gifId;
      if (gifId) {
        const response = await gf.gif(gifId);
        setGif(response.data);
      }
    };

    fetchGif()

  }, []);

  return (
    <div className="card" style={{ marginBottom: 40, }}>
      <div className="card-block">
        {
          gif
            ? <EmojiVariationsList
                noLink={true}
                hideAttribution={true}
                fetchVariations={fetchVariations}
                gif={gif}
                gifHeight={100}
            />
            : <div dangerouslySetInnerHTML={{ __html: comment.body }} />
        }
      </div>
      <div className="card-footer">
        <Link
          to={`/@${comment.author.username}`}
          className="comment-author">
          <img src={comment.author.imageUrl} className="comment-author-img" alt={comment.author.username} />
        </Link>
        &nbsp;
        <Link
          to={`/@${comment.author.username}`}
          className="comment-author">
          {comment.author.username}
        </Link>
        <span className="date-posted">
          {formatDateAgo(comment.createdAt)}
        </span>
        <DeleteButton show={show} slug={props.slug} commentId={comment.id} />
      </div>
    </div>
  );
};

export default Comment;
