import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {getLatLon} from "../../utils/freakUtils";
import useTranslation from "../../customHooks/translations";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FreakListFeed from "./FreakListFeed";
import FreakListAll from "./FreakListAll";
import FreakListFollowed from "./FreakListFollowed";
import FreakListDaily from "./FreakListDaily";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";

const FreakView = (props) => {
  const translation = useTranslation();
  const context = props.context;
  const [geolocation, setGeolocation] = useState(null);
  const [errorGeoLocation, setGeoErrorLocation] = useState(null);
  const [freaks, setFreaks] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const fetchGeoLocation = async () => {
      await getLatLon(setGeolocation, setGeoErrorLocation);
    };
    fetchGeoLocation();
  }, [
    props.distanceFilter,
    props.sortByLikesFilter,
    props.sortByDateFilter,
    props.filterByNameFilter,
    props.filterByDateFromFilter,
    props.filterByDateToFilter,
    props.filterByStatusFilter,
    props.filterByParticipantFilter,
  ]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="col-md-fullwidth">
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="freakland-tabs"
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: DEFAULT_THEME_COLOR,
          },
        }}
      >
        <Tab
          label={translation.tabs.private}
          sx={{
            '&.Mui-selected': {
              color: DEFAULT_THEME_COLOR,
              'outline': 'none',
            },
          }}
        />
        <Tab
          label={translation.tabs.public}
          sx={{
            '&.Mui-selected': {
              color: DEFAULT_THEME_COLOR,
              'outline': 'none',
            },
          }}
        />
        <Tab
          label={translation.tabs.followed}
          sx={{
            '&.Mui-selected': {
              color: DEFAULT_THEME_COLOR,
              'outline': 'none',
            },
          }}
        />
        <Tab
          label={translation.tabs.daily}
          sx={{
            '&.Mui-selected': {
              color: DEFAULT_THEME_COLOR,
              'outline': 'none',
            },
          }}
        />
      </Tabs>

      {/* @todo find a better way to do this instead of separate list for each*/}
      {
        selectedTab === 0 && (
          <FreakListFeed
            tab={'feed'}
            geolocation={geolocation}
            errorGeoLocation={errorGeoLocation}
            pager={props.pager}
            freaks={freaks}
            loading={props.loading}
            freaksCount={props.freaksCount}
            currentPage={props.currentPage}
            context={context}
          />
        )
      }
      {
        selectedTab === 1 && (
          <FreakListAll
            tab={'all'}
            geolocation={geolocation}
            errorGeoLocation={errorGeoLocation}
            pager={props.pager}
            freaks={freaks}
            loading={props.loading}
            freaksCount={props.freaksCount}
            currentPage={props.currentPage}
            context={context}
          />
        )
      }
      {
        selectedTab === 2 && (
          <FreakListFollowed
            tab={'followed'}
            geolocation={geolocation}
            errorGeoLocation={errorGeoLocation}
            pager={props.pager}
            freaks={freaks}
            loading={props.loading}
            freaksCount={props.freaksCount}
            currentPage={props.currentPage}
            context={context}
          />
        )
      }
      {
        selectedTab === 3 && (
          <FreakListDaily
            tab={'followed'}
            geolocation={geolocation}
            errorGeoLocation={errorGeoLocation}
            pager={props.pager}
            freaks={freaks}
            loading={props.loading}
            freaksCount={props.freaksCount}
            currentPage={props.currentPage}
            context={context}
          />
        )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  ...state.freakList,
  tags: state.home.tags,
  token: state.common.token,
  device: state.common.device,
  distanceFilter: state.common.distanceFilter,
  sortByLikesFilter: state.common.sortByLikesFilter,
  sortByDateFilter: state.common.sortByDateFilter,
  filterByNameFilter: state.common.filterByNameFilter,
  filterByDateFromFilter: state.common.filterByDateFromFilter,
  filterByDateToFilter: state.common.filterByDateToFilter,
  filterByStatusFilter: state.common.filterByStatusFilter,
  filterByParticipantFilter: state.common.filterByParticipantFilter,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FreakView);
