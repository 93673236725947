export const toastNotification = ({ title, description, status, setSnackbar}) => {
  console.log(`Toast Notification: ${title} - ${description} - ${status}`);

  setSnackbar({
    open: true,
    message: `${title}: ${description}`,
    severity: status,
  });
};

export const sendNativeNotification = ({ title, body }) => {
  console.log(`Native Notification: ${title} - ${body}`);

  // if (Notification.permission === 'granted') {
  //   new Notification(title, { body });
  // } else if (Notification.permission !== 'denied') {
  //   Notification.requestPermission().then(permission => {
  //     if (permission === 'granted') {
  //       new Notification(title, { body });
  //     }
  //   });
  // }
};
