import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import CardContent from "@mui/joy/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Card, IconButton} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import Slider from "@mui/material/Slider";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DatePickerComponent from "../DatePickerComponent";
import FreakSelect from "../Freak/FreakSelect";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import useTranslation from "../../customHooks/translations";
import {getLatLon} from "../../utils/freakUtils";
import {
  DISTANCE_CHANGE, FILTER_BY_DATE_FROM_CHANGE, FILTER_BY_DATE_TO_CHANGE, FILTER_BY_PARTICIPANT_CHANGE,
  FILTER_BY_STATUS_CHANGE, LATITUDE_CHANGE, LONGITUDE_CHANGE, PRIVATE_FREAK_PLACE_CHANGE, PUBLIC_FREAK_PLACE_CHANGE,
  SORT_BY_DATE_CHANGE,
  SORT_BY_LIKES_CHANGE
} from "../../constants/actionTypes";

const FiltersComponent = (props) => {
  const translation = useTranslation();

  const defaultDistance = 100; // all

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('5');
  const [selectedSortByLikes, setSelectedSortByLikes] = useState(props.sortByLikesFilter || 'none');
  const [selectedSortByDate, setSelectedSortByDate] = useState(props.sortByDateFilter || 'none');
  const [selectedFilterByStatus, setSelectedFilterByStatus] = useState(props.filterByStatusFilter || 'none');
  const [selectedFilterByDateFrom, setSelectedFilterByDateFrom] = useState(props.filterByDateFromFilter || null);
  const [selectedFilterByDateTo, setSelectedFilterByDateTo] = useState(props.filterByDateToFilter || null);
  const [selectedFilterByParticipant, setSelectedFilterByParticipant] = useState(props.filterByParticipantFilter || 'none');
  const [geolocation, setGeolocation] = useState(null);
  const [errorGeoLocation, setGeoErrorLocation] = useState(null);
  const [distance, setDistance] = useState(defaultDistance);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [filtersBadgeCount, setFiltersBadgeCount] = useState(0);


  const marks = [
    {
      value: 0,
      label: '25km'
    },
    {
      value: 25,
      label: '50km',
    },
    {
      value: 50,
      label: '100km',
    },
    {
      value: 75,
      label: '500km',
    },
    {
      value: 100,
      label: translation.freakLocalization.all,
    }
  ];
  const payloadValueMap = {
    0: 25,
    25: 50,
    50: 100,
    75: 500,
    100: 10000
  };

  useEffect(() => {
    calculateFiltersCount();
  }, [
    props.sortByLikesFilter,
    props.sortByDateFilter,
    props.filterByNameFilter,
    props.filterByDateFromFilter,
    props.filterByDateToFilter,
    props.filterByStatusFilter,
    props.filterByParticipantFilter,
    distance,
  ]);

  const handleGeoLocationSelectChange = async (event) => {
    const distance = event?.target?.value || defaultDistance;
    setDistance(distance);

    await getLatLon(setGeolocation, setGeoErrorLocation);

    if (geolocation) {
      setLatitude(geolocation.latitude);
      props.onLatitudeChange(geolocation.latitude);
      setLongitude(geolocation.longitude);
      props.onLongitudeChange(geolocation.longitude);
    }

    props.onDistanceChange(distance)
  };

  const handleSortByLikesSelectChange = async (event) => {
    const sortByLikesValue = event.target.value;

    setSelectedSortByLikes(sortByLikesValue);

    props.onSortByLikesChange(sortByLikesValue)
  };

  const handleSortByDateSelectChange = async (event) => {
    const sortByDateValue = event.target.value;

    setSelectedSortByDate(sortByDateValue);

    props.onSortByDateChange(sortByDateValue)
  };

  const handleFilterByStatusSelectChange = async (event) => {
    const filterByStatusValue = event.target.value;

    setSelectedFilterByStatus(filterByStatusValue);

    props.onFilterByStatusChange(filterByStatusValue)
  };

  const handleFilterByDateFromSelectChange = async (filterByDateFromValue) => {
    setSelectedFilterByDateFrom(filterByDateFromValue);

    props.onFilterByDateFromChange(filterByDateFromValue)
  };

  const handleFilterByDateToSelectChange = async (filterByDateToValue) => {
    setSelectedFilterByDateTo(filterByDateToValue);

    props.onFilterByDateToChange(filterByDateToValue)
  };

  const handleFilterByParticipantSelectChange = async (filterByParticipantValue) => {
    setSelectedFilterByParticipant(filterByParticipantValue);

    props.onFilterByParticipantChange(filterByParticipantValue)
  };

  const calculateFiltersCount = () => {
    let filtersCount = 0;
    if (props.sortByDateFilter && props.sortByDateFilter !== 'none') {
      filtersCount++;
    }
    if (props.filterByStatusFilter && props.filterByStatusFilter !== 'none') {
      filtersCount++;
    }

    if (props.sortByLikesFilter && props.sortByLikesFilter !== 'none') {
      filtersCount++;
    }

    if (distance !== defaultDistance) {
      filtersCount++;
    }

    if (props.filterByDateFromFilter && props.filterByDateFromFilter !== 'none') {
      filtersCount++;
    }

    if (props.filterByDateToFilter && props.filterByDateToFilter !== 'none') {
      filtersCount++;
    }

    if (props.filterByParticipantFilter && props.filterByParticipantFilter !== 'none') {
      filtersCount++;
    }

    setFiltersBadgeCount(filtersCount);
  };

  const resetAllFilters = () => {
    props.onSortByDateChange('none');
    setSelectedSortByDate('none');

    props.onFilterByStatusChange('none');
    setSelectedFilterByStatus('none');

    props.onSortByLikesChange('none');
    setSelectedSortByLikes('none');

    setDistance(defaultDistance);

    props.onFilterByDateFromChange('none');
    setSelectedFilterByDateFrom(null);

    props.onFilterByDateToChange('none');
    setSelectedFilterByDateTo(null);

    props.onFilterByParticipantChange('none');
    setSelectedFilterByParticipant(null);
  };

  return (
    <Card sx={{
      height: '100vh',
      boxShadow: 'none',
      // backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'auto',
    }}>
      <CardContent
        sx={{
          marginLeft: 2,
          marginRight: 2,
          overflowY: 'auto',
        }}
      >
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <Typography variant="h4">
              {translation.filterSwipeableDrawerComponent.title}
            </Typography>
          </Box>
          <Grid container spacing={0}>
            <Grid container spacing={0}>
              <Typography variant="h6" gutterBottom>
                {translation.freakView.filter}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <IconButton
                sx={{
                  'button:focus': {
                    outline: 'none'
                  },
                  paddingLeft: 0,
                }}
              >
                <MapIcon sx={{
                  // color: 'black',
                  width: 25,
                  height: 25
                }}
                />
              </IconButton>
              <Typography variant="h8" sx={{
                // color: 'grey'
              }}
              >
                {translation.freakLocalization.distance}
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ marginLeft: 5, marginRight: 5, }}>
              <Slider
                aria-label="Always visible"
                defaultValue={100}
                step={25}
                marks={marks}
                valueLabelDisplay="off"
                sx={{
                  color: DEFAULT_THEME_COLOR
                }}
                onChange={(event) => {
                  handleGeoLocationSelectChange({
                    target: {
                      value: payloadValueMap[event.target.value]
                    }
                  })
                  setSelectedValue(payloadValueMap[event.target.value]);
                }}
              />
            </Grid>
          </Grid>

          {
            props.pathname === '/freak'
              ? <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <IconButton
                        sx={{
                          'button:focus': {
                            outline: 'none'
                          },
                          paddingLeft: 0
                        }}
                      >
                        <HorizontalSplitIcon sx={{
                          // color: 'black',
                          width: 25,
                          height: 25
                        }}
                        />
                      </IconButton>
                      <Typography variant="h8" sx={{
                        // color: 'grey'
                      }}
                      >
                        {translation.freakView.status}
                      </Typography>
                    </Grid>
                    <Box sx={{ minWidth: 120, marginBottom: 1 }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="status-label"
                          id="status-select"
                          size="small"
                          value={selectedFilterByStatus}
                          label={translation.freakView.status}
                          onChange={(e) => handleFilterByStatusSelectChange(e)}
                        >
                          <MenuItem value={'none'}>{translation.freakView.none}</MenuItem>
                          <MenuItem value={'planned'}>{translation.freakView.planned}</MenuItem>
                          <MenuItem value={'canceled'}>{translation.freakView.canceled}</MenuItem>
                          <MenuItem value={'finished'}>{translation.freakView.finished}</MenuItem>
                          <MenuItem value={'in_progress'}>{translation.freakView.inProgress}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <IconButton
                        sx={{
                          'button:focus': {
                            outline: 'none'
                          },
                          paddingLeft: 0
                        }}
                      >
                        <HorizontalSplitIcon sx={{
                          // color: 'black',
                          width: 25,
                          height: 25
                        }}
                        />
                      </IconButton>
                      <Typography variant="h8" sx={{
                        // color: 'grey'
                      }}
                      >
                        {translation.freakView.date}
                      </Typography>
                    </Grid>
                    <Box sx={{ minWidth: 120 }}>
                      <DatePickerComponent
                        label={translation.freakView.dateFrom}
                        value={selectedFilterByDateFrom}
                        onChange={(dateFrom) => handleFilterByDateFromSelectChange(dateFrom)}
                      />
                      <DatePickerComponent
                        label={translation.freakView.dateTo}
                        value={selectedFilterByDateTo}
                        onChange={(dateTo) => handleFilterByDateToSelectChange(dateTo)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <IconButton
                        sx={{
                          'button:focus': {
                            outline: 'none'
                          },
                          paddingLeft: 0
                        }}
                      >
                        <HorizontalSplitIcon sx={{
                          // color: 'black',
                          width: 25,
                          height: 25
                        }}
                        />
                      </IconButton>
                      <Typography variant="h8" sx={{
                        // color: 'grey'
                      }}
                      >
                        {translation.freakView.participant}
                      </Typography>
                    </Grid>
                    <Box sx={{ minWidth: 120, marginBottom: 5 }}>
                      <FreakSelect
                        onSelect={(selectedFreaks) =>
                          handleFilterByParticipantSelectChange(selectedFreaks?.map(selectedFreak => selectedFreak.username))
                        }
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {translation.freakView.sortBy}
                    </Typography>

                    <Grid item xs={12}>
                      <IconButton
                        sx={{
                          'button:focus': {
                            outline: 'none'
                          },
                          paddingLeft: 0
                        }}
                      >
                        <FavoriteIcon sx={{
                          // color: 'black',
                          width: 25,
                          height: 25
                        }}
                        />
                      </IconButton>
                      <Typography variant="h8" sx={{
                        // color: 'grey'
                      }}
                      >
                        {translation.freakView.likes}
                      </Typography>
                    </Grid>
                    <Box sx={{ minWidth: 120, marginBottom: 1 }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="likes-label"
                          id="likes-select"
                          size="small"
                          value={selectedSortByLikes}
                          label={translation.freakView.likes}
                          onChange={(e) => handleSortByLikesSelectChange(e)}
                        >
                          <MenuItem value={'none'}>{translation.freakView.none}</MenuItem>
                          <MenuItem value={'asc'}>{translation.freakView.asc}</MenuItem>
                          <MenuItem value={'desc'}>{translation.freakView.desc}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Grid item xs={12}>
                      <IconButton
                        sx={{
                          'button:focus': {
                            outline: 'none'
                          },
                          paddingLeft: 0
                        }}
                      >
                        <DateRangeIcon sx={{
                          // color: 'black',
                          width: 25,
                          height: 25
                        }}
                        />
                      </IconButton>
                      <Typography variant="h8" sx={{
                        // color: 'grey'
                      }}
                      >
                        {translation.freakView.date}
                      </Typography>
                    </Grid>
                    <Box sx={{ minWidth: 120, marginBottom: 1, }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="date-label"
                          id="date-select"
                          size="small"
                          value={selectedSortByDate}
                          label={translation.freakView.date}
                          onChange={(e) => handleSortByDateSelectChange(e)}
                        >
                          <MenuItem value={'none'}>{translation.freakView.none}</MenuItem>
                          <MenuItem value={'asc'}>{translation.freakView.asc}</MenuItem>
                          <MenuItem value={'desc'}>{translation.freakView.desc}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </>
              : null
          }

          {
            props.pathname === '/places'
              ? <>
                <FormGroup>
                  <FormControlLabel control={
                    <Checkbox
                      checked={props.publicFreakPlaceFilter === undefined ? true : props.publicFreakPlaceFilter}
                      onChange={(e) => props.onPublicFreakPlaceChange(e.target.checked) }
                    />
                  } label={translation.freakPlacesEditor.public} />
                  <FormControlLabel control={
                    <Checkbox
                      checked={props.privateFreakPlaceFilter === undefined ? true : props.privateFreakPlaceFilter}
                      onChange={(e) => props.onPrivateFreakPlaceChange(e.target.checked)}
                    />
                  } label={translation.freakPlacesEditor.private} />
                </FormGroup>
              </>
              : null
          }

          <Button
            variant="contained"
            style={{
              backgroundColor: DEFAULT_THEME_COLOR,
              color: 'white',
              borderColor: DEFAULT_THEME_COLOR,
              'z-index': '0',
              borderRadius: 4,
              marginBottom: 10,
              outline: 'none',
            }}
            onClick={() => resetAllFilters()}
          >
            {translation.contextSwipeableDrawerComponent.resetAllFilters}
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: DEFAULT_THEME_COLOR,
              color: 'white',
              borderColor: DEFAULT_THEME_COLOR,
              'z-index': '0',
              borderRadius: 4,
              outline: 'none',
              marginBottom: 200,
            }}
            onClick={() => props.setOpen(false) || setOpen(false)}
          >
            {translation.contextSwipeableDrawerComponent.close}
          </Button>
        </>
      </CardContent>
    </Card>
  )
};

const mapStateToProps = state => ({
  ...state,
  currentUser: state.common.currentUser,
  context: state.common.context,
  device: state.common.device,
  publicFreakPlaceFilter: state.common.publicFreakPlaceFilter,
  privateFreakPlaceFilter: state.common.privateFreakPlaceFilter,
  sortByDateFilter: state.common.sortByDateFilter,
  sortByLikesFilter: state.common.sortByLikesFilter,
  filterByNameFilter: state.common.filterByNameFilter,
  filterByDateFromFilter: state.common.filterByDateFromFilter,
  filterByDateToFilter: state.common.filterByDateToFilter,
  filterByStatusFilter: state.common.filterByStatusFilter,
  filterByParticipantFilter: state.common.filterByParticipantFilter,
});

const mapDispatchToProps = dispatch => ({
  onDistanceChange: payload => dispatch({type: DISTANCE_CHANGE, payload}),
  onSortByLikesChange: payload => dispatch({type: SORT_BY_LIKES_CHANGE, payload}),
  onSortByDateChange: payload => dispatch({type: SORT_BY_DATE_CHANGE, payload}),
  onFilterByStatusChange: payload => dispatch({ type: FILTER_BY_STATUS_CHANGE, payload}),
  onFilterByDateFromChange: payload => dispatch({ type: FILTER_BY_DATE_FROM_CHANGE, payload}),
  onFilterByDateToChange: payload => dispatch({ type: FILTER_BY_DATE_TO_CHANGE, payload}),
  onFilterByParticipantChange: payload => dispatch({ type: FILTER_BY_PARTICIPANT_CHANGE, payload}),
  onLatitudeChange: payload => dispatch({type: LATITUDE_CHANGE, payload}),
  onLongitudeChange: payload => dispatch({type: LONGITUDE_CHANGE, payload}),
  onPrivateFreakPlaceChange: payload => dispatch({type: PRIVATE_FREAK_PLACE_CHANGE, payload}),
  onPublicFreakPlaceChange: payload => dispatch({type: PUBLIC_FREAK_PLACE_CHANGE, payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersComponent);
