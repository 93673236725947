import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { CometChat } from "@cometchat/chat-sdk-javascript";

import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CampaignIcon from '@mui/icons-material/Campaign';
import Paper from '@mui/material/Paper';
import {
  BOTTOM_NAVIGATION_LOADED, BOTTOM_NAVIGATION_SET_POINTS,
  BOTTOM_NAVIGATION_SET_UNREAD_MESSAGES,
  BOTTOM_NAVIGATION_UNLOADED,
  LOGOUT,
  TOP_NAVIGATION_VISIBILITY
} from '../constants/actionTypes';
import {useEffect} from "react";
import agent from '../agent';

import { Badge as BaseBadge, badgeClasses } from '@mui/base/Badge';
import { styled } from '@mui/system';
import { DEFAULT_THEME_COLOR } from '../constants/theme';
import useTranslation from "../customHooks/translations";
import {getFreakIcon} from "../utils/freakUtils";
import Avatar from "@mui/material/Avatar";

const Badge = styled(BaseBadge)(
  ({ theme }) => `
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-self: center;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 1;

  & .${badgeClasses.badge} {
    z-index: auto;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 22px;
    height: 22px;
    padding: 0 6px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    text-align: center;
    border-radius: 12px;
    background: ${DEFAULT_THEME_COLOR};
    box-shadow: 0px 4px 6x #C7D0DD;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }

  & .${badgeClasses.invisible} {
    opacity: 0;
    pointer-events: none;
  }
  `,
);

const FixedBottomNavigation = (props) => {
  const translation = useTranslation();
  const [value, setValue] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const token = window.localStorage.getItem('jwt');
  const device = props.device;
  const context = props.context || props?.currentUser?.context || 'wof';

  const fetchNotificationsCount = (props) => {
    if (token) {
      props.onLoad(Promise.all([
        agent.Notifications.count()
      ]));
    }
  };

  const fetchUserFreakPoints = (props) => {
    if (token) {
      props.onLoadPoints(Promise.all([
        agent.Points.count()
      ]));
    }
  };

  const fetchUnreadMessagesCount = async () => {
    if (token) {
      const results = await CometChat.getUnreadMessageCount();
      let unreadMessagesCount = 0;
      if (results && results.users) {
        Object.keys(results.users).forEach(key => {
          unreadMessagesCount += results.users[key];
        });
      }

      if (results && results.groups) {
        Object.keys(results.groups).forEach(key => {
          unreadMessagesCount += results.groups[key];
        });
      }

      props.setUnreadMessages(unreadMessagesCount)
    }
  };

  useEffect(() => {
    fetchNotificationsCount(props);
    fetchUserFreakPoints(props);

    fetchUnreadMessagesCount();

    if (location?.pathname === '/freak') {
      setValue(1);
    }
  }, []);

  return (
      props?.bottomNavigationVisible
        ? <Box sx={{ pb: 7  }} ref={ref}>
          <Paper
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              minHeight: device === 'desktop' ? 50 : 90,
              'z-index': 9999,
              backgroundColor: 'rgb(18, 18, 18)',
              backgroundImage: 'none'
            }}
            elevation={14}
          >
            <BottomNavigation
              showLabels
              value={value}
              style={{
                minHeight: device === 'desktop' ? 50 : 70,
                'z-index': 999,
              }}
              onChange={(event, newValue) => {
                fetchNotificationsCount(props);
                fetchUserFreakPoints(props);
                fetchUnreadMessagesCount()

                switch (newValue) {
                  case 0:
                    setValue(0);
                    props && props.token ? navigate('/freak') : navigate('/login');

                    break;
                  case 1:
                    setValue(1);
                    props && props.token ? navigate('/notifications') : navigate('/login');

                    break;
                  // case 2:
                  //   setValue(2);
                  //   props && props.token ? navigate('/record') : navigate('/login');
                  //
                  //   break;
                  case 2:
                    setValue(2);
                    const username = props?.common?.currentUser?.username;
                    props && props.token ? navigate('/places') : navigate('/login');

                    break;

                  case 3:
                    setValue(3);
                    props && props.token ? navigate('/campaigns') : navigate('/login');

                    break;
                  default:
                    break;
                }
              }}
            >
              <BottomNavigationAction
                sx={{
                  // color: 'black',
                  '&.Mui-selected': {
                    color: DEFAULT_THEME_COLOR,
                    'outline': 'none',
                  },
                }}
                // label={translation.bottomNavigation.freaks}
                icon={getFreakIcon(context)}
              />
              <BottomNavigationAction
                sx={{
                  // color: 'black',
                  '&.Mui-selected': {
                    color: DEFAULT_THEME_COLOR,
                    'outline': 'none',
                  },
                }}
                // label={translation.bottomNavigation.notifications}
                icon={
                  <Badge
                    badgeContent={props.common.notificationsCount || notificationsCount}
                    color="primary"
                  >
                    <NotificationsNoneIcon />
                  </Badge>
                }
              />
              <BottomNavigationAction
                sx={{
                  // color: 'black',
                  '&.Mui-selected': {
                    color: DEFAULT_THEME_COLOR,
                    'outline': 'none',
                  },
                }}
                // label={translation.bottomNavigation.places}
                icon={
                  <TravelExploreIcon />
                }
              />
              <BottomNavigationAction
                sx={{
                  // color: 'black',
                  '&.Mui-selected': {
                    color: DEFAULT_THEME_COLOR,
                    'outline': 'none',
                  },
                }}
                // label={translation.bottomNavigation.campaigns}
                icon={
                  <CampaignIcon />
                }
              />
            </BottomNavigation>
          </Paper>
        </Box>
        : null
  );
};

const mapStateToProps = state => ({
  ...state,
  bottomNavigationVisible: state.common.bottomNavigationVisible,
  device: state.common.device,
  currentUser: state.common.currentUser,
  context: state.common.context,
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
  onLoad: payload =>
    dispatch({type: BOTTOM_NAVIGATION_LOADED, payload}),
  onLoadPoints: payload =>
    dispatch({type: BOTTOM_NAVIGATION_SET_POINTS, payload}),
  setUnreadMessages: payload =>
    dispatch({ type: BOTTOM_NAVIGATION_SET_UNREAD_MESSAGES, payload }),
  onUnload: () =>
    dispatch({type: BOTTOM_NAVIGATION_UNLOADED}),
  setTopNavigationVisible: isVisible => dispatch({
    type: TOP_NAVIGATION_VISIBILITY,
    payload: isVisible
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FixedBottomNavigation);
