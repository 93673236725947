import React, {useState, useEffect} from 'react';
import TopBar from "../TopBar";

import agent from "../../agent";
import { useParams } from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import FreakListFollowersComponent from "../List/FreakListFollowersComponent";

const FollowingView = (props) => {
  const params = useParams();
  const translation = useTranslation();
  const username = params.username.replace('@', '');
  const [following, setFollowing] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const following = await agent.Profile.getProfileFollow(username);
      setFollowing(following);
    };

    fetchData();
  }, []);

  return (
    <div>
      <TopBar/>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '80vh',
        paddingTop: 30,
      }}>
        {
          following && following.length
            ? <FreakListFollowersComponent
              title={`${translation.following.title}`}
              secondaryTextKey={"bio"}
              secondaryTextToConcat={""}
              data={following}
            />
            : null
        }
      </div>

    </div>
  )
};

export default FollowingView;
