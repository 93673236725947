export default {
  tabs: {
    private: 'My',
    public: 'Public',
    nearby: 'Nearby',
    followed: 'Followed',
    daily: 'Daily',
    all: 'Global'
  },
  comments: {
    placeholder: 'Write a comment...',
    add: 'Post Comment',
    postComment: 'Post comment',
    cancel: 'Cancel',
  },
  buttons: {
    edit: 'Edit',
    delete: 'Delete',
    finish: 'Finish',
    publish: 'Publish',
    cancel: 'Cancel',
    chat: 'Chat',
    leave: 'Leave'
  },
  accordions: {
    addSocialMedia: 'Add Social Media'
  },
  freaks: {
    visibility: {
      public: 'Public',
      private: 'Private',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      yearly: 'Yearly',
      campaign: 'Campaign',
      promotion: 'Promotion'
    },
    status: {
      inProgress: 'In progress',
      finished: 'Finished',
      planned: 'Planned',
      canceled: 'Cancelled'
    },
    operations: {
      start: 'Start',
      join: 'Join',
      requested: 'Requested'
    },
    participate: {
      pending: 'Pending',
      approved: 'Approved',
      declined: 'Declined',
      requestJoin: 'Requested Join'
    },
    emptyList: 'No freaks are here yet.',
    emptyListDaily: 'No feed yet. Create some! ;)',
    emptyListFollowed: 'No Freaks to show',
    deleted: 'Freak has been deleted ;(',
    unauthorized: 'You have to accept invitation first',
    favourited: 'Likes',
    loading: 'Loading Freaks'
  },
  profile: {
    editMessage: 'Edit Profile Settings',
    freaks: 'Freaks',
    followers: 'Followers',
    following: 'Following',
    points: 'Freak Points',
    verified: 'freak-land.com'
  },
  articles: {
    my: 'My Articles',
    favourited: 'Favorited Articles',
    emptyList: 'No articles are here... yet.'
  },
  settings: {
    title: 'Settings',
    logout: 'Logout',
    forceReload: 'Force reload app',
    version: 'Version',
    update: 'Save',
    bio: "Short bio about you",
    bioLabel: 'Bio',
    userImageLabel: 'Upload User Image',
    backgroundImageLabel: 'Background Image',
    modeLabel: 'Mode',
    modeLabelDescription: 'Change to dark mode.',
    languageLabel: 'Language',
    languageLabelDescription: 'Choose langugage.',
    languagePolish: 'Polish',
    languageEnglish: 'English',
    contextLabel: 'Default Context',
    settings: 'Settings',
    notifications: 'Request notifications permissions',
    contextWof: 'Wild Off Road',
    contextBike: 'Bikes',
    contextFishing: 'Fishing',
    contextTrip: 'Trip',
    contextFit: 'Fit',
    contextRunning: 'Running',
    contextDogs: 'Pets',
    contextGames: 'Games',
    contextBooks: 'Books',
    contextFood: 'Food',
    contextShopping: 'Shopping',
    contextTech: 'Tech',
    contextMotorization: 'Motorization',
    contextGardening: 'Gardening',
    contextMusic: 'Music',
    contextSport: 'Sport',
    contextWinterSports: 'Winter Sports',
    contextHorseRiding: 'Horse Riding',
    switchThemeDark: 'Switch to dark theme',
    switchThemeLight: 'Switch to light theme',
    theme: 'Theme'
  },
  modals: {
    confirmation: {
      areYouSure: 'Are you sure?',
      actionUndone: 'This action cannot be undone.',
      confirm: 'Confirm',
      decline: 'Decline',
      emailHasBeenSent: 'Confirmation email has been sent.',
      ok: 'OK'
    }
  },
  editor: {
    placeholders: {
      postTitle: 'Title',
      postContent: 'Content'
    },
    save: 'Save'
  },
  freakEditor: {
    placeholders: {
      freakTitle: 'Title',
      freakContent: 'Content'
    },
    defaults: {
      body: 'My latest Freak!'
    },
    add: 'Add',
    save: 'Save',
    isPrivateLabel: 'Private Freak',
    freakVisibility: 'Settings',
    createPlace: 'Create a Private Place',
    showInPlaces: 'Show in Places',
    createFreaks: 'Create Freaky!',
    createTrack: 'Create Freak track!',
    freakRouteMobileAppNeeded: 'Install mobile📱or watch⌚app and create Freak route.',
    freakyMobileAppNeeded: 'Install mobile📱or watch⌚app and create Freaky.'
  },
  campaignEditor: {
    placeholders: {
      freakTitle: 'Title',
      freakContent: 'Content'
    },
    add: 'Add',
    save: 'Save',
    isPrivateLabel: 'Private Campaign',
    freakVisibility: 'Settings'
  },
  freakPlacesEditor: {
    placeholders: {
      title: 'Title',
      content: 'Description'
    },
    isPrivateLabel: "Private Freak place",
    private: 'Private',
    public: 'Public'
  },
  freakCampaigns: {
    noCampaigns: 'No Campaigns for now, check soon.',
    contact: 'Do you want to create a campaign?',
    contactButton: 'Contact us!'
  },
  freakView: {
    filter: 'Filter:',
    search: 'Search...',
    yourLocation: 'Your localization',
    sortBy: 'Sort by:',
    likes: 'Likes',
    status: 'Status',
    planned: 'Planned',
    canceled: 'Canceled',
    finished: 'Finished',
    inProgress: 'In Progress',
    date: 'Create date',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    asc: 'Ascending',
    desc: 'Descending',
    none: 'None',
    participant: 'Participant/Author'
  },
  freakPreview: {
    messages: {
      plannedTo: 'Planned to',
      finishedAt: 'Finished at',
      alreadyRunning: 'Already running'
    },
    copy: {
      copyLink: 'Copy Link',
      copied: 'Copied!',
      failed: 'Failed to copy'
    },
    share: {
      checkoutThis: 'Checkout this !'
    }
  },
  freakDetails: {
    localization: 'Localization',
    localizationDescription: 'Event venue',
    plannedTo: 'Planned to',
    startedAt: 'Started at',
    finishedAt: 'Finished at',
    duration: 'Duration',
    checkOnGoogleMaps: 'Check on Google Maps',
    noParticipants: 'No participants yet...',
    participants: 'Participants',
    participantsDescription: 'Freaks in event',
    description: 'Description',
    information: 'Info',
    informationDescription: 'Start, end, duration event time',
    socialMedia: 'Social Media',
    socialMediaDescription: 'Youtube, Facebook, Instagram',
    likes: 'Likes',
    route: 'Route',
    activity: 'Activity'
  },
  freakParticipants: {
    remove: 'Remove'
  },
  freakSelect: {
    placeholders: {
      selectParticipants: 'Select participants',
      searchParticipants: 'Type username',
      selectPlace: 'Select place',
      searchPlace: 'Type place',
    }
  },
  freakLocalization: {
    placeholders: {
      search: 'Search for a location...'
    },
    yourLocalization: 'Your localization',
    distance: 'At a distance from you',
    all: 'All',
    markerIsDraggable: 'Marker is draggable',
    clickToDragMarker: 'Click here to make marker draggable',
    filter: {
      header: 'Filter',
      status: 'Status',
      participant: 'Participant',
      localization: 'Lokalizacja'
    },
    sort: {
      header: 'Sort',
      likes: 'Likes',
      date: 'Date'
    }
  },
  freakCampaign: {
    participantsDescription: 'Freaks in campaign',
    cooperation: 'Cooperation',
    cooperationBody: 'Want to create your own Campaigns?',
    contact: 'Write to us',
    requestContact: 'or request contact and we will contact to You'
  },
  notifications: {
    types: {
      invitation: 'Invitation',
      info: 'Info',
      requestJoin: 'Request join'
    },
    emptyList: 'You don\'t have any notification',
    accept: 'Accept',
    decline: 'Decline',
    show: 'Show',
    delete: 'Delete',
    denied: 'Notifications permissions denied.',
    settings: 'Notifications settings'
  },
  giphy: {
    placeholders: {
      search: 'Search for GIFs'
    }
  },
  uploadFiles: {
    upload: 'Select files (png, jpg, jpeg, heic). Limit 10mb.',
    notSupportedFileType: 'Not supported file type',
    somethingWentWrong: 'Something went wrong, try again',
    tooLargeFile: 'Too large file. Limit 10mb.'
  },
  datePicker: {
    placeholders: {
      select: 'Select date'
    }
  },
  login: {
    needAccount: 'Need an account?',
    signIn: 'Sign In',
    password: 'Password',
    forgotPassword: 'Forgot Password?',
    buttons: {
      signIn: 'Sign In'
    }
  },
  resetPassword: {
    label: 'Reset Password',
    sendEmail: 'Send Email'
  },
  setupPassword: {
    setupNew: 'Setup New Password',
    placeholders: {
      password: 'Password',
      confirmPassword: 'Confirm Password'
    },
    confirm: 'Confirm'
  },
  register: {
    signUp: 'Sign Up',
    haveAnAccount: 'Have an account?',
    emailSent: 'Confirmation email has been sent.',
    termsOfService: 'By signing up to create an account I accept',
    termsOfServiceTitle: 'Terms of Service',
    termsOfServiceAnd: 'and',
    privacyPolicy: 'Privacy Policy',
    placeholders: {
      username: 'Username',
      password: 'Password'
    },
    defaultContext: 'Freak'
  },
  readMoreComponent: {
    more: 'Read more...',
    less: 'Read less...'
  },
  linksPlace: {
    placeholders: {
      add: 'Add Place link here...',
      invalidLink: 'Invalid link',
    }
  },
  linksYoutube: {
    placeholders: {
      add: 'Add YouTube link here...',
      addFeed: 'Add YouTube channel here...',
      invalidLink: 'Invalid link',
      invalidFeed: 'Invalid channel'
    }
  },
  linksFacebook: {
    placeholders: {
      add: 'Add Facebook link here...',
      invalidLink: 'Invalid link'
    }
  },
  linksInstagram: {
    placeholders: {
      add: 'Add Instagram link here...',
      invalidLink: 'Invalid link'
    }
  },
  bottomNavigation: {
    freaks: 'Freaks',
    notifications: 'Notifications',
    messages: 'Chat',
    places: 'Places',
    campaigns: 'Campaigns',
    record: 'Freak'
  },
  leftNavigation: {
    add: 'Add',
    freaks: 'Freak',
    notifications: 'Notifications',
    messages: 'Messages',
    places: 'Places',
    campaigns: 'Campaigns',
    topFreaks: 'Top Freaks',
    context: 'Context',
    filters: 'Filters',
    profile: 'Profile'
  },
  freakPlaces: {
    navigate: 'Check on:',
    details: 'Details',
    edit: 'Edit',
    sendForPublic: 'Request change to public place',
    waitingForApproval: 'Waiting for approval',
    delete: 'Delete',
    freaksFromThisPlace: 'From this place:',
    noFreaksFromThisPlace: 'No Freaks from this place yet.',
    fishing_pzw: 'PZW',
    fishing_commercial: 'Commercial',
    fishing_white_card_only: 'White card only',
    wof_motocross: 'Off-road',
    loadingFreakPlaces: 'Loading Freak Places...'
  },
  contextSwipeableDrawerComponent: {
    title: 'Context',
    close: 'Close',
    resetAllFilters: 'Reset All Filters'
  },
  filterSwipeableDrawerComponent: {
    title: 'Settings'
  },
  snackbar: {
    open: 'Open',
    denied: 'Notifications denied.'
  },
  emailConfirmed: {
    message: 'Email confirmed.',
    buttonMessage: 'Log in'
  },
  reportAbuse: {
    report: 'Report abuse',
    spam: 'Spam or Scam',
    hate: 'Contains hate speech or attacks an individual',
    violence: 'Violence or harmful behaviour',
    nudity: 'Nudity, pornography, or sexually explicit content',
    duplicate: 'Duplicate, fake, or miscategoriezed content'
  },
  topFreaks: {
    title: "Top Freak'ów",
    points: "Freak points"
  },
  followedBy: {
    title: 'Your Followers',
    noBio: 'No bio'
  },
  following: {
    title: 'Following',
    noBio: 'No bio'
  },
  profileFavorites: {
    follow: 'Follow',
    unfollow: 'Unfollow'
  },
  usersList: {
    title: 'Users'
  },
  errors: {
    error401Message: 'Back to Freaks.',
    error404Message: 'Back to Freaks.',
  },
  shortsCreator: {
    retake: 'Retake',
    save: 'Save'
  },
  freakLocalizationTracker: {
    distance: 'Distance:',
    duration: 'Duration:',
    movingTime: 'Moving Time:',
    speed: 'Speed',
    maxSpeed: 'Max Speed:',
    avgSpeed: 'Avg Speed:',
    altitude: 'Altitude:',
    maxAltitude: 'Max Altitude:',
    calories: 'Calories:',
    hydration: 'Hydration:',
    avgHeartRate: 'Avg Heart Rate:',
    maxHeartRate: 'Max Heart Rate:'
  },
  freakTrackerView: {
    resume: 'Resume',
    start: 'Start',
    pause: 'Pause',
    finish: 'Finish'
  },
  freakPlacesList: {
    fromThisPlace: 'Freaks from this place:',
    filter: 'Filter',
  },
  cometChat: {
    CHATS: 'Chats',
    USERS: 'Users',
    DELETE: 'Yes',
    CANCEL: 'Cancel',
    DELETE_CONVERSATION: 'Delete Conversation?',
    WOULD__YOU_LIKE_TO_DELETE_THIS_CONVERSATION: 'Would you like to delete this conversation?'
  },
  feeds: {
    title: "Feeds",
  },
};
