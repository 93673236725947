// import DOMPurify from 'dompurify';

const sanitizeInput = (input) => {
  // @todo use some lib here
  // return DOMPurify.sanitize(input);
  return input;
};

module.exports = {
  sanitizeInput,
};
