export const NOTIFICATION_TYPES = {
  INVITATION: 'invitation',
  INFO: 'info',
  REQUEST_JOIN: 'request_join',
};

export const NOTIFICATION_OPERATION_TYPES = {
  APPROVE: 'approve',
  DECLINE: 'decline',
};
