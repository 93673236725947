import React, {useEffect, useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Toolbar,
  Box
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import {useLocation, useNavigate} from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import {getFreakIcon} from "../../utils/freakUtils";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CampaignIcon from "@mui/icons-material/Campaign";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Avatar from "@mui/material/Avatar";
import {CometChat} from "@cometchat/chat-sdk-javascript";
import agent from "../../agent";
import {
  BOTTOM_NAVIGATION_LOADED,
  BOTTOM_NAVIGATION_SET_UNREAD_MESSAGES,
  LEFT_NAVIGATION_OPEN,
} from "../../constants/actionTypes";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import { Badge as BaseBadge, badgeClasses } from '@mui/base/Badge';
import IconButton from "@mui/material/Button";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from "@mui/icons-material/Add";
import FiltersComponent from "../Filters/FiltersComponent";
import Modal from '@mui/material/Modal';
import FilterAltIcon from "@mui/icons-material/FilterAlt";


const Badge = styled(BaseBadge)(
  ({ theme }) => `
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-self: center;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 1;

  & .${badgeClasses.badge} {
    z-index: auto;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 22px;
    height: 22px;
    padding: 0 6px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    text-align: center;
    border-radius: 12px;
    background: ${DEFAULT_THEME_COLOR};
    box-shadow: 0px 4px 6x #C7D0DD;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }

  & .${badgeClasses.invisible} {
    opacity: 0;
    pointer-events: none;
  }
  `,
);

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const drawerWidth = 300;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

const LeftNavigationComponent = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const theme = useTheme();
  const token = window.localStorage.getItem('jwt');
  const context = props.context || props?.currentUser?.context || 'wof';

  const [selectedMenuItem, setSelectedMenuItem] = useState('freaks');
  const [filtersBadgeCount, setFiltersBadgeCount] = useState(0);

  const fetchNotificationsCount = (props) => {
    if (token) {
      props.onLoad(Promise.all([
        agent.Notifications.count()
      ]));
    }
  };

  const fetchUnreadMessagesCount = async () => {
    if (token) {
      const results = await CometChat.getUnreadMessageCount();
      let unreadMessagesCount = 0;
      if (results && results.users) {
        Object.keys(results.users).forEach(key => {
          unreadMessagesCount += results.users[key];
        });
      }

      if (results && results.groups) {
        Object.keys(results.groups).forEach(key => {
          unreadMessagesCount += results.groups[key];
        });
      }

      props.setUnreadMessages(unreadMessagesCount)
    }
  };

  useEffect(() => {
    calculateFiltersCount();
  }, [
    props.sortByLikesFilter,
    props.sortByDateFilter,
    props.filterByNameFilter,
    props.filterByDateFromFilter,
    props.filterByDateToFilter,
    props.filterByStatusFilter,
    props.filterByParticipantFilter,
  ]);

  const calculateFiltersCount = () => {
    let filtersCount = 0;
    if (props.sortByDateFilter && props.sortByDateFilter !== 'none') {
      filtersCount++;
    }
    if (props.filterByStatusFilter && props.filterByStatusFilter !== 'none') {
      filtersCount++;
    }

    if (props.sortByLikesFilter && props.sortByLikesFilter !== 'none') {
      filtersCount++;
    }

    // if (distance !== defaultDistance) {
    //   filtersCount++;
    // }

    if (props.filterByDateFromFilter && props.filterByDateFromFilter !== 'none') {
      filtersCount++;
    }

    if (props.filterByDateToFilter && props.filterByDateToFilter !== 'none') {
      filtersCount++;
    }

    if (props.filterByParticipantFilter && props.filterByParticipantFilter !== 'none') {
      filtersCount++;
    }

    setFiltersBadgeCount(filtersCount);
  };

  useEffect(() => {
    fetchNotificationsCount(props);
    // fetchUserFreakPoints(props);

    fetchUnreadMessagesCount();

    if (location?.pathname === '/freak') {
      setValue(1);
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
    props.setLeftNavigationOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.setLeftNavigationOpen(false);
  };

  return token ? (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        open={open}
      >
        <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{
                marginLeft: -2,
                '&:focus': {
                  outline: 'none'
                },
                'button:focus': {
                  outline: 'none'
                }
              }}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
            </IconButton>
        </Toolbar>
        <Avatar
          alt={'logo'}
          src={'./logo.png'}
          sx={{
            width: open ? 100 : 50,
            height: open ? 100 : 50,
            marginLeft: open ? 5 : 1,
            cursor: 'pointer'
          }}
          onClick={() => {
            navigate('/freak');
          }}
        />
        <Box sx={{ overflowX: 'hidden' }}>
          <List>
            {
              location?.pathname === '/freak' ||
              location?.pathname === '/' ||
              location?.pathname === '/places'
              ?             <ListItem
                  key={"Add"}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: DEFAULT_THEME_COLOR
                    },
                    border: 'none',
                  }}
                  onClick={() => {
                    if (location?.pathname === '/freak') {
                      navigate('/freak-editor');
                    } else if (location?.pathname === '/') {
                      navigate('/editor');
                    } else if (location?.pathname === '/places') {
                      navigate('/places-editor');
                    }
                  }}
                >
                  <ListItemIcon sx={{ }}>
                    <AddIcon sx={{
                      backgroundColor: DEFAULT_THEME_COLOR,
                      borderRadius: 10,
                      width: 35,
                      height: 35,
                    }}/>
                  </ListItemIcon>
                  <ListItemText
                    primary={translation.leftNavigation.add}
                  />
                </ListItem>
                : null
            }

            <ListItem
              key={"Freaks"}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: DEFAULT_THEME_COLOR
                },
                border: selectedMenuItem === 'freaks' ? '1px solid #36c9a2' : 'none',
              }}
              onClick={() => {
                setSelectedMenuItem('freaks');
                navigate('/freak')
              }}
            >
              <ListItemIcon sx={{ }}>
                {getFreakIcon(context)}
              </ListItemIcon>
              <ListItemText
                primary={translation.leftNavigation.freaks}
              />
            </ListItem>

            <ListItem
              key={"Places"}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: DEFAULT_THEME_COLOR
                },
                border: selectedMenuItem === 'places' ? '1px solid #36c9a2' : 'none',
              }}
              onClick={() => {
                setSelectedMenuItem('places');
                navigate('/places');
              }}
            >
              <ListItemIcon sx={{ }}>
                <TravelExploreIcon />
              </ListItemIcon>
              <ListItemText primary={translation.leftNavigation.places} />
            </ListItem>

            <ListItem
              key={"Campaigns"}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: DEFAULT_THEME_COLOR
                },
                border: selectedMenuItem === 'campaigns' ? '1px solid #36c9a2' : 'none',
              }}
              onClick={() => {
                setSelectedMenuItem('campaigns');
                navigate('/campaigns');
              }}
            >
              <ListItemIcon sx={{ }}>
                <CampaignIcon />
              </ListItemIcon>
              <ListItemText primary={translation.leftNavigation.campaigns} />
            </ListItem>

            {
              location?.pathname === '/freak' ||
              location?.pathname === '/places'
                ? <ListItem
                    key={"Filters"}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: DEFAULT_THEME_COLOR
                      },
                      border: selectedMenuItem === 'notifications' ? '1px solid #36c9a2' : 'none',
                    }}
                    onClick={() => setOpenModal(true)}
                >
                  <ListItemIcon sx={{ }}>
                    <Badge
                      badgeContent={filtersBadgeCount}
                      color="primary"
                    >
                      <FilterAltIcon />
                    </Badge>

                  </ListItemIcon>
                  <ListItemText primary={translation.leftNavigation.filters} />
                </ListItem>
                : null
            }
            <ListItem
              key={"Notifications"}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: DEFAULT_THEME_COLOR
                },
                border: selectedMenuItem === 'notifications' ? '1px solid #36c9a2' : 'none',
              }}
              onClick={() => {
                setSelectedMenuItem('notifications')
                navigate('/notifications');
              }}
            >
              <ListItemIcon sx={{ }}>
                <Badge
                  badgeContent={props.common.notificationsCount || notificationsCount}
                  color="primary"
                >
                  <NotificationsNoneIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={translation.leftNavigation.notifications} />
            </ListItem>

            <ListItem
              key={"Messages"}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: DEFAULT_THEME_COLOR
                },
                border: selectedMenuItem === 'messages' ? '1px solid #36c9a2' : 'none',
              }}
              onClick={() => {
                setSelectedMenuItem('messages');
                navigate('/messages')
              }}
            >
              <ListItemIcon sx={{ }}>
                <Badge
                  badgeContent={props.unreadMessagesCount || 0}
                  color="primary"
                >
                  <MailOutlineIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={translation.leftNavigation.messages} />
            </ListItem>

            <ListItem
              key={"Top Freaks"}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: DEFAULT_THEME_COLOR
                },
                border: selectedMenuItem === 'top-freaks' ? '1px solid #36c9a2' : 'none',
              }}
              onClick={() => {
                setSelectedMenuItem('top-freaks');
                navigate('/top-freaks');
              }}
            >
              <ListItemIcon sx={{ }}>
                <EmojiEventsIcon />
              </ListItemIcon>
              <ListItemText primary={translation.leftNavigation.topFreaks} />
            </ListItem>

            <ListItem
              key={"Context"}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: DEFAULT_THEME_COLOR
                },
                border: selectedMenuItem === 'context-change' ? '1px solid #36c9a2' : 'none',
              }}
              onClick={() => {
                setSelectedMenuItem('context-change');
                navigate('/context-change');
              }}
            >
              <ListItemIcon sx={{ }}>
                {getFreakIcon(context)}
              </ListItemIcon>
              <ListItemText primary={translation.leftNavigation.context} />
            </ListItem>

            <ListItem
              key={"Profile"}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: DEFAULT_THEME_COLOR
                },
                border: selectedMenuItem === 'profile' ? '1px solid #36c9a2' : 'none',
                marginLeft: -1,
              }}
              onClick={() => {
                setSelectedMenuItem('profile');
                navigate(`/@${props?.currentUser?.username}`);
              }}
            >
              <ListItemIcon sx={{ }}>
                <Avatar
                  alt={props?.currentUser?.username}
                  src={props?.currentUser?.imageUrl}
                />
              </ListItemIcon>
              <ListItemText primary={translation.leftNavigation.profile} />
            </ListItem>

          </List>
        </Box>
      </Drawer>
      <Modal
        open={openModal}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
        }}>
          <FiltersComponent pathname={location?.pathname} setOpen={() => setOpenModal(false)}/>
        </Box>
      </Modal>
    </Box>
  ) : null;
};

const mapStateToProps = state => ({
  ...state,
  currentUser: state.common.currentUser,
  context: state.common.context,
  unreadMessagesCount: state.common.unreadMessagesCount,
  publicFreakPlaceFilter: state.common.publicFreakPlaceFilter,
  privateFreakPlaceFilter: state.common.privateFreakPlaceFilter,
  sortByDateFilter: state.common.sortByDateFilter,
  sortByLikesFilter: state.common.sortByLikesFilter,
  filterByNameFilter: state.common.filterByNameFilter,
  filterByDateFromFilter: state.common.filterByDateFromFilter,
  filterByDateToFilter: state.common.filterByDateToFilter,
  filterByStatusFilter: state.common.filterByStatusFilter,
  filterByParticipantFilter: state.common.filterByParticipantFilter,
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload =>
    dispatch({type: BOTTOM_NAVIGATION_LOADED, payload}),
  setUnreadMessages: payload =>
    dispatch({ type: BOTTOM_NAVIGATION_SET_UNREAD_MESSAGES, payload }),
  setLeftNavigationOpen: payload =>
    dispatch({ type: LEFT_NAVIGATION_OPEN, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavigationComponent);
