import React from 'react';
import TopBar from "../TopBar";
import FreakListComponent from "../List/FreakListComponent";
import useTranslation from "../../customHooks/translations";
import { useLocation } from "react-router-dom";

const UsersListView = (props) => {
  const translation = useTranslation();
  const location = useLocation();
  const locationState = location?.state?.usersList || [];

  return (
    <div>
      <TopBar/>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '80vh',
        paddingTop: 30,
      }}>
        <FreakListComponent
          title={translation.usersList.title}
          secondaryTextKey={"points"}
          secondaryTextToConcat={translation.topFreaks.points}
          data={locationState}
        />
      </div>
    </div>
  )
};

export default UsersListView;
