import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ReadMoreComponent from "../ReadMoreComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import IconButton from "@mui/material/IconButton";
import ConfirmationModal from "../ConfirmationModal";
import agent from '../../agent';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import {Link, useNavigate} from "react-router-dom";

const SocialListComponent = (props) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedSocial, setSelectedSocial] = useState(null);
  const [socialFeed, setSocialFeed] = useState([]);

  const fetchSocialFeed = async () => {
    const username = props.forUsername?.replace('@', '');
    const results = await agent.Feeds.list(props.social, username);
    setSocialFeed(results);
  };

  useEffect(() => {
    fetchSocialFeed();
  }, [props.isAddingFeed]);

  const handleOpenModal = social => {
    setModalOpen(true);
    setSelectedSocial(social);
  };
  const handleCloseModal = () => setModalOpen(false);

  const handleDelete = async () => {
    await agent.Feeds.disconnectSocial(selectedSocial.id);
    const results = await agent.Feeds.list(props.social);
    setSocialFeed(results);
    setModalOpen(false);
  };

  return (
    <>
      <List sx={{
        width: '100%',
      }}>
        {
          socialFeed
            ? socialFeed.map(social => (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={social.title} src={social.thumbnail} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={social.title}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {social.name}
                        </Typography>
                        <ReadMoreComponent text={social.description} maxChars={100}/>
                      </React.Fragment>
                    }
                  />
                  {
                    props.showFeedButton
                    ?  <IconButton
                        variant="contained"
                        style={{
                          height: 25,
                          outline: 'none',
                        }}
                        onClick={() => {
                          navigate('/settings', {
                            state: {
                              youtube: social.forHandle
                            }
                          })
                        }}
                      >
                        <DynamicFeedIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                      </IconButton>
                      : null
                  }
                  {
                    props.showSubscribeButton
                    ? <IconButton
                        variant="contained"
                        style={{
                          height: 25,
                          outline: 'none',
                        }}
                        component={Link}
                        target="_blank"
                        rel="noopener"
                        to={`https://www.youtube.com/${social.forHandle}`} // @todo other socials when ready
                        aria-label="subscribe"
                      >
                        <SubscriptionsIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                      </IconButton>
                      : null
                  }
                  {
                    !props.hideDelete
                     ? <IconButton
                        variant="contained"
                        style={{
                          height: 25,
                          outline: 'none',
                        }}
                        onClick={() => handleOpenModal(social)}
                      >
                        <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                      </IconButton>
                      : null
                  }

                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ))
            : null
        }
      </List>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleDelete}
        hideUndone={true}
      />
    </>
  );
};

export default SocialListComponent;
