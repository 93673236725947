import React from "react";
import { connect } from "react-redux";
import {useMediaQuery} from "react-responsive";
import TopBar from "../TopBar";
import SocialListComponent from "../List/SocialListComponent";
import {useParams} from "react-router-dom";

const FeedsView = (props) => {
  const params = useParams();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);

  return (
    <div style={{
      paddingLeft,
    }}>
      <TopBar/>
      <SocialListComponent
        social={params.social}
        forUsername={params.username}
        hideDelete={true}
        isAddingFeed={false}
        showFeedButton={true}
        showSubscribeButton={true}
      />
    </div>
  )
};

const mapStateToProps = state => ({
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FeedsView);
