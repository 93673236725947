import React, { createContext, useState, useContext } from "react";
import { getLanguageByBrowser } from "../utils/languageUtils";

const LanguageContext = createContext();

export const LanguageContextProvider = ({ children }) => {
  const [language, changeLanguage] = useState(getLanguageByBrowser());

  const handleChangeLanguage = (newLanguage) => {
    changeLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, handleChangeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext);
