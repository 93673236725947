import React, { useState } from 'react';
import { DropzoneArea } from 'mui-file-dropzone';

const FileDropzoneArea = (props) => {
  const [files, setFiles] = useState(null);

  const handleChange = (files) => {
    setFiles(files)

    props.onFileUpload(files)
  }

  return (
    <DropzoneArea
      dropzoneClass={'height: 100px; border: none;'}
      dropzoneText={props.dropzoneText || ''} onChange={handleChange}
      acceptedFiles={["image/jpeg", "image/png", "image/bmp", "image/heic"]}
      maxFileSize={20000000}
      filesLimit={1}
    />
  )
};
export default FileDropzoneArea;
