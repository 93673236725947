import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import agent from "./agent";
import { sendNativeNotification } from "./notifications/notificationHelpers";

// Firebase config
const REACT_APP_FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const REACT_APP_FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const REACT_APP_FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const REACT_APP_FIREBASE_STORAGE_BUCKET =  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const REACT_APP_FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const REACT_APP_FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
const REACT_APP_FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

let firebaseApp = null;
let messaging = null;

// Check if Cordova is present
if (window.cordova) {
  console.log("Running in Cordova environment");
  // Cordova-specific Firebase setup will be handled by the plugin
} else {
  console.log("Running in Web environment");
  // Initialize Firebase for the web
  firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
}

export const setupToken = async () => {
  if (window.cordova) {
    // Get FCM token using the Cordova plugin
    try {
      const token = await window.FirebasePlugin.getToken();
      await agent.Firebase.setFcmToken(token); // Send token to your server
    } catch (error) {
      console.error('Error getting FCM token in Cordova:', error);
    }
  } else {
    // Get the FCM token in the web environment
    try {
      const token = await getToken(messaging);
      await agent.Firebase.setFcmToken(token); // Send token to your server
    } catch (error) {
      console.error('Error getting FCM token for web:', error);
    }
  }
};

const setupNotifications = async (setSnackbar, notificationDeniedMessage) => {
  try {
    // Request permission for notifications
    if (window.cordova) {
      // Use Cordova's Firebase plugin to request permission
      window.FirebasePlugin.grantPermission((hasPermission) => {
        if (hasPermission) {
          setupToken(); // Call to store the token
          if (setSnackbar) {
            setSnackbar({
              open: true,
              message: 'Notifications permission granted',
              severity: 'info',
              hideOpenButton: true,
            });
          } else {
            alert('Notifications permission granted');
          }
        } else {
          alert('Notifications permission denied');
        }
      });
    } else {
      // Web notification permission request
      new Promise((resolve) => {
        Notification.requestPermission(resolve)?.then(resolve);
      }).then(permission => {
        if (permission === 'granted') {
          setupToken(); // Not await
          if (setSnackbar) {
            setSnackbar({
              open: true,
              message: 'Notifications permission granted',
              severity: 'info',
              hideOpenButton: true,
            });
          } else {
            alert(`Notifications: ${permission}`);
          }
        } else {
          console.log('Notification permission denied.');
          if (setSnackbar) {
            setSnackbar({
              open: true,
              message: notificationDeniedMessage,
              severity: 'info',
              hideOpenButton: true,
            });
          } else {
            alert(`Notifications: ${permission}`);
          }
        }
      });
    }

    const fetchNotificationsCount = async () => {
      const notificationsResponse = await agent.Notifications.count();
      if (notificationsResponse.count ===  0) {
        await navigator.clearAppBadge();
      } else {
        await navigator.setAppBadge(notificationsResponse.count);
      }
    };

    if (!window.cordova) {
      // Web: Handle foreground notifications
      onMessage(messaging, (payload) => {
        console.log('Foreground Message:', payload);

        const { title, body } = payload.notification;

        // Show the snackbar with the notification
        setSnackbar({
          open: true,
          message: `${title}: ${body}`,
          severity: 'info',
        });

        sendNativeNotification({ title, body });

        fetchNotificationsCount();
      });
    } else {
      // Cordova: Handle foreground notifications with Firebase Plugin
      window.FirebasePlugin.onMessageReceived((payload) => {
        console.log('Foreground Message (Cordova):', payload);

        const { title, body } = payload;

        // Show the snackbar with the notification
        setSnackbar({
          open: true,
          message: `${title}: ${body}`,
          severity: 'info',
        });

        sendNativeNotification({ title, body });

        fetchNotificationsCount();
      });
    }
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

export { messaging, setupNotifications };
