import React from 'react';
import { connect } from "react-redux";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BackdropWrapper = (props) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.isLoading || false}
      onClick={() => {}}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
};

const mapStateToProps = state => ({
  ...state,
  isLoading: state.common.isLoading
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BackdropWrapper);
