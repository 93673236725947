import ListErrors from './ListErrors';
import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import agent from '../agent';
import {connect} from 'react-redux';
import {
  ADD_TAG,
  EDITOR_PAGE_LOADED,
  REMOVE_TAG,
  ARTICLE_SUBMITTED,
  EDITOR_PAGE_UNLOADED,
  UPDATE_FIELD_EDITOR,
} from '../constants/actionTypes';
import Thumbnails from "./Thumbnails";
import UploadFiles from "./UploadFiles";
import TopBar from "./TopBar";
import useTranslation from "../customHooks/translations";

const removeTagHandler = (tag, props) => {
  props.onRemoveTag(tag);
};

const onChangeTagInput = (event, props) => {
  const key = 'tagInput';
  const value = event.target.value;
  props.onUpdateField(key, value);
};

const watchForEnter = (event, props) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    props.onAddTag();
  }
};
const submitForm = (event, props) => {
  event.preventDefault();
  const article = {
    images: props.images?.map(image => image.name),
    videos: props.videos?.map(video => video.name),
    body: props.body,
    tagList: props.tagList
  };

  if (props.oldTitle !== props.title) {
    Object.assign(article, {title: props.title});
  }

  const promise = props.slug
    ? agent.Articles.update(Object.assign(article, {
      slug: props.slug,
    }))
    : agent.Articles.create(article);

  props.onSubmit(promise);
};

const ArticleEditor = (props) => {
  const translation = useTranslation();
  const {slug} = useParams();
  const [title, setTitle] = useState('');
  const [oldTitle, setOldTitle] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [body, setBody] = useState('');
  const [tagList, setTagList] = useState([]);

  const [saveDisabled, setSaveDisabled] = useState(true);

  const handleImageUpload = (file) => {
    const newImage = {
      id: file?.data?.id,
      imageUrl: file?.data?.fileUrl,
      name: file?.data?.filename,
    };
    setImages((prevImages) => [...prevImages, newImage]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const formData = await agent.Articles.get(slug, props.device);
      if (formData) {
        setTitle(formData?.article?.title || '');
        setOldTitle(formData?.article?.title || '');
        setImages(formData?.article?.images || []);
        setVideos(formData?.article?.videos || []);
        setBody(formData?.article?.body || '');
        setTagList(formData?.article?.tagList || []);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setSaveDisabled(!(title.trim() && body.trim()));
  },[title, body]);

  return (
    <>
      <TopBar />
      <div className="editor-page">
        <div className="container page">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-xs-12">

              <ListErrors errors={props.errors}></ListErrors>

              <form>
                <fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      placeholder={translation.editor.placeholders.postTitle}
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                  </fieldset>

                  {
                    images && images.length
                      ? <Thumbnails images={images} handleDeleteFn={setImages}/>
                      : null
                  }

                  <fieldset className="form-group">
                    <UploadFiles
                      onFileUpload={handleImageUpload}
                      setSaveDisabled={setSaveDisabled}
                      dest={"article"}
                    />
                  </fieldset>

                  <fieldset className="form-group">
                      <textarea
                        className="form-control"
                        rows="8"
                        placeholder={translation.editor.placeholders.postContent}
                        value={body}
                        onChange={(event) => {
                          setBody(event?.target?.value);
                        }}
                      >
                      </textarea>
                  </fieldset>

                  {/*<fieldset className="form-group">*/}
                  {/*  <input*/}
                  {/*    className="form-control"*/}
                  {/*    type="text"*/}
                  {/*    placeholder="Post tags"*/}
                  {/*    value={props.tagInput}*/}
                  {/*    onChange={(event) => onChangeTagInput(event, props)}*/}
                  {/*    onKeyUp={(event) => watchForEnter(event, props)}*/}
                  {/*  />*/}

                  {/*  <div className="tag-list">*/}
                  {/*    {*/}
                  {/*      (props.tagList || []).map(tag => {*/}
                  {/*        return (*/}
                  {/*          <span className="tag-default tag-pill" key={tag}>*/}
                  {/*            <i className="ion-close-round"*/}
                  {/*               onClick={() => removeTagHandler(tag)}>*/}
                  {/*            </i>*/}
                  {/*            {tag}*/}
                  {/*          </span>*/}
                  {/*        );*/}
                  {/*      })*/}
                  {/*    }*/}
                  {/*  </div>*/}
                  {/*</fieldset>*/}

                  <button
                    className="btn btn-lg pull-xs-right btn-primary"
                    type="button"
                    disabled={saveDisabled}
                    onClick={(event) => submitForm(event, {
                      ...props,
                      slug,
                      title,
                      oldTitle,
                      images,
                      body,
                      tagList,
                    })}>
                    {translation.editor.save}
                  </button>

                </fieldset>
              </form>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state.editor,
  device: state.common.device,
});

const mapDispatchToProps = dispatch => ({
  onAddTag: () =>
    dispatch({type: ADD_TAG}),
  onLoad: payload =>
    dispatch({type: EDITOR_PAGE_LOADED, payload}),
  onRemoveTag: tag =>
    dispatch({type: REMOVE_TAG, tag}),
  onSubmit: payload =>
    dispatch({type: ARTICLE_SUBMITTED, payload}),
  onUnload: payload =>
    dispatch({type: EDITOR_PAGE_UNLOADED}),
  onUpdateField: (key, value) =>
    dispatch({type: UPDATE_FIELD_EDITOR, key, value}),
});


export default connect(mapStateToProps, mapDispatchToProps)(ArticleEditor);
