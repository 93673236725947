import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useNavigate, useParams} from 'react-router-dom';

import Thumbnails from '../Thumbnails';
import UploadFiles from "../UploadFiles";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FreakParticipantsList from './FreakParticipantsList';
import FreakSelect from './FreakSelect';
import FreakLocalization from './FreakLocalization';
import {
  ADD_TAG,
  FREAK_SUBMITTED,
  FREAK_EDITOR_PAGE_LOADED,
  FREAK_EDITOR_PAGE_UNLOADED,
  REMOVE_TAG,
  UPDATE_FIELD_FREAK_EDITOR,
  UPLOAD_FREAK_VIDEO,
} from '../../constants/actionTypes';
import agent from '../../agent';
import DatePickerComponent from '../DatePickerComponent';
import ListErrors from "../ListErrors";
import TextField from '@mui/material/TextField';
import TopBar from "../TopBar";
import {
  getMapObject,
  validateYouTubeLink,
  handleAddYouTubeLink,
  validateFacebookLink,
  handleAddFacebookLink,
  validateInstagramLink,
  handleAddInstagramLink, getYouTubeLinkVideoId
} from "../../utils/freakUtils";
import useTranslation from "../../customHooks/translations";
import Grid from "@mui/material/Grid";
import CarouselComponent from "../Embed/CarouselComponent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import ThumbnailsEmbed from "../Embed/ThumbnailsEmbed";
import InstagramIcon from "@mui/icons-material/Instagram";
import Typography from "@mui/material/Typography";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {DEFAULT_THEME_COLOR, DEFAULT_THEME_COLOR_GREY} from "../../constants/theme";
import AddIcon from "@mui/icons-material/Add";
import {formatDate} from "../../utils/dateUtils";
import Button from "@mui/material/Button";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import ShortsCreatorComponent from "../FreaksCreator/ShortsCreatorComponent";
import FreakPlaceLocalizationComponent from "../FreakPlaceLocalization/FreakPlaceLocalizationComponent";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import {useMediaQuery} from "react-responsive";

const onChangeTagInput = (event, props) => {
  const key = 'tagInput';
  const value = event.target.value;
  props.onUpdateField(key, value);
};
const watchForEnter = (event, props) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    props.onAddTag();
  }
};
const removeTagHandler = (tag, props) => {
  props.onRemoveTag(tag);
};
const onParticipantSelect = (currentParticipants, participantsToAdd, setParticipants) => {
  const updatedParticipants = currentParticipants || [];
  for (const participant of participantsToAdd) {
    const alreadyExists = updatedParticipants?.find(item => item.username === participant.username);
    if (!alreadyExists) {
      updatedParticipants.push(participant);
    }
  }
  setParticipants(updatedParticipants)
};
const onLocationSelect = (localizationMeta, setLocalization) => {
  setLocalization(localizationMeta);
};
const onFreakStartDateChange = (startDate, setStartDate) => {
  setStartDate(startDate);
};

const submitForm = (event, props) => {
  event.preventDefault();

  const freak = {
    title: props.title,
    description: props.description,
    context: props.context,
    images: props.images?.map(image => image.name),
    videos: props.videos?.map(video => video.name),
    linksYoutube: props.linksYoutube?.filter(linkYoutube => {
      if (!linkYoutube.id && linkYoutube.name) {
        return linkYoutube.name;
      }
      if (!linkYoutube.id && !linkYoutube.name) {
        return linkYoutube;
      }
    }),
    linksFacebook: props.linksFacebook?.filter(linkFacebook => {
      if (!linkFacebook.id && linkFacebook.name) {
        return linkFacebook.name;
      }
      if (!linkFacebook.id && !linkFacebook.name) {
        return linkFacebook;
      }
    }),
    linksInstagram: props.linksInstagram?.filter(linkInstagram => {
      if (!linkInstagram.id && linkInstagram.name) {
        return linkInstagram.name;
      }
      if (!linkInstagram.id && !linkInstagram.name) {
        return linkInstagram;
      }
    }),
    body: props.body,
    tagList: props.tagList || [],
    participants: props?.participants?.map(participant => participant.username) || [],
    map: props.map || {},
    startDate: props.startDate,
    public: props.isPrivate !== undefined ? !props.isPrivate : true,
    shouldCreatePlace: props.shouldCreatePlace,
  };

  const slug = {slug: props.slug};
  const promise = props.slug
    ? agent.Freaks.update(Object.assign(freak, slug))
    : agent.Freaks.create(freak);

  props.onSubmit(promise);
};

const FreakEditor = (props) => {
  const context = props.context;
  const device = props.device;

  const translation = useTranslation();
  const navigate = useNavigate();
  const {slug} = useParams();
  const [freakId, setFreakId] = useState(null);
  const [title, setTitle] = useState('');
  const [oldTitle, setOldTitle] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [linksYoutube, setLinksYoutube] = useState([]);
  const [linksFacebook, setLinksFacebook] = useState([]);
  const [linksInstagram, setLinksInstagram] = useState([]);
  const [body, setBody] = useState('');
  const [tagList, setTagList] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [localization, setLocalization] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [isPrivate, setIsPrivate] = useState(false);
  const [shouldCreatePlace, setShouldCreatePlace] = useState(false);
  const [author, setAuthor] = useState(null);
  const [isAuthor, setIsAuthor] = useState(false);

  const [isValidYouTubeLink, setIsValidYouTubeLink] = useState(true);
  const [youTubeLink, setYouTubeLink] = useState('');

  const [isValidFacebookLink, setIsValidFacebookLink] = useState(true);
  const [facebookLink, setFacebookLink] = useState('');

  const [isValidInstagramLink, setIsValidInstagramLink] = useState(true);
  const [instagramLink, setInstagramLink] = useState('');

  const [showCreateFreaks, setShowCreateFreaks] = useState(false);

  const [saveDisabled, setSaveDisabled] = useState(true);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    // set current location as default on create
    const fetchCurrentMapObject = async () => {
      const mapObject = await getMapObject();

      setLocalization(mapObject)
    };

    if (!slug && !localization) {
      fetchCurrentMapObject();
    }
  }, []);

  useEffect(() => {
    setSaveDisabled(!(title.trim() && body.trim()));
  }, [title, body]);

  const handleImageUpload = (file) => {
    const newImage = {
      id: file?.data?.id,
      imageUrl: file?.data?.fileUrl,
      name: file?.data?.filename,
    };
    setImages((prevImages) => [...prevImages, newImage]);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (slug) {
        const formData = await agent.Freaks.get(slug, device);
        if (formData) {
          setFreakId(formData?.freak.id);
          setTitle(formData?.freak?.title || '');
          setOldTitle(formData?.freak?.title || '');
          setImages(formData?.freak?.images || []);
          setVideos(formData?.freak?.videos || []);
          setLinksYoutube(formData?.freak?.linksYoutube || []);
          setLinksFacebook(formData?.freak?.linksFacebook || []);
          setLinksInstagram(formData?.freak?.linksInstagram || []);
          setBody(formData?.freak?.body || '');
          setTagList(formData?.freak?.tagList || []);
          setParticipants(formData?.freak?.participants || []);
          setLocalization(formData?.freak?.map || null);
          setStartDate(formData?.freak?.startDate || null);
          setIsPrivate(!formData?.freak?.public);
          setAuthor(formData?.freak?.author);
          setIsAuthor(formData?.freak?.author?.username === props?.currentUser?.username)
        }
      } else {
        setTitle(`${props.currentUser.username} : Freak ${formatDate(new Date(), true)}`);
        setBody(translation.freakEditor.defaults.body);
      }
    };
    fetchData();
  }, []);

  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);
  return (
    <>
      <TopBar/>
      <div
        className="editor-page"
        style={{
          paddingLeft,
        }}
      >
        <div className="banner">
          <IconButton sx={{color: 'white'}} aria-label="back" onClick={() => {
            navigate(-1);
          }}>
            <ArrowBackIosNewOutlinedIcon sx={{
              marginRight: 2,
              color: DEFAULT_THEME_COLOR
            }}
            />
            <Typography variant="h4" sx={{color: 'white'}}>
              {title}
            </Typography>
          </IconButton>
        </div>
        <div className="container page">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-xs-12">

              <ListErrors errors={props.errors}></ListErrors>

              <form>
                <fieldset>
                  <fieldset className="form-group">
                    <input
                      style={{
                        backgroundColor: 'transparent',
                        color: DEFAULT_THEME_COLOR,
                        border: '0.5px solid #F1F1F1',
                      }}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder={translation.freakEditor.placeholders.freakTitle}
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                  </fieldset>

                  {
                    images && images.length
                      ? <Thumbnails images={images} handleDeleteFn={setImages}/>
                      : null
                  }

                  <fieldset className="form-group">
                    <UploadFiles
                      onFileUpload={handleImageUpload}
                      setSaveDisabled={setSaveDisabled}
                      dest={"freak"}
                    />
                  </fieldset>

                  {
                    window.cordova
                      ? <fieldset className="form-group">
                        {
                          showCreateFreaks
                            ? <ShortsCreatorComponent afterSaveFn={setShowCreateFreaks} />
                            : <Button
                              sx={{
                                backgroundColor: DEFAULT_THEME_COLOR,
                                color: 'white',
                                borderColor: DEFAULT_THEME_COLOR,
                                'z-index': '0',
                                marginLeft: 1,
                                marginTop: 2,
                                '&:hover': {
                                  color: DEFAULT_THEME_COLOR,
                                  borderColor: DEFAULT_THEME_COLOR,
                                }
                              }}
                              variant="outlined"
                              startIcon={<SlowMotionVideoIcon />}
                              onClick={() => {
                                setShowCreateFreaks(true);
                              }}
                            >
                              {translation.freakEditor.createFreaks}
                            </Button>
                        }
                      </fieldset>
                      : null
                  }

                  <fieldset className="form-group">
                    <TextareaAutosize
                      style={{
                        backgroundColor: 'transparent',
                        color: DEFAULT_THEME_COLOR,
                        minHeight: 200,
                        border: '0.5px solid #F1F1F1',
                      }}
                      variant="solid"
                      className="form-control form-control-lg"
                      rows="8"
                      placeholder={translation.freakEditor.placeholders.freakContent}
                      value={body}
                      onChange={(event) => {
                        setBody(event?.target?.value);
                      }}>
                    </TextareaAutosize>
                  </fieldset>

                  {
                    participants && participants.length
                    ? <>
                      <hr/>
                      <FreakParticipantsList
                        freakId={freakId}
                        author={author}
                        participants={participants}
                        isAuthor={isAuthor}
                      />
                    </>
                    : null
                  }

                  <hr/>
                  <FreakSelect
                    onSelect={(participantsToAdd => onParticipantSelect(participants, participantsToAdd, setParticipants))}
                  />

                  <hr />
                  <FreakPlaceLocalizationComponent
                    onSelect={(localization => onLocationSelect(localization, setLocalization))}
                  />

                  <hr/>
                  <FreakLocalization
                    localization={localization}
                    onSelect={(localization => onLocationSelect(localization, setLocalization))}
                  />

                  {
                    !slug
                      ? <FormGroup sx={{marginTop: 2, marginLeft: 2}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={shouldCreatePlace}
                              onChange={(event, value) => {
                                setShouldCreatePlace(value);
                              }}
                            />
                          }
                          label={translation.freakEditor.createPlace}
                        />
                      </FormGroup>
                      : <Button
                        sx={{
                          backgroundColor: DEFAULT_THEME_COLOR,
                          color: 'white',
                          borderColor: DEFAULT_THEME_COLOR,
                          'z-index': '0',
                          marginLeft: 1,
                          marginTop: 2,
                          '&:hover': {
                            color: DEFAULT_THEME_COLOR,
                            borderColor: DEFAULT_THEME_COLOR,
                          }
                        }}
                        variant="outlined"
                        startIcon={<TravelExploreIcon />}
                        onClick={() => navigate('/places') }
                      >
                        {translation.freakEditor.showInPlaces}
                      </Button>
                  }

                  <FormGroup sx={{ marginTop: 2, marginLeft: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPrivate}
                          onChange={(event, value) => {
                            setIsPrivate(value);
                          }}
                        />
                      }
                      label={translation.freakEditor.isPrivateLabel}
                    />
                  </FormGroup>

                  <hr/>
                  <DatePickerComponent
                    value={startDate}
                    onChange={(startDate) => onFreakStartDateChange(startDate, setStartDate)}
                  />

                  <hr/>
                  {
                    linksYoutube && linksYoutube.length
                      ? <Thumbnails
                        images={linksYoutube.map(link => {
                          return link.id
                            ? {
                              ...link,
                              imageUrl: link.imageUrl || link.thumbnail,
                            }
                            : {
                              name: getYouTubeLinkVideoId(link),
                              imageUrl: link.imageUrl || `https://img.youtube.com/vi/${getYouTubeLinkVideoId(link)}/hqdefault.jpg`,
                            }
                        })}
                        handleDeleteFn={setLinksYoutube}/>
                      : null
                  }

                  <fieldset className="form-group">
                    <Grid container>
                      <Grid item xs={12}>
                        {
                          linksYoutube?.length
                            ? <CarouselComponent youtubeEmbeds={linksYoutube.map(link => link.url)}/>
                            : null
                        }
                      </Grid>
                      <Grid container sx={{
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Grid item xs={2}>
                          <IconButton
                            component={Link}
                            to={"https://www.youtube.com"}
                            aria-label="Navigate"
                          >
                            <YouTubeIcon sx={{color: '#FF0000', width: 40, height: 40}}/>
                          </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            label={translation.linksYoutube.placeholders.add}
                            variant="outlined"
                            fullWidth
                            value={youTubeLink}
                            onChange={(e) => validateYouTubeLink(e.target.value, setIsValidYouTubeLink, setYouTubeLink)}
                            error={!isValidYouTubeLink}
                            helperText={!isValidYouTubeLink && translation.linksYoutube.placeholders.invalidLink}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              setYouTubeLink('');
                              setIsValidYouTubeLink(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            disabled={youTubeLink && !isValidYouTubeLink}
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              handleAddYouTubeLink(youTubeLink, linksYoutube, setLinksYoutube, setYouTubeLink);
                            }}
                          >
                            <AddIcon sx={{
                              color: youTubeLink && !isValidYouTubeLink ? DEFAULT_THEME_COLOR_GREY : DEFAULT_THEME_COLOR
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </fieldset>

                  {
                    linksFacebook && linksFacebook.length
                      ? <ThumbnailsEmbed
                        embeds={linksFacebook.map(link => {
                          return link.id
                            ? {
                              ...link,
                              imageUrl: link.url,
                            }
                            : {
                              name: link,
                              imageUrl: link
                            }
                        })}
                        handleDeleteFn={setLinksFacebook}
                        embedType={'facebook'}
                      />
                      : null
                  }

                  <fieldset className="form-group">
                    <Grid container>
                      <Grid item xs={12}>
                        {
                          linksFacebook?.length
                            ? <CarouselComponent facebookEmbeds={linksFacebook.map(link => link.url)}/>
                            : null
                        }
                      </Grid>
                      <Grid container sx={{
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Grid item xs={2}>
                          <IconButton
                            component={Link}
                            to={"https://www.facebook.com"}
                            aria-label="Navigate"
                          >
                            <FacebookIcon sx={{color: '#1877F2', width: 40, height: 40}}/>
                          </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            label={translation.linksFacebook.placeholders.add}
                            variant="outlined"
                            fullWidth
                            value={facebookLink}
                            onChange={(e) => validateFacebookLink(e.target.value, setIsValidFacebookLink, setFacebookLink)}
                            error={!isValidFacebookLink}
                            helperText={!isValidFacebookLink && translation.linksFacebook.placeholders.invalidLink}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              setFacebookLink('');
                              setIsValidFacebookLink(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            disabled={facebookLink && !isValidFacebookLink}
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              handleAddFacebookLink(facebookLink, linksFacebook, setLinksFacebook, setFacebookLink);
                            }}
                          >
                            <AddIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </fieldset>

                  {
                    linksInstagram && linksInstagram.length
                      ? <ThumbnailsEmbed
                        embeds={linksInstagram.map(link => {
                          return link.id
                            ? {
                              ...link,
                              imageUrl: link.url,
                            }
                            : {
                              name: link,
                              imageUrl: link
                            }
                        })}
                        handleDeleteFn={setLinksInstagram}
                        embedType={'instagram'}
                      />
                      : null
                  }

                  <fieldset className="form-group">
                    <Grid container>
                      <Grid item xs={12}>
                        {
                          linksInstagram?.length
                            ? <CarouselComponent instagramEmbeds={linksInstagram.map(link => link.url)}/>
                            : null
                        }
                      </Grid>
                      <Grid container sx={{
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Grid item xs={2}>
                          <IconButton
                            component={Link}
                            to={"https://www.instagram.com"}
                            aria-label="Navigate"
                          >
                            <InstagramIcon sx={{color: 'rgb(30, 48, 80)', width: 40, height: 40}}/>
                          </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            label={translation.linksInstagram.placeholders.add}
                            variant="outlined"
                            fullWidth
                            value={instagramLink}
                            onChange={(e) => validateInstagramLink(e.target.value, setIsValidInstagramLink, setInstagramLink)}
                            error={!isValidInstagramLink}
                            helperText={!isValidInstagramLink && translation.linksInstagram.placeholders.invalidLink}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              setInstagramLink('');
                              setIsValidInstagramLink(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            disabled={instagramLink && !isValidInstagramLink}
                            style={{
                              height: 25,
                              outline: 'none'
                            }}
                            onClick={() => {
                              handleAddInstagramLink(instagramLink, linksInstagram, setLinksInstagram, setInstagramLink);
                            }}
                          >
                            <AddIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </fieldset>

                  <hr/>
                  <button
                    className="btn btn-lg pull-xs-right btn-primary"
                    style={{
                      marginBottom: 100,
                    }}
                    type="button"
                    disabled={saveDisabled}
                    onClick={(event) => submitForm(event, {
                      ...props,
                      slug,
                      title,
                      context,
                      oldTitle,
                      images,
                      linksYoutube,
                      linksFacebook,
                      linksInstagram,
                      body,
                      tagList,
                      participants,
                      map: localization,
                      startDate,
                      isPrivate,
                      shouldCreatePlace,
                    })}>
                    {translation.freakEditor.save}
                  </button>

                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

const mapStateToProps = state => ({
  ...state.freakEditor,
  device: state.common.device,
  currentUser: state.common.currentUser,
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({
  onAddTag: () =>
    dispatch({type: ADD_TAG}),
  onRemoveTag: tag =>
    dispatch({type: REMOVE_TAG, tag}),
  onLoad: payload =>
    dispatch({type: FREAK_EDITOR_PAGE_LOADED, payload}),
  onUnload: payload =>
    dispatch({type: FREAK_EDITOR_PAGE_UNLOADED}),
  onUpdateField: (key, value) =>
    dispatch({type: UPDATE_FIELD_FREAK_EDITOR, key, value}),
  onFileUpload: payload => {
    if (payload && payload.data) {
      const videosToUpload = [];
      videosToUpload.push(payload.data.filename)
      dispatch({
        type: UPLOAD_FREAK_VIDEO,
        payload: {
          videosToUpload
        }
      })
    }
  },
  onSubmit: payload =>
    dispatch({type: FREAK_SUBMITTED, payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakEditor);
