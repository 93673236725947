import {
  CAMPAIGN_FAVORITED,
  CAMPAIGN_SET_PAGE,
  CAMPAIGN_UNFAVORITED,
} from "../constants/actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case CAMPAIGN_FAVORITED:
    case CAMPAIGN_UNFAVORITED:
      return {
        ...state,
        campaigns: state.campaigns?.map(freak => {
          if (action.payload && action.payload.campaign && freak.slug === action.payload.campaign.slug) {
            return {
              ...freak,
              favorited: action.payload.campaign.favorited,
              favoritesCount: action.payload.campaign.favoritesCount
            };
          }
          return freak;
        })
      };
    case CAMPAIGN_SET_PAGE:
      return {
        ...state,
        campaigns: action.payload.campaigns,
        campaignsCount: action.payload.campaignsCount,
        currentPage: action.page
      };
    default:
      return state;
  }
};
