import React, { useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import marked from 'marked';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import ArticleMeta from './ArticleMeta';
import CommentContainer from '../comment/CommentContainer';
import agent from '../../agent';
import { ARTICLE_PAGE_LOADED, ARTICLE_PAGE_UNLOADED } from '../../constants/actionTypes';
import VideoPlayer from "../VideoPlayer";
import YoutubePlayer from "../YoutubePlayer";
import videojs from "video.js";
import NotFoundComponent from "../NotFoundComponent";
import ReadMoreComponent from "../ReadMoreComponent";
import ImageViewComponent from "../ImageView/ImageViewComponent";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";

export const Article = (props) => {
  const { id: articleId} = useParams();
  const navigate = useNavigate();
  const playerRef = React.useRef(null);

  useEffect(() => {
    props.onLoad(Promise.all([
      agent.Articles.get(articleId, props.device),
      agent.Comments.forArticle(articleId)
    ]));

    props.onUnload();
  }, []);

  if (!props.article || !props.article.body) {
    return (
      <NotFoundComponent text={"Loading..."}/>
    );
  }

  const markup = {
    __html: marked(props.article.body, { sanitize: true })
  };
  const canModify = props.currentUser &&
    props.currentUser.username === props.article.author.username;

  const getVideoPlayerOptions = video => ({
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: video.videoUrl,
      type: 'video/mp4'
    }]
  });

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  return (
    <div className="article-page">

      <div className="banner">
        <IconButton sx={{ color: 'white' }} aria-label="back" onClick={() => {
          navigate('/');
        }}>
          <ArrowBackIosNewOutlinedIcon
            sx={{
              marginRight: 2,
              marginTop: 1,
              color: DEFAULT_THEME_COLOR
            }}
          />
          <h1>{props.article.title}</h1>
        </IconButton>
        <div className="container">

          <ArticleMeta
            article={props.article}
            canModify={canModify}
          />
        </div>
      </div>
      <div className="container page">
        <fieldset className="form-group">
          { props.article?.images?.length
            ? <ImageViewComponent images={props.article.images} showThumbnails={true} />
            : (
              props.article?.videos?.length
                ? props.article.videos.map(video => (
                    <>
                      <VideoPlayer options={getVideoPlayerOptions(video)} onReady={handlePlayerReady} />
                      <br />
                    </>
                  ))
                : (
                  props.article?.linksYoutube?.length
                    ? <YoutubePlayer url={props.article.linksYoutube[0].url}/>
                    : null
                )
            )
          }
        </fieldset>
        <div className="row article-content">
          <div className="col-xs-12">

            <ReadMoreComponent text={props.article.body} />

            <ul className="tag-list">
              {
                props.article.tagList.map(tag => {
                  return (
                    <li
                      className="tag-default tag-pill tag-outline"
                      key={tag}>
                      {tag}
                    </li>
                  );
                })
              }
            </ul>

          </div>
        </div>

        <hr/>

        <div className="article-actions">
        </div>

        <div className="row">
          <CommentContainer
            type={'articles'}
            comments={props.comments || []}
            errors={props.commentErrors}
            slug={articleId}
            currentUser={props.currentUser}
          />
        </div>
      </div>

    </div>
  )
};

const mapStateToProps = state => ({
  ...state.article,
  currentUser: state.common.currentUser,
  device: state.common.device,
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload =>
    dispatch({type: ARTICLE_PAGE_LOADED, payload}),
  onUnload: () =>
    dispatch({type: ARTICLE_PAGE_UNLOADED})
});


export default connect(mapStateToProps, mapDispatchToProps)(Article);
