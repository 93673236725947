import React from 'react';
import {useNavigate} from "react-router-dom";
import TopBar from "../TopBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import useTranslation from "../../customHooks/translations";

const EmailConfirmedView = (props) => {
  const navigate = useNavigate();
  const translation = useTranslation();

  return (
    <>
      <TopBar />

      <Box
        display="flex"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        height="70vh"
      >
        <Grid container>
          <Grid item xs={12}>
            <span style={{ fontSize: '20px' }}>{translation.emailConfirmed.message}</span>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              style={{
                backgroundColor: DEFAULT_THEME_COLOR,
                color: 'white',
                alignSelf: 'flex-end',
                'z-index': '0',
                borderRadius: 4,
              }}
              onClick={() => navigate('/login')}
            >
              {translation.emailConfirmed.buttonMessage}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
};

export default EmailConfirmedView;
