import React from 'react';
import Grid from "@mui/material/Grid";

const LogoComponent = (props) => {
  return (
    <Grid display="flex" justifyContent="center" alignItems="center">
      <div style={{
        backgroundImage: 'url("./logo.png")',
        width: '100%',
        height: 150,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}></div>
    </Grid>
  )
};

export default LogoComponent;
