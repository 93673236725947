import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {IconButton} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useTranslation from "../../customHooks/translations";
import agent from "../../agent";
import CommonLoader from "../Loaders/CommonLoader";

const FreakPlaceLocalizationComponent = (props) => {
  const translation = useTranslation();
  const [data, setData] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  // load places
  useEffect(() => {
    setIsFetched(false);
    const fetchData = async () => {
      const results = await agent.FreaksPlaces.all(
        props.context || props.currentUser.context,
        true,
        true
      );

      if (!isFetched) {
        setData(results.freaksPlaces || []);
        setIsFetched(true);
      }
    };

    fetchData();
  }, []);

  return (
    <Stack spacing={3} sx={{
      width: '100%',
    }}>
      <Autocomplete
        options={data}
        loading={!isFetched}
        loadingText={
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <CommonLoader />
          </Box>
        }
        getOptionLabel={(option) => option.title}
        onChange={(event, value) => props.onSelect(value?.map)}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              srcSet={option?.images[0]?.imageUrl} // @todo default user image
              src={option.imageUrl}
              alt=""
            />
            {option.title}
          </Box>
        )}
        renderInput={(params) => {
          return (
            <TextField
              sx={{
                width: '100%',
              }}
              {
                ...params
              }
              endAdornment={
                <IconButton>
                  <SearchIcon />
                </IconButton>
              }
              size={'small'}
              variant="outlined"
              label={translation.freakSelect.placeholders.selectPlace}
              placeholder={translation.freakSelect.placeholders.searchPlace}
            />
          )
        }}
      />
    </Stack>
  );
};

const mapStateToProps = state => ({
  device: state.common.device,
  context: state.common.context,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FreakPlaceLocalizationComponent);
