import React from 'react';

import "./styles.css";

const CommonLoader = (props) => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};

export default CommonLoader;
