import React from 'react';
import TopBar from "../TopBar";
import useTranslation from "../../customHooks/translations";

const FreakUnauthorizedView = (props) => {
  const translation = useTranslation();

  return (
    <>
      <TopBar />
      <div className="article-preview"
           style={{
             minHeight: 150,
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             textAlign: 'center',
           }}>
        <span style={{ fontSize: '15px' }}>
          {translation.freaks.unauthorized}
        </span>
      </div>
    </>
  )
};

export default FreakUnauthorizedView;
