import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {connect} from 'react-redux';

import marked from 'marked';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import FreakMeta from './FreakMeta';
import CommentContainer from '../comment/CommentContainer';
import agent from '../../agent';
import {
  FREAK_PAGE_LOADED,
  FREAK_PAGE_UNLOADED, FREAK_SUBMITTED,
  UPLOAD_FREAK_IMAGE,
  UPLOAD_FREAK_VIDEO
} from '../../constants/actionTypes';
import FreakParticipantsList from './FreakParticipantsList';
import FreakLocalization from './FreakLocalization';
import NotFoundComponent from '../NotFoundComponent';
import Typography from '@mui/material/Typography';
import {calculateDuration, formatDate} from '../../utils/dateUtils';
import UploadFiles from "../UploadFiles";
import {FREAK_TYPE_PRIVATE, FREAK_TYPE_PUBLIC, STATUS_IN_PROGRESS} from "../../constants/commonConstants";
import ImageViewComponent from "../ImageView/ImageViewComponent";
import Grid from "@mui/material/Grid";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import useTranslation from "../../customHooks/translations";
import {
  getGoogleMapsLink,
  validateYouTubeLink,
  handleAddYouTubeLink,
  handleAddFacebookLink,
  validateFacebookLink,
  handleAddInstagramLink,
  validateInstagramLink,
  isFreakParticipant,
  isFeed, getLatLon,
} from "../../utils/freakUtils";
import NavigationIcon from '@mui/icons-material/Navigation';
import {Link} from 'react-router-dom';
import TextField from "@mui/material/TextField";
import CarouselComponent from "../Embed/CarouselComponent";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import ShortsCreatorComponent from "../FreaksCreator/ShortsCreatorComponent";
import RouteIcon from '@mui/icons-material/Route';
import FreakLocalizationTracker from "./FreakLocalizationTracker";
import FreakActivityComponent from "./FreakActivityComponent";
import {useMediaQuery} from "react-responsive";

// uploaded files handler
let uploadedFiles = [];

const onFileUpload = (file, props) => {
  props.onFileUpload(file);
};

const onSubmit = async (
  props,
  setImages,
  linksYoutube,
  setLinksYoutube,
  linksFacebook,
  setLinksFacebook,
  linksInstagram,
  setLinksInstagram
) => {
  const freak = {
    slug: props.freak.slug,
    images: uploadedFiles.map(file => file.name),
    linksYoutube: linksYoutube?.filter(linkYoutube => {
      if (linkYoutube.startsWith('https')) {
        return linkYoutube;
      }
    }),
    linksFacebook,
    linksInstagram,
  };

  // get freak route (if exists)
  if (props.recordingFreakRoute) {
    freak.route = props.recordingFreakRoute;
  }

  const promise = agent.Freaks.update(freak);
  try {
    await promise;
    // Reload images after successful update
    const updatedFormData = await agent.Freaks.get(props.freak.slug, props.device);
    setImages(updatedFormData?.freak?.images || []);
    setLinksYoutube(updatedFormData?.freak?.linksYoutube || []);
    setLinksFacebook(updatedFormData?.freak?.linksFacebook || []);
    setLinksInstagram(updatedFormData?.freak?.linksInstagram || []);
    // Optionally, you can set other state variables as well
  } catch (error) {
    console.error(error);
  }

  uploadedFiles = [];
};

export const FreakDetails = (props) => {
  const translation = useTranslation();
  const {id: freakId} = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [oldTitle, setOldTitle] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [linksYoutube, setLinksYoutube] = useState([]);
  const [linksFacebook, setLinksFacebook] = useState([]);
  const [linksInstagram, setLinksInstagram] = useState([]);
  const [body, setBody] = useState('');
  const [tagList, setTagList] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [localization, setLocalization] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);

  const playerRef = React.useRef(null);
  const device = props.device;

  const [isLoaded, setIsLoaded] = useState(false);

  const [isValidYouTubeLink, setIsValidYouTubeLink] = useState(true);
  const [youTubeLink, setYouTubeLink] = useState('');

  const [isValidFacebookLink, setIsValidFacebookLink] = useState(true);
  const [facebookLink, setFacebookLink] = useState('');

  const [isValidInstagramLink, setIsValidInstagramLink] = useState(true);
  const [instagramLink, setInstagramLink] = useState('');

  const [showCreateFreaks, setShowCreateFreaks] = useState(false);

  const [position, setPosition] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    if (!props.token) {
      navigate('/login');
    }
  }, [
    props.context
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const formData = await agent.Freaks.get(freakId, device);
      if (formData) {
        setTitle(formData?.freak?.title || '');
        setOldTitle(formData?.freak?.title || '');
        setImages(formData?.freak?.images || []);
        setVideos(formData?.freak?.videos || []);
        setLinksYoutube(formData?.freak?.linksYoutube || []);
        setLinksFacebook(formData?.freak?.linksFacebook || []);
        setLinksInstagram(formData?.freak?.linksInstagram || []);
        setBody(formData?.freak?.body || '');
        setTagList(formData?.freak?.tagList || []);
        setParticipants(formData?.freak?.participants || []);
        setLocalization(formData?.freak?.map || {});
        setStartDate(formData?.freak?.startDate || null);
        setIsPrivate(!formData?.freak?.public);

        setIsLoaded(true);
      }

      try {
        await getLatLon((position) => {
          setPosition([position.latitude, position.longitude]);
        }, () => {});
      } catch (error) {
        console.log('getLatLon', error);

        setPosition([50.258598, 19.020420]); // @todo default position
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    props.onLoad(Promise.all([
      agent.Freaks.get(freakId, device),
      agent.Comments.forFreak(freakId)
    ]));

    props.onUnload();
  }, []);

  if (!isLoaded || !props.freak || !props.freak.body) {
    return (
      <NotFoundComponent text={"Loading..."}/>
    );
  }

  const markup = {
    __html: !isFeed(props.freak)
      ? marked(props.freak.body, {sanitize: true})
      : marked(props.freak.body.replace(/\"/g, '').replace(/@/g, '\n- '), {sanitize: true})
  };
  const canModify = props.currentUser &&
    props.currentUser.username === props.freak.author.username;
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);

  return (
    <div
      className="article-page"
      style={{
        paddingLeft,
      }}
    >
      <div className="banner">
        <IconButton sx={{color: 'white'}} aria-label="back" onClick={() => {
          navigate('/freak');
        }}>
          <ArrowBackIosNewOutlinedIcon sx={{
            marginRight: 2,
            marginTop: 1,
            color: DEFAULT_THEME_COLOR
          }}
          />
          <Typography variant="h4" sx={{color: 'white'}}>
            {title}
          </Typography>
        </IconButton>
        <div className="container">
          <FreakMeta
            freak={props.freak}
            canModify={canModify}
          />
        </div>
      </div>

      <div className="container page">
        <fieldset className="form-group">

          {
            images?.length
              ? <ImageViewComponent images={images} showThumbnails={true}/>
              : null
          }

          <fieldset className="form-group">
            <UploadFiles
              onFileUpload={(file) => {
                uploadedFiles = [...uploadedFiles, {
                  id: file.data.id,
                  name: file.data.filename,
                  imageUrl: file.data.fileUrl,
                }];
                onFileUpload(file, props);
                onSubmit(props, setImages);
              }}
              dest={"freak"}
            />
          </fieldset>
        </fieldset>

        {
          window.cordova && false
            ? <fieldset className="form-group">
              {
                showCreateFreaks
                  ? <ShortsCreatorComponent afterSaveFn={setShowCreateFreaks} />
                  : <Button
                    sx={{
                      backgroundColor: DEFAULT_THEME_COLOR,
                      color: 'white',
                      borderColor: DEFAULT_THEME_COLOR,
                      'z-index': '0',
                      marginLeft: 1,
                      '&:hover': {
                        color: DEFAULT_THEME_COLOR,
                        borderColor: DEFAULT_THEME_COLOR,
                      }
                    }}
                    variant="outlined"
                    startIcon={<SlowMotionVideoIcon />}
                    onClick={() => {
                      setShowCreateFreaks(true);
                    }}
                  >
                    {translation.freakEditor.createFreaks}
                  </Button>
              }
            </fieldset>
            : null
        }

        <fieldset className="form-group">
          <Accordion
            defaultExpanded
            sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Grid container>
                <Grid item xs={12}>
                  <h3>{translation.freakDetails.description}</h3>
                </Grid>
              </Grid>

            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div dangerouslySetInnerHTML={markup}></div>

                  <ul className="tag-list">
                    {
                      props.freak.tagList.map(tag => {
                        return (
                          <li
                            className="tag-default tag-pill tag-outline"
                            key={tag}>
                            {tag}
                          </li>
                        );
                      })
                    }
                  </ul>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        {
          !isFeed(props.freak)
            ? <fieldset className="form-group">
              <Accordion
                defaultExpanded
                sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
              >
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <h3>{translation.freakDetails.information}</h3>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12} sx={{
                          fontSize: 13,
                          // color: 'rgb(82, 88, 102)'
                        }}>
                          {translation.freakDetails.plannedTo}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{
                              // color: 'black'
                            }}
                          >
                            {formatDate(props.freak.startDate)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12} sx={{
                          fontSize: 13,
                          // color: 'rgb(82, 88, 102)'
                        }}>
                          {translation.freakDetails.startedAt}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{
                              // color: 'black'
                            }}
                          >
                            {props.freak.startedAt ? formatDate(props.freak.startedAt) : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12} sx={{
                          fontSize: 13,
                          // color: 'rgb(82, 88, 102)'
                        }}>
                          {translation.freakDetails.finishedAt}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{
                              // color: 'black'
                            }}
                          >
                            {props.freak.finishedAt ? formatDate(props.freak.finishedAt) : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12} sx={{
                          fontSize: 13,
                          // color: 'rgb(82, 88, 102)'
                        }}>
                          {translation.freakDetails.duration}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{
                              // color: 'black'
                            }}
                          >
                            {props.freak.startedAt && props.freak.finishedAt ? calculateDuration(props.freak.startedAt, props.freak.finishedAt) : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12} sx={{
                          fontSize: 13,
                          // color: 'rgb(82, 88, 102)'
                        }}>
                          {translation.freakDetails.likes}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{
                              // color: 'black'
                            }}
                            onClick={() => navigate('/users-list', {
                              state: {
                                usersList: props.freak.favoritedBy
                              }
                            })}
                          >
                            {props.freak.favoritesCount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </fieldset>
            : null
        }

        <fieldset className="form-group">
          {
            window.cordova
            && props?.freak?.status
            && (props?.freak?.type === FREAK_TYPE_PRIVATE || props?.freak?.type === FREAK_TYPE_PUBLIC)
              ? <Button
                sx={{
                  backgroundColor: DEFAULT_THEME_COLOR,
                  color: 'white',
                  borderColor: DEFAULT_THEME_COLOR,
                  'z-index': '0',
                  marginLeft: 1,
                  marginTop: 2,
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  }
                }}
                variant="outlined"
                startIcon={
                  <RouteIcon />
                }
                onClick={() => {
                  navigate('/record', {
                    state: {
                      freak: props.freak,
                      position
                    }
                  });
                }}
              >
                {translation.freakEditor.createTrack}
              </Button>
              : null
          }
        </fieldset>
        <fieldset className="form-group">
          {
            props?.freak?.route && props?.freak?.route?.length
              ? <Accordion
                  defaultExpanded
                  sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
              >
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <h3>{translation.freakDetails.route}</h3>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{
                  padding: 0,
                }}>
                  <FreakLocalizationTracker
                    routeCoordinates={props.freak?.route}
                    position={position}
                    isRunning={false}
                    zoom={8}
                  />
                </AccordionDetails>
              </Accordion>
              : null
          }
        </fieldset>

        {
          props?.freak?.freakActivity
            ? <Accordion
              defaultExpanded
              sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <h3>{translation.freakDetails.activity}</h3>
                  </Grid>
                </Grid>
              </AccordionSummary>
            <AccordionDetails sx={{
              padding: 0,
            }}>
              <FreakActivityComponent activity={props.freak?.freakActivity} />
            </AccordionDetails>
            </Accordion>
            : null
        }

        {
          !isFeed(props.freak)
            ? <fieldset className="form-group">
              <Accordion
                defaultExpanded
                sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
              >
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <h3>{translation.freakDetails.localization}</h3>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{
                  padding: 0,
                }}>
                  <Grid container spaciing={2}>
                    <Grid item xs={12}>
                      <FreakLocalization
                        // route={props.freak.route}
                        localization={props.freak.map}
                        viewOnly={true}
                        myLocation={position}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{
                      margin: 3,
                      fontSize: 13,
                      // color: 'rgb(82, 88, 102)'
                    }}>
                      {props.freak?.map?.description}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{
                    fontSize: 13,
                    // color: 'rgb(82, 88, 102)',
                  }}>
                    <Grid item xs={12} sx={{
                      marginLeft: 3,
                    }}>
                      {translation.freakDetails.checkOnGoogleMaps}:
                      <IconButton
                        component={Link}
                        target="_blank"
                        rel="noopener"
                        to={getGoogleMapsLink(props.freak?.map?.coordinates?.latitude, props.freak?.map?.coordinates?.longitude)}
                        aria-label="Navigate"
                        sx={{
                          '&:focus': {
                            outline: 'none'
                          },
                        }}
                      >
                        <NavigationIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </fieldset>
            : null
        }
        {
          (props?.freak?.type === FREAK_TYPE_PRIVATE || props?.freak?.type === FREAK_TYPE_PUBLIC)
          ? <Button
            sx={{
              color: 'white',
              backgroundColor: DEFAULT_THEME_COLOR,
              'z-index': '0',
              marginLeft: 1,
              marginBottom: 2,
              borderColor: DEFAULT_THEME_COLOR,
              '&:hover': {
                color: 'black',
                borderColor: 'black',
              }
            }}
            variant="outlined"
            startIcon={<TravelExploreIcon />}
            onClick={() => navigate('/places', {
              state: {
                freakPlaceMapSlug: props.freak?.map?.slug,
                freakPlaceMap: props.freak?.map,
              }
            })
            }
          >{translation.freakEditor.showInPlaces}
          </Button>
            : null
        }
        {
          !isFeed(props.freak)
            ? <fieldset className="form-group">
              <Accordion
                defaultExpanded
                sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
              >
                <AccordionSummary
                  // expandIcon={<ArrowDropDownIcon/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <h3>{translation.freakDetails.participants}</h3>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {
                    props?.freak?.participants?.length
                      ? <FreakParticipantsList
                        freakId={props.freak.id}
                        author={props.freak.author}
                        participants={props.freak.participants}
                        isAuthor={props?.freak?.author?.username === props?.currentUser?.username}
                      />
                      : <div>{translation.freakDetails.noParticipants}</div>
                  }
                </AccordionDetails>
              </Accordion>
            </fieldset>
            : null
        }

        {
          isFreakParticipant(props.currentUser.username, props?.freak)
            ?
            <>
              {
                linksYoutube?.length
                  ? <fieldset className="form-group">
                    <Accordion sx={{
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
                    }}
                    >
                      <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          padding: 0,
                        }}
                      >
                        <IconButton
                          sx={{
                            '&:focus': {
                              outline: 'none'
                            },
                          }}
                        >
                          <YouTubeIcon sx={{color: '#FF0000', width: 45, height: 45}}/>
                        </IconButton>
                        <Grid container>
                          <Grid item xs={12}>
                            <h3 style={{ marginTop: 15}}>{`YouTube (${linksYoutube?.length})`}</h3>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{padding: 0}}>
                        {
                          linksYoutube?.length
                            ? <fieldset className="form-group">
                              <Grid container>
                                <Grid item xs={12}>
                                  {
                                    linksYoutube?.length
                                      ? <CarouselComponent youtubeEmbeds={linksYoutube.map(link => link.url)}/>
                                      : null
                                  }
                                </Grid>
                              </Grid>
                            </fieldset>
                            : null
                        }
                        <Grid container sx={{
                          marginBottom: 2,
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                          <Grid item xs={2}>
                            <IconButton
                              component={Link}
                              to={"https://www.youtube.com"}
                              aria-label="Navigate"
                            >
                              <YouTubeIcon sx={{color: '#FF0000', width: 45, height: 45}}/>
                            </IconButton>
                          </Grid>
                          <Grid item xs={7}>
                            <TextField
                              size="small"
                              label={translation.linksYoutube.placeholders.add}
                              variant="outlined"
                              fullWidth
                              value={youTubeLink}
                              onChange={(e) => validateYouTubeLink(e.target.value, setIsValidYouTubeLink, setYouTubeLink)}
                              error={!isValidYouTubeLink}
                              helperText={!isValidYouTubeLink && translation.linksYoutube.placeholders.invalidLink}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              variant="contained"
                              style={{
                                height: 25,
                                outline: 'none',
                              }}
                              onClick={() => {
                                setYouTubeLink('');
                                setIsValidYouTubeLink(true);
                              }}
                            >
                              <DeleteIcon sx={{color: DEFAULT_THEME_COLOR}}/>
                            </IconButton>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              variant="contained"
                              disabled={youTubeLink && !isValidYouTubeLink}
                              style={{
                                height: 25,
                                outline: 'none',
                              }}
                              onClick={() => {
                                const updatedLinksYoutube = handleAddYouTubeLink(youTubeLink, linksYoutube, setLinksYoutube, setYouTubeLink);
                                onSubmit(
                                  props,
                                  setImages,
                                  updatedLinksYoutube,
                                  setLinksYoutube,
                                  null,
                                  setLinksFacebook,
                                  null,
                                  setLinksInstagram,
                                );
                              }}
                            >
                              <AddIcon sx={{color: DEFAULT_THEME_COLOR}}/>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </fieldset>
                  : null
              }

              {
                linksFacebook?.length
                  ? <fieldset className="form-group">
                    <Accordion sx={{
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
                    }}
                    >
                      <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          padding: 0,
                        }}
                      >
                        <IconButton
                          sx={{
                            '&:focus': {
                              outline: 'none'
                            },
                          }}
                        >
                          <FacebookIcon sx={{color: '#1877F2', width: 45, height: 45}}/>
                        </IconButton>
                        <Grid container>
                          <Grid item xs={12}>
                            <h3 style={{ marginTop: 15}}>{`Facebook (${linksFacebook?.length})`}</h3>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{padding: 0}}>
                        {
                          linksFacebook?.length
                            ? <fieldset className="form-group">
                              <Grid container>
                                <Grid item xs={12}>
                                  {
                                    linksFacebook?.length
                                      ? <CarouselComponent facebookEmbeds={linksFacebook.map(link => link.url)}/>
                                      : null
                                  }
                                </Grid>
                              </Grid>
                            </fieldset>
                            : null
                        }
                        <Grid container sx={{
                          marginBottom: 2,
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                          <Grid item xs={2}>
                            <IconButton
                              component={Link}
                              to={"https://www.facebook.com"}
                              aria-label="Navigate"
                            >
                              <FacebookIcon sx={{color: '#1877F2', width: 45, height: 45}}/>
                            </IconButton>
                          </Grid>
                          <Grid item xs={7}>
                            <TextField
                              size="small"
                              label={translation.linksFacebook.placeholders.add}
                              variant="outlined"
                              fullWidth
                              value={facebookLink}
                              onChange={(e) => validateFacebookLink(e.target.value, setIsValidFacebookLink, setFacebookLink)}
                              error={!isValidFacebookLink}
                              helperText={!isValidFacebookLink && translation.linksFacebook.placeholders.invalidLink}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              variant="contained"
                              style={{
                                height: 25,
                                outline: 'none',
                              }}
                              onClick={() => {
                                setFacebookLink('');
                                setIsValidFacebookLink(true);
                              }}
                            >
                              <DeleteIcon sx={{color: DEFAULT_THEME_COLOR}}/>
                            </IconButton>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              variant="contained"
                              disabled={facebookLink && !isValidFacebookLink}
                              style={{
                                height: 25,
                                outline: 'none',
                                marginLeft: 2,
                              }}
                              onClick={() => {
                                const updatedLinksFacebook = handleAddFacebookLink(facebookLink, linksFacebook, setLinksFacebook, setFacebookLink);
                                onSubmit(
                                  props,
                                  setImages,
                                  null,
                                  setLinksYoutube,
                                  updatedLinksFacebook,
                                  setLinksFacebook,
                                  null,
                                  setLinksInstagram,
                                );
                              }}
                            >
                              <AddIcon sx={{color: DEFAULT_THEME_COLOR}}/>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </fieldset>
                  : null
              }

              {
                linksInstagram?.length
                  ? <fieldset className="form-group">
                    <Accordion sx={{
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
                    }}
                    >
                      <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          padding: 0,
                        }}
                      >
                        <IconButton
                          sx={{
                            '&:focus': {
                              outline: 'none'
                            },
                          }}
                        >
                          <InstagramIcon sx={{color: 'rgb(30, 48, 80)', width: 40, height: 40}}/>
                        </IconButton>
                        <Grid container>
                          <Grid item xs={12}>
                            <h3 style={{ marginTop: 15}}>{`Instagram (${linksInstagram?.length})`}</h3>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{padding: 0}}>
                        {
                          linksInstagram?.length
                            ? <fieldset className="form-group">
                              <Grid container>
                                <Grid item xs={12}>
                                  {
                                    linksInstagram?.length
                                      ? <CarouselComponent instagramEmbeds={linksInstagram.map(link => link.url)}/>
                                      : null
                                  }
                                </Grid>
                              </Grid>
                            </fieldset>
                            : null
                        }
                        <Grid container sx={{
                          marginBottom: 2,
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                          <Grid item xs={2}>
                            <IconButton
                              component={Link}
                              to={"https://www.instagram.com"}
                              aria-label="Navigate"
                            >
                              <InstagramIcon sx={{color: 'rgb(30, 48, 80)', width: 40, height: 40}}/>
                            </IconButton>
                          </Grid>
                          <Grid item xs={7}>
                            <TextField
                              size="small"
                              label={translation.linksInstagram.placeholders.add}
                              variant="outlined"
                              fullWidth
                              value={instagramLink}
                              onChange={(e) => validateInstagramLink(e.target.value, setIsValidInstagramLink, setInstagramLink)}
                              error={!isValidInstagramLink}
                              helperText={!isValidInstagramLink && translation.linksInstagram.placeholders.invalidLink}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              variant="contained"
                              style={{
                                height: 25,
                                outline: 'none',
                              }}
                              onClick={() => {
                                setInstagramLink('');
                                setIsValidInstagramLink(true);
                              }}
                            >
                              <DeleteIcon sx={{color: DEFAULT_THEME_COLOR}}/>
                            </IconButton>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              variant="contained"
                              disabled={instagramLink && !isValidInstagramLink}
                              style={{
                                height: 25,
                                outline: 'none',
                              }}
                              onClick={() => {
                                const updatedLinksInstagram = handleAddInstagramLink(instagramLink, linksInstagram, setLinksInstagram, setInstagramLink);
                                onSubmit(
                                  props,
                                  setImages,
                                  null,
                                  setLinksYoutube,
                                  null,
                                  setLinksFacebook,
                                  updatedLinksInstagram,
                                  setLinksInstagram,
                                );
                              }}
                            >
                              <AddIcon sx={{color: DEFAULT_THEME_COLOR}}/>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </fieldset>
                  : null
              }
            </>
            : null
        }

          <CommentContainer
            type={'freaks'}
            comments={props.comments || []}
            errors={props.commentErrors}
            slug={freakId}
            currentUser={props.currentUser}
          />
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  ...state.freak,
  token: state.common.token,
  device: state.common.device,
  currentUser: state.common.currentUser,
  recordingFreakRoute: state.common.recordingFreakRoute,
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload =>
    dispatch({type: FREAK_PAGE_LOADED, payload}),
  onUnload: () =>
    dispatch({type: FREAK_PAGE_UNLOADED}),
  onFileUpload: payload => {
    if (payload && payload.data) {
      const fileType = payload.type;
      const fileData = payload.data;
      const action = fileType === 'images' ? UPLOAD_FREAK_IMAGE : UPLOAD_FREAK_VIDEO;
      const prop = fileType === 'images' ? 'imagesToUpload' : 'videosToUpload';
      dispatch({
        type: action,
        payload: {
          [prop]: fileData.filename
        }
      });
    }
  },
  onSubmit: payload =>
    dispatch({type: FREAK_SUBMITTED, payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakDetails);
