import {
  NOTIFICATION_PAGE_LOADED,
  NOTIFICATION_PAGE_UNLOADED,
  NOTIFICATION_SET_PAGE,
  NOTIFICATION_OPERATION,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_PAGE_LOADED:
      return {
        ...state,
        notifications: action.payload[0].notifications,
      };
    case NOTIFICATION_PAGE_UNLOADED:
      return {};
    case NOTIFICATION_SET_PAGE:
      return {
        ...state,
        notifications: action.payload?.notifications,
        currentPage: action.page
      };
    case NOTIFICATION_OPERATION:
      return {
        ...state,
        notifications: action.payload,
      }
    default:
      return state;
  }
};
