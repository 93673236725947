const PASSWORD_TOO_WEAK_NO_UPPERCASE = 'please use minimum one uppercase character';
const PASSWORD_TOO_WEAK_NO_LOWERCASE = 'please use minimum one lower character';
const PASSWORD_TOO_WEAK_NO_DIGIT = 'please use minimum one digit';
const PASSWORD_TOO_WEAK_NO_SPECIAL_CHARACTER = 'please use minimum one special character';
const PASSWORD_TOO_WEAK_TOO_SHORT = 'password too short, minimum length is 8';

const validatePassword = password => {
  const minimumPasswordLength = 8;
  const strengthChecks = {
    length: 0,
    hasUpperCase: false,
    hasLowerCase: false,
    hasDigit: false,
    hasSpecialChar: false,
  };
  const errors = [];

  strengthChecks.length = password.length >= minimumPasswordLength ? true : false;
  strengthChecks.hasUpperCase = /[A-Z]+/.test(password);
  strengthChecks.hasLowerCase = /[a-z]+/.test(password);
  strengthChecks.hasDigit = /[0-9]+/.test(password);
  strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(password);

  if (!strengthChecks.length) {
    errors.push(PASSWORD_TOO_WEAK_TOO_SHORT);
  }
  if (!strengthChecks.hasUpperCase) {
    errors.push(PASSWORD_TOO_WEAK_NO_UPPERCASE);
  }
  if (!strengthChecks.hasLowerCase) {
    errors.push(PASSWORD_TOO_WEAK_NO_LOWERCASE);
  }
  if (!strengthChecks.hasDigit) {
    errors.push(PASSWORD_TOO_WEAK_NO_DIGIT);
  }
  if (!strengthChecks.hasSpecialChar) {
    errors.push(PASSWORD_TOO_WEAK_NO_SPECIAL_CHARACTER);
  }
  return errors;
};

module.exports = {
  validatePassword
};
