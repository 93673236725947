import React, {useState, useEffect} from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import ConfirmationModal from "../ConfirmationModal";
import agent from '../../agent';
import useTranslation from "../../customHooks/translations";
import {useNavigate} from "react-router-dom";
import {DEFAULT_THEME_COLOR, DEFAULT_THEME_COLOR_GREY} from "../../constants/theme";

const getParticipantStatusMessage = (participant, translation) => {
  if (participant?.status) {
    switch (participant.status) {
      case 'pending':
        return translation.freaks.participate.pending;
      case 'approved':
        return translation.freaks.participate.approved;
      case 'declined':
        return translation.freaks.participate.declined;
      case 'request_join':
        return translation.freaks.participate.requestJoin;
      default:
        return '';
    }
  }
};

const FreakParticipantsList = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [participants, setParticipants] = useState(props.participants || []);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setParticipants(props.participants || [])
  }, [props.participants]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDelete = (freakId, participantId) => {
    agent.Freaks.deleteParticipant(freakId, participantId);

    setParticipants((prevParticipants) =>
      prevParticipants.filter((participant) => participant.id !== participantId)
    );
  };

  return (
    <>
      <List sx={{
        width: '100%',
        bgcolor: 'background.paper'
      }}>
        {
          props.author
            ? <ListItem>
                <ListItemAvatar onClick={() => navigate(`/@${props.author.username}`)}>
                  <Avatar alt={props.author.username} src={props.author.imageUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={props.author.username} secondary={props.author.bio}
                  onClick={() => navigate(`/@${props.author.username}`)}
                />
                <Button
                  disabled
                >
                  Admin
                </Button>
              </ListItem>
            : null
        }
        { participants && participants.length
          ? participants.map((participant, idx) => (
            <ListItem
              key={idx}
            >
              <ListItemAvatar onClick={() => navigate(`/@${participant.username}`)}>
                <Avatar alt={participant.username} src={participant.imageUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={participant.username} secondary={participant.bio}
                onClick={() => navigate(`/@${participant.username}`)}
              />
              {
                participant.status
                  ? <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <ButtonGroup
                      sx={{
                        display: 'flex',
                        gap: 1,
                      }}
                      orientation="vertical"
                      aria-label="vertical outlined button group"
                    >
                      <Button
                        disabled
                        style={{
                          borderColor: DEFAULT_THEME_COLOR_GREY,
                          'z-index': '0',
                          borderRadius: 4,
                          width: 150,
                          outline: 'none',
                          marginLeft: 10,
                        }}
                      >
                        {getParticipantStatusMessage(participant, translation)}
                      </Button>
                      {
                        props?.isAuthor ?
                          <Button
                            style={{
                              color: DEFAULT_THEME_COLOR,
                              borderColor: DEFAULT_THEME_COLOR,
                              'z-index': '0',
                              borderRadius: 4,
                              width: 150,
                              outline: 'none',
                              marginLeft: 10,
                            }}
                            onClick={() => setModalOpen(true)}
                          >
                            {translation.freakParticipants.remove}
                          </Button>
                        : null
                      }
                    </ButtonGroup>
                  </Box>
                  : null
              }
              <ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => handleCloseModal()}
                onConfirm={() => {
                  handleDelete(props.freakId, participant.id);
                  setModalOpen(false);
                }}
              />
            </ListItem>
          ))
          : null
        }
      </List>
    </>
  );
};

export default FreakParticipantsList;
