import React, {useState, useEffect, useRef} from 'react';
import ListErrors from './ListErrors';
import agent from '../agent';
import {connect} from 'react-redux';
import {
  SETTINGS_SAVED,
  SETTINGS_PAGE_UNLOADED,
  LOGOUT, UPLOAD_USER_IMAGE,
  UPLOAD_USER_BACKGROUND_IMAGE,
  THEME_MODE_CHANGE,
} from '../constants/actionTypes';
import FileDropzoneArea from './FileDropzoneArea';
import {convertToBase64} from '../utils/imageUtils';
import {Link, useLocation, useNavigate} from "react-router-dom";
import TopBar from "./TopBar";
import {Card} from "@mui/material";
import useTranslation from "../customHooks/translations";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Grid from "@mui/material/Grid";
import { FormControl } from '@mui/base/FormControl';
import {Radio, RadioGroup} from "@mui/joy";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useLanguageContext } from "../contexts/LanguageContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import IconButton from "@mui/material/IconButton";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PortraitIcon from '@mui/icons-material/Portrait';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import TranslateIcon from '@mui/icons-material/Translate';
import { ReactComponent as FreaklandIcon } from "../assets/freakland.svg";
import SocialListComponent from "./List/SocialListComponent";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import {DEFAULT_THEME_COLOR, DEFAULT_THEME_COLOR_GREY} from "../constants/theme";
import AddIcon from "@mui/icons-material/Add";
import {validateYouTubeFeed} from "../utils/freakUtils";
import packageJson from '../../package.json';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from "@mui/material/Button";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {setupNotifications} from "../firebase";
import Box from "@mui/material/Box";
import ContrastIcon from '@mui/icons-material/Contrast';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import WbSunnyIcon from '@mui/icons-material/WbSunny';

import { extendTheme, CssVarsProvider } from '@mui/joy/styles';

const customTheme = extendTheme({
  radius: {
    s: '10px',
    m: '20px',
  },
});

const SettingsForm = (props) => {
  const translation = useTranslation();
  const { handleChangeLanguage } = useLanguageContext();
  const location = useLocation();
  const feedYoutube = location?.state?.youtube || null;

  const [image, setImage] = useState('');
  const [currentImage, setCurrentImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [currentBackgroundImage, setCurrentBackgroundImage] = useState('');
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState('');
  const [freaksCount, setFreaksCount] = useState('');
  const [points, setPoints] = useState('');
  const [followedByCount, setFollowedByCount] = useState('');
  const [followingCount, setFollowingCount] = useState('');
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState('');
  const [context, setContext] = useState('');
  const [youtubeFeeds, setYoutubeFeeds] = useState([]);
  const [themeMode, setThemeMode] = useState('dark');

  const [isValidYouTubeFeed, setIsValidYouTubeFeed] = useState(true);
  const [youTubeFeed, setYouTubeFeed] = useState('');
  const [isAddingFeed, setIsAddingFeed] = useState(false);

  useEffect(() => {
    setCurrentImage(props.currentUser?.image ?? '');
    setCurrentBackgroundImage(props.currentUser?.backgroundImage ?? '');

    fetchYoutubeFeeds();
  }, []);

  useEffect(() => {
    setUsername(props.currentUser?.username ?? '');
    setBio(props.currentUser?.bio ?? '');
    setFreaksCount(props.currentUser?.freaksCount ?? 0);
    setPoints(props.currentUser?.points ?? 0);
    setFollowedByCount(props.currentUser?.followedByCount ?? 0);
    setFollowingCount(props.currentUser?.followingCount ?? 0);
    setEmail(props.currentUser?.email ?? '');
    setImage(props.currentUser?.image ?? '');
    setImageUrl(props.currentUser?.imageUrl ?? '');
    setBackgroundImage(props.currentUser?.backgroundImage ?? '')
    setBackgroundImageUrl(props.currentUser?.backgroundImageUrl ?? '')
    setLanguage(props.currentUser?.language ?? '');
    setContext(props.currentUser?.context ?? '');
    setThemeMode(props.currentUser?.themeMode ?? 'dark');
  }, [
    props.currentUser?.image,
    props.currentUser?.backgroundImage,
    isAddingFeed,
  ]);

  const fetchYoutubeFeeds = async () => {
    const results = await agent.Feeds.list('youtube');
    setYoutubeFeeds(results);
  };

  const addFeedYouTube = async (feed, setIsValidFn) => {
    setIsAddingFeed(true);

    try {
      await agent.Feeds.createUserFeedForYouTube(feed);
      setIsValidFn(true);
      setYouTubeFeed('');
      await fetchYoutubeFeeds();
      setIsAddingFeed(false);
    } catch (error) {
      setIsValidFn(false);
      setIsAddingFeed(false);
    }
  };

  const updateState = field => ev => {
    const stateMethod = {
      image: setImage,
      backgroundImage: setBackgroundImage,
      username: setUsername,
      bio: setBio,
      email: setEmail
    }[field];

    stateMethod(ev.target.value);
  };

  const submitForm = ev => {
    ev.preventDefault();

    const user = {
      username,
      bio,
      email,
      language,
      context,
      themeMode,
    };

    if (image && image !== currentImage) {
      user.image = image;
    }

    if (backgroundImage && backgroundImage !== currentBackgroundImage) {
      user.backgroundImage = backgroundImage;
    }

    if (!user.password) {
      delete user.password;
    }

    props.onSubmitForm(user);
  };

  const submitTheme = newThemeMode => {
    props.onThemeModeChange(newThemeMode);
  };

  const youtubeFeedSectionRef = useRef(null);
  const scrollToSection = sectionRef => {
    sectionRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  if (feedYoutube) {
    scrollToSection(youtubeFeedSectionRef);
  }

  return (
    <form onSubmit={submitForm}>
      <fieldset>
        <fieldset className="form-group">
          <Box
            sx={{
              background: '#f3f3f3',
              backgroundImage: backgroundImageUrl ? `url("${backgroundImageUrl}")` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 5,
              height: '100px',
            }}
          >
            <Avatar
              sx={{
                width: '100px',
                height: '100px',
                borderRadius: 5,
              }}
              alt={username}
              src={imageUrl}
            />
          </Box>
          <Box sx={{
            marginTop: 1,
            marginLeft: 1,
            marginBottom: 1,
          }}>
            <h3>{username}</h3>
            <p style={{color: 'grey'}}>{`@${username}`}</p>

            <Grid
              container
              spacing={2}
              alignItems="left"
              sx={{
                marginTop: 1,
                marginBottom: 3,
              }}
            >
              <Grid item xs={0.5}>
                <Avatar
                  sx={{
                    width: 14,
                    height: 14,
                    marginTop: 0.5,
                  }}
                  src={'./../icon-48x48.png'}
                />
              </Grid>
              <Grid item xs={10}>
                {translation.profile.verified}
              </Grid>

              <Grid
                container
                spacing={0}
                alignItems="left"
                sx={{
                  marginTop: 4,
                  marginBottom: 3,
                  marginLeft: 2,
                }}
              >
                <Grid container xs={6}>
                  <Grid item xs={1} sx={{
                    fontWeight: 'bold',
                    marginRight: 2,
                  }}>
                    {freaksCount}
                  </Grid>
                  <Grid item xs={6} sx={{
                    marginTop: 0.35,
                    fontSize: 13,
                    // color: 'rgb(82, 88, 102)'
                  }}>
                    {translation.profile.freaks}
                  </Grid>
                </Grid>
                <Grid container xs={6}>
                  <Grid item xs={1} sx={{
                    fontWeight: 'bold',
                    marginRight: 2,
                  }}>
                    {points}
                  </Grid>
                  <Grid item xs={6} sx={{
                    marginTop: 0.35,
                    fontSize: 13,
                    // color: 'rgb(82, 88, 102)'
                  }}>
                    {translation.profile.points}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                alignItems="left"
                sx={{
                  marginTop: 1,
                  marginBottom: 3,
                  marginLeft: 2,
                }}
              >
                <Grid
                  container
                  xs={6}
                  onClick={() => navigate(`/followed-by/@${username}`)}
                >
                  <Grid item xs={1} sx={{
                    fontWeight: 'bold',
                    marginRight: 2,
                  }}>
                    {followedByCount}
                  </Grid>
                  <Grid item xs={6} sx={{
                    marginTop: 0.35,
                    fontSize: 13,
                    // color: 'rgb(82, 88, 102)'
                  }}>
                    {translation.profile.followers}
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={6}
                  onClick={() => navigate(`/following/@${username}`)}
                >
                  <Grid item xs={1} sx={{
                    fontWeight: 'bold',
                    marginRight: 2,
                  }}>
                    {followingCount}
                  </Grid>
                  <Grid item xs={6} sx={{
                    marginTop: 0.35,
                    fontSize: 13,
                    // color: 'rgb(82, 88, 102)'
                  }}>
                    {translation.profile.following}
                  </Grid>
                </Grid>
              </Grid>

              <p style={{color: 'grey', marginLeft: 15}}>{bio}</p>
            </Grid>
          </Box>
        </fieldset>

        <fieldset className="form-group">
          <Accordion
            sx={{
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                }}
              >
                <PortraitIcon sx={{
                    // color: 'rgba(0, 0, 0, 0.87)',
                    width: 25,
                    height: 25,
                  }}
                />
              </IconButton>
              <Typography variant="h6" sx={{
                marginTop: 0.5,
              }}>
                {translation.settings.userImageLabel}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{padding: 0}}>
              <Card sx={{
                boxShadow: 'none',
                backgroundColor: 'rgb(243, 243, 243)',
              }}>
                <FileDropzoneArea
                  onFileUpload={props.onFileUpload}
                />
              </Card>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        <fieldset className="form-group">
          <Accordion sx={{
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                }}
              >
                <WallpaperIcon sx={{
                  // color: 'rgba(0, 0, 0, 0.87)',
                  width: 25,
                  height: 25,
                }}
                />
              </IconButton>
              <Typography variant="h6" component="h2" sx={{marginTop: 0.5}}>
                {translation.settings.backgroundImageLabel}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{padding: 0}}>
              <Card sx={{
                boxShadow: 'none',
                backgroundColor: 'rgb(243, 243, 243)',
              }}>
                <FileDropzoneArea
                  onFileUpload={props.onBackgroundFileUpload}
                />
              </Card>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        <fieldset className="form-group">
          <Accordion sx={{
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                }}
              >
                <FingerprintIcon sx={{
                  // color: 'rgba(0, 0, 0, 0.87)',
                  width: 25,
                  height: 25,
                }}
                />
              </IconButton>
              <Typography variant="h6" component="h2" sx={{marginTop: 0.5}}>
                {translation.settings.bioLabel}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{padding: 0}}>
              <Card sx={{
                boxShadow: 'lg',
              }}>
                <TextareaAutosize
                  sx={{
                    // backgroundColor: 'white',
                    minHeight: 200
                  }}
                  className="form-control form-control-lg"
                  rows="8"
                  placeholder={translation.settings.bio}
                  value={bio}
                  onChange={updateState('bio')}>
                </TextareaAutosize>
              </Card>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        <fieldset className="form-group">
          <Accordion sx={{
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                }}
              >
                <TranslateIcon sx={{
                  // color: 'rgba(0, 0, 0, 0.87)',
                  width: 25,
                  height: 25,
                }}
                />
              </IconButton>
              <Typography variant="h6" sx={{ marginTop: 0.5 }}>
                {translation.settings.languageLabel}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{padding: 0}}>
              <FormControl>
                <CssVarsProvider theme={customTheme}>
                  <RadioGroup
                    sx={{
                      marginLeft: 5,
                      borderRadius: 10,
                      padding: 1,
                      color: DEFAULT_THEME_COLOR,
                    }}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={language}
                    onChange={(event) => {
                      const selectedLanguage = event.target.value;
                      setLanguage(selectedLanguage);
                      handleChangeLanguage(selectedLanguage);
                    }}
                  >
                    <FormControlLabel
                      value="pl"
                      control={<Radio sx={{ marginRight: 1, }}/>}
                      label={translation.settings.languagePolish}
                    />
                    <FormControlLabel
                      value="en"
                      control={<Radio sx={{ marginRight: 1, }} />}
                      label={translation.settings.languageEnglish}
                    />
                  </RadioGroup>
                </CssVarsProvider>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        <fieldset className="form-group">
          <Accordion sx={{
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <IconButton
              sx={{
                '&:focus': {
                  outline: 'none'
                },
              }}
            >
              <FreaklandIcon style={{ width: 25, height: 25 }}/>
            </IconButton>
            <Typography variant="h6" sx={{
              marginTop: 0.5
            }}>
              {translation.settings.contextLabel}
            </Typography>

          </AccordionSummary>
          <AccordionDetails sx={{padding: 0}}>
            <FormControl>
              <CssVarsProvider theme={customTheme}>
                <RadioGroup
                  sx={{
                    marginLeft: 5,
                    borderRadius: 10,
                    padding: 1,
                    color: DEFAULT_THEME_COLOR,
                  }}
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={context}
                  onChange={(event) => {
                    const selectedContext = event.target.value;
                    setContext(selectedContext);
                  }}
                >
                  <FormControlLabel
                    value="wof"
                    control={<Radio sx={{ marginRight: 1, }}/>}
                    label={translation.settings.contextWof}
                  />
                  <FormControlLabel
                    value="bike"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextBike}
                  />
                  <FormControlLabel
                    value="fishing"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextFishing}
                  />
                  <FormControlLabel
                    value="trip"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextTrip}
                  />
                  <FormControlLabel
                    value="fit"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextFit}
                  />
                  <FormControlLabel
                    value="dogs"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextDogs}
                  />
                  <FormControlLabel
                    value="games"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextGames}
                  />
                  <FormControlLabel
                    value="books"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextBooks}
                  />
                  <FormControlLabel
                    value="food"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextFood}
                  />
                  <FormControlLabel
                    value="shopping"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextShopping}
                  />
                  <FormControlLabel
                    value="tech"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextTech}
                  />
                  <FormControlLabel
                    value="motorization"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextMotorization}
                  />
                  <FormControlLabel
                    value="gardening"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextGardening}
                  />
                  <FormControlLabel
                    value="music"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextMusic}
                  />
                  <FormControlLabel
                    value="sport"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextSport}
                  />
                  <FormControlLabel
                    value="winterSports"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextWinterSports}
                  />
                  <FormControlLabel
                    value="horseRiding"
                    control={<Radio sx={{ marginRight: 1, }} />}
                    label={translation.settings.contextHorseRiding}
                  />
                </RadioGroup>
              </CssVarsProvider>

            </FormControl>
          </AccordionDetails>
          </Accordion>
        </fieldset>

        <fieldset className="form-group" ref={youtubeFeedSectionRef}>
          <Accordion
            sx={{
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                }}
              >
                <YouTubeIcon sx={{
                  color: '#FF0000',
                  width: 25,
                  height: 25,
                }}
                />
              </IconButton>
              <Grid container>
                <Typography variant="h6" sx={{
                  marginTop: 0.5,
                }}>
                  { `YouTube Feeds`}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{padding: 0}}>
              <Grid container sx={{
                marginBottom: 2,
                display: 'flex',
                alignItems: 'center'
              }}>
                <SocialListComponent social={'youtube'} isAddingFeed={isAddingFeed} forUsername={props.currentUser?.username}/>
              </Grid>
              <Grid container sx={{
                marginBottom: 2,
                display: 'flex',
                alignItems: 'center'
              }}>
                <Grid item xs={2}>
                  <IconButton
                    component={Link}
                    to={"https://www.youtube.com"}
                    aria-label="Navigate"
                  >
                    <YouTubeIcon sx={{color: '#FF0000', width: 40, height: 40}}/>
                  </IconButton>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    size="small"
                    label={translation.linksYoutube.placeholders.addFeed}
                    variant="outlined"
                    fullWidth
                    value={youTubeFeed}
                    placeholder={'@ExampleChannel'}
                    onChange={(e) => validateYouTubeFeed(e.target.value, setIsValidYouTubeFeed, setYouTubeFeed)}
                    error={!isValidYouTubeFeed}
                    helperText={!isValidYouTubeFeed && translation.linksYoutube.placeholders.invalidFeed}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    variant="contained"
                    style={{
                      height: 25,
                      outline: 'none'
                    }}
                    onClick={() => {
                      setYouTubeFeed('');
                      setIsValidYouTubeFeed(true);
                    }}
                  >
                    <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    variant="contained"
                    disabled={!youTubeFeed || (youTubeFeed && !isValidYouTubeFeed)}
                    style={{
                      height: 25,
                      outline: 'none'
                    }}
                    onClick={() => {
                      addFeedYouTube(youTubeFeed, setIsValidYouTubeFeed);
                    }}
                  >
                    <AddIcon sx={{
                      color: !youTubeFeed || (youTubeFeed && !isValidYouTubeFeed) ? DEFAULT_THEME_COLOR_GREY : DEFAULT_THEME_COLOR
                    }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        <fieldset className="form-group">
          <Accordion sx={{
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                }}
              >
                <SettingsIcon style={{ width: 25, height: 25 }}/>
              </IconButton>
              <Typography variant="h6" sx={{
                marginTop: 0.5
              }}>
                {translation.settings.notifications}
              </Typography>

            </AccordionSummary>
            <AccordionDetails sx={{padding: 0}}>
              <Button
                sx={{
                  backgroundColor: DEFAULT_THEME_COLOR,
                  color: 'white',
                  borderColor: DEFAULT_THEME_COLOR,
                  'z-index': '0',
                  marginLeft: 1,
                  marginTop: 2,
                  marginBottom: 2,
                  outline: 'none',
                  '&:focus': {
                    outline: 'none'
                  },
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  }
                }}
                variant="outlined"
                startIcon={<NotificationsActiveIcon />}
                onClick={() => {
                  setupNotifications();
                }}
              >
                {translation.settings.notifications}
              </Button>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        <fieldset className="form-group">
          <Accordion sx={{
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                }}
              >
                <ContrastIcon style={{ width: 25, height: 25 }}/>
              </IconButton>
              <Typography variant="h6" sx={{
                marginTop: 0.5
              }}>
                {translation.settings.theme}
              </Typography>

            </AccordionSummary>
            <AccordionDetails sx={{padding: 0}}>
              <Button
                sx={{
                  backgroundColor: DEFAULT_THEME_COLOR,
                  color: 'white',
                  borderColor: DEFAULT_THEME_COLOR,
                  'z-index': '0',
                  marginLeft: 1,
                  marginTop: 2,
                  marginBottom: 2,
                  outline: 'none',
                  '&:focus': {
                    outline: 'none'
                  },
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  }
                }}
                variant="outlined"
                startIcon={themeMode === 'dark' ? <WbSunnyIcon /> : <DarkModeIcon /> }
                onClick={() => {
                  const themeMode = props.handleTheme();
                  setThemeMode(themeMode);

                  submitTheme(themeMode);
                }}
              >
                {(!themeMode || themeMode === 'dark') ? translation.settings.switchThemeLight : translation.settings.switchThemeDark}
              </Button>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        <button
          className="btn btn-lg btn-primary pull-xs-right"
          type="submit"
        >
          {translation.settings.update}
        </button>

      </fieldset>
    </form>
  );
};

export const Settings = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <TopBar/>
      <div className="settings-page">
        <div className="container page">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-xs-12">

              <ListErrors errors={props.errors}></ListErrors>

              <SettingsForm
                currentUser={props.currentUser}
                image={props.image}
                onSubmitForm={props.onSubmitForm}
                onFileUpload={props.onFileUpload}
                onBackgroundFileUpload={props.onBackgroundFileUpload}
                handleTheme={props.handleTheme}
                onThemeModeChange={props.onThemeModeChange}
              />

              <hr/>
              <Grid container>
                <Grid item xs={5}>
                  <button
                    className="btn btn-outline-danger"
                    style={{
                      marginBottom: 50,
                    }}
                    onClick={() => {
                      props.onClickLogout();
                      navigate('/login');
                    }}>
                    {translation.settings.logout}
                  </button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <footer>{translation.settings.version}: {packageJson.version}</footer>
    </>
  );
};

const mapStateToProps = state => ({
  ...state.settings,
  currentUser: state.common.currentUser,
  image: state.settings.image,
  backgroundImage: state.settings.backgroundImage,
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => dispatch({type: LOGOUT}),
  onSubmitForm: user =>
    dispatch({type: SETTINGS_SAVED, payload: agent.Auth.save(user)}),
  onThemeModeChange: newThemeMode =>
    dispatch({ type: THEME_MODE_CHANGE, payload: { newThemeMode }}),
  onUnload: () => dispatch({type: SETTINGS_PAGE_UNLOADED}),
  onFileUpload: async payload => {
    if (payload && payload.length) {
      const image = await convertToBase64(payload[0]);
      dispatch({
        type: UPLOAD_USER_IMAGE,
        payload: {
          image
        }
      })
    }
  },
  onBackgroundFileUpload: async payload => {
    if (payload && payload.length) {
      const backgroundImage = await convertToBase64(payload[0]);
      dispatch({
        type: UPLOAD_USER_BACKGROUND_IMAGE,
        payload: {
          backgroundImage
        }
      })
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
