import React, { useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import agent from '../agent';
import { connect } from 'react-redux';
import {
  PROFILE_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED
} from '../constants/actionTypes';
import TopBar from "./TopBar";
import useTranslation from "../customHooks/translations";
import FreakList from "./Freak/FreakList";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

const EditProfileSettings = props => {
  const translation = useTranslation();
  if (props.isUser) {
    return (
      <Link
        to="/settings"
        className="btn btn-sm btn-outline-secondary action-btn">
        <i className="ion-gear-a"></i> {translation.profile.editMessage}
      </Link>
    );
  }
  return null;
};

const FollowUserButton = props => {
  const translation = useTranslation();

  if (props.isUser) {
    return null;
  }

  let classes = 'btn btn-sm action-btn';
  if (props.user.following) {
    classes += ' btn-secondary';
  } else {
    classes += ' btn-outline-secondary';
  }

  const handleClick = ev => {
    ev.preventDefault();
    if (props.user.following) {
      props.unfollow(props.user.username)
    } else if (props.follow){
      props.follow(props.user.username)
    }
  };

  return (
    <button
      className={classes}
      onClick={handleClick}>
      <i className="ion-plus-round"></i>
      &nbsp;
      {props.user.following ? translation.profileFavorites.unfollow : translation.profileFavorites.follow} {props.user.username}
    </button>
  );
};

const renderTabs = (props, username) => {
  const translation = useTranslation();
  const tabName = props.profile.username !== props.currentUser.username ? props.profile.username : translation.tabs.private;
  return (
    <ul className="nav nav-pills outline-active">
      <li className="nav-item">
        <Link
          className="nav-link"
          to={`/${username}`}>
          {tabName}
        </Link>
      </li>

      {/*<li className="nav-item">*/}
      {/*  <Link*/}
      {/*    className="nav-link active"*/}
      {/*    to={`/${username}/favorites`}>*/}
      {/*    {translation.freaks.favourited}*/}
      {/*  </Link>*/}
      {/*</li>*/}
    </ul>
  );
}

export const ProfileFavorites = (props) => {
  const { username } = useParams();
  const context = props.context || props?.currentUser?.context || 'wof';
  const device = props.device;

  // useEffect(() => {
  //   props.onLoad(page => agent.Freaks.favoritedBy(username, page, context, device), Promise.all([
  //     agent.Profile.get(username),
  //     agent.Freaks.favoritedBy(username, 0, context, device)
  //   ]));
  //   props.onUnload();
  // }, []);

  const profile = props.profile;
  if (!profile) {
    return null;
  }

  const isUser = props.currentUser &&
    username === props.currentUser.username;

  return (
    <>
      <TopBar />
      <div className="profile-page">
        <Box sx={{
          textAlign: 'center',
          background: '#f3f3f3',
          backgroundImage: props?.profile?.backgroundImageUrl ? `url("${props.profile.backgroundImageUrl}")` : '',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '2rem 0 1rem'
        }}>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-10 offset-md-1">

                <Avatar
                  sx={{
                    width: '100px',
                    height: '100px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  alt={profile.username}
                  src={profile?.imageUrl}
                />
                <h4>{profile.username}</h4>
                <p>{profile.bio}</p>

                <EditProfileSettings isUser={isUser} />
                <FollowUserButton
                  isUser={isUser}
                  user={profile}
                  follow={props.onFollow}
                  unfollow={props.onUnfollow}
                />

              </div>
            </div>
          </div>
        </Box>

        <div className="container">
          <div className="row">

            <div className="col-xs-12 col-md-10 offset-md-1">

              <div className="articles-toggle">
                {renderTabs(props, username)}
              </div>

              <FreakList
                tab={'favourites'}
                profileUsername={profile.username}
                pager={props.pager}
                freaks={props.freaks}
                loading={props.loading}
                freaksCount={props.freaksCount}
                currentPage={props.currentPage}
              />
            </div>

          </div>
        </div>

      </div>
    </>
  )
};

const mapStateToProps = state => ({
  ...state.freakList,
  currentUser: state.common.currentUser,
  profile: state.profile,
  context: state.common.context,
  device: state.common.device,
});

const mapDispatchToProps = dispatch => ({
  onLoad: (pager, payload) =>
    dispatch({ type: PROFILE_PAGE_LOADED, pager, payload }),
  onUnload: () =>
    dispatch({ type: PROFILE_PAGE_UNLOADED })
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFavorites);
