import {
  FREAK_PLACES_SUBMITTED
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case FREAK_PLACES_SUBMITTED:
      return {
        ...state,
        inProgress: null,
        errors: action.error ? action.payload.errors : null
      };
    default:
      return state;
  };
};
