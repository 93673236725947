
export const getLanguageByBrowser = () => {
  const supportedLanguages = ['en', 'pl'];
  const defaultLanguage = 'en';

  const languageFromBrowser = navigator.language || navigator.userLanguage;
  const languageByBrowser = languageFromBrowser.split('-')?.[0];

  if (supportedLanguages.includes(languageByBrowser)) {
    return languageByBrowser;
  }

  return defaultLanguage;
};
