import React from 'react';

import "./styles.css";
import useTranslation from "../../customHooks/translations";

const SmallTopLoader = (props) => {
  const translation = useTranslation();

  return (
    <div className="small-top-loader-container">
      {translation.freakPlaces.loadingFreakPlaces}
      <div className="small-top-loader"></div>
    </div>
  );
};

export default SmallTopLoader;
