import {
  LOGIN,
  REGISTER,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  ASYNC_START,
  UPDATE_FIELD_AUTH,
  RESET_PASSWORD,
  SETUP_PASSWORD,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        inProgress: false,
        redirectTo: action.error ? null : action.redirectTo,
        errors: action.error ? action.payload.errors : null
      };
    case REGISTER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null
      };
    case RESET_PASSWORD:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null
      };
    case SETUP_PASSWORD:
      return {
        ...state,
        inProgress: false,
        password: action?.payload?.password,
        confirm_password: action?.payload?.confirm_password,
        errors: action.error ? action.payload.errors : null
      };
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return {};
    case ASYNC_START:
      if (action.subtype === LOGIN || action.subtype === REGISTER) {
        return { ...state, inProgress: true };
      }
      break;
    case UPDATE_FIELD_AUTH:
      return {
        ...state,
        [action.key]: action.value,
        errors: action.error ? action.payload.errors : null
      };
    default:
      return state;
  }

  return state;
};
