import React, { useEffect, useState } from 'react';
import TopBar from '../TopBar';
import agent from "../../agent";
import FreakListComponent from "../List/FreakListComponent";
import useTranslation from "../../customHooks/translations";
import InfiniteScroll from "react-infinite-scroller";
import CommonLoader from "../Loaders/CommonLoader";
import {useMediaQuery} from "react-responsive";
import {connect} from "react-redux";

const TopFreaksView = (props) => {
  const translation = useTranslation();

  const [topFreaks, setTopFreaks] = useState([]);
  const [myPositionInTopFreaks, setMyPositionInTopFreaks] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    setPage(0);
    setTopFreaks([]);
    setHasMore(true);
    setIsLoading(true);

    loadMoreTopFreaks(0);
  }, []);

  const loadMoreTopFreaks = async (__page) => {
    if (hasMore) {
      const results = await agent.Freaks.getTopFreaks(page);

      setTopFreaks([...topFreaks, ...results]);
      setPage(page + 1);
      setIsLoading(false);

      if (results.length === 0) {
        setHasMore(false);
      }
    }
  };
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);

  return (
    <div style={{
      paddingLeft,
    }}>
      <TopBar/>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreTopFreaks}
          hasMore={hasMore}
          loader={
            <CommonLoader />
          }
        >
          <FreakListComponent
            title={translation.topFreaks.title}
            secondaryTextKey={"points"}
            secondaryTextToConcat={translation.topFreaks.points}
            data={topFreaks}
          />
        </InfiniteScroll>

    </div>
  )
};

const mapStateToProps = state => ({
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TopFreaksView);
