import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import MotocrossAnimation from "./Animations/MotocrossAnimation";

export const NotFoundComponent = (props) => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      {/*<Typography variant="h4">{props.text}</Typography>*/}
      {/*<IconButton sx={{ color: 'black' }} aria-label="back" onClick={() => {*/}
      {/*  navigate('/');*/}
      {/*}}>*/}
      {/*  <RestartAltIcon  />*/}
      {/*</IconButton>*/}
      {/*<MotocrossAnimation />*/}
    </Box>
  );
};

export default NotFoundComponent;
