import React from 'react';
import { useNavigate} from 'react-router-dom';
import { connect } from 'react-redux';

import agent from '../../agent';
import { DELETE_ARTICLE } from '../../constants/actionTypes';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Button from "@mui/material/Button";
import useTranslation from "../../customHooks/translations";

const mapDispatchToProps = dispatch => ({
  onClickDelete: payload =>
    dispatch({ type: DELETE_ARTICLE, payload })
});

const ArticleActions = props => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const article = props.article;
  const del = () => {
    props.onClickDelete(agent.Articles.del(article.slug));
  };
  const edit = () => {
    navigate(`/editor/${article.slug}`);
  };

  if (props.canModify) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Stack spacing={2}>
          <Button
            size="md"
            variant="contained"
            onClick={() => edit()}
            style={{
              maxWidth: 200,
              backgroundColor: DEFAULT_THEME_COLOR,
              color: 'white'
            }}
          >
            {translation.buttons.edit}
          </Button>

          <Button
            size="md"
            variant="contained"
            onClick={() => del()}
            style={{
              maxWidth: 200,
              backgroundColor: DEFAULT_THEME_COLOR,
              color: 'white'
            }}
          >
            {translation.buttons.delete}
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <span>
    </span>
  );
};

export default connect(() => ({}), mapDispatchToProps)(ArticleActions);
