import React from 'react';
import {Box, Card, CardContent, CardMedia, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {getFreakPlaceTypeBackgroundColor, getFreakPlaceTypeColor} from "../../utils/commonUtils";
import ReadMoreComponent from "../ReadMoreComponent";
import CircleIcon from "@mui/icons-material/Circle";
import useTranslation from "../../customHooks/translations";

const FreakPlaceCard = (props) => {
  const translation = useTranslation();
  const freakPlace = props.freakPlace;

  return (
    <Card
      key={freakPlace.id}
      sx={{
        display: 'flex',
        borderRadius: 2,
        width: '100%',
        marginBottom: 1,
        cursor: 'pointer',
        maxHeight: 150,
      }}
      onClick={() => props.handleCardClick(freakPlace)}
    >
      <CardMedia
        component="img"
        image={freakPlace?.images[0]?.imageUrl || './logo.png'}
        alt="Trail"
        sx={{
          width: 150,
          height: 'auto',
          objectFit: 'cover',
          borderRadius: 2,
          margin: 1,
        }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: 'auto',
          width: 'calc(100% - 150px)',  // Adjust for the image width
        }}
      >
        <Box sx={{
          maxHeight: 140,
        }}>
          {
            freakPlace.type
              ? <Button
                variant="contained"
                style={{
                  minWidth: 18,
                  maxWidth: 150,
                  height: 15,
                  fontSize: 10,
                  backgroundColor: getFreakPlaceTypeBackgroundColor(freakPlace.type),
                  color: getFreakPlaceTypeColor(freakPlace.type)
                }}
                disabled
              >
                {translation.freakPlaces[freakPlace.type.name]}
              </Button>
              : null
          }

          <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
            {freakPlace.title}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', marginY: 1 }}>
            <Typography sx={{ fontSize: 12, marginRight: 1 }}>
              <ReadMoreComponent
                maxChars={25}
                hideReadMore={true}
                fontSize={12}
                text={freakPlace?.map?.description || freakPlace.description}
              />
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CircleIcon sx={{ color: '#FFB400', fontSize: 12, marginRight: 1 }} />
            <Typography sx={{ fontSize: 10, }}>
              {translation.freakPlacesList.fromThisPlace} {freakPlace?.map?.Freak?.length || 0}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default  FreakPlaceCard;
