import React, {useState} from 'react';
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Button from "@mui/material/Button";
import useTranslation from "../../customHooks/translations";
import {Global} from "@emotion/react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {styled} from "@mui/material/styles";
import {Card, IconButton} from "@mui/material";
import CardContent from "@mui/joy/CardContent";
import {ButtonGroup, Container} from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import GifIcon from "@mui/icons-material/Gif";
import agent from "../../agent";
import GiphyComponent from "./GiphyComponent";
import {ADD_COMMENT} from "../../constants/actionTypes";
import {connect} from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import "./style.css"
import {getLanguageByBrowser} from "../../utils/languageUtils";
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';


const StyledBox = styled('div')(({theme}) => ({
  // backgroundColor: 'white',
}));

const Puller = styled('div')(({theme}) => ({
  width: 30,
  height: 6,
  // backgroundColor: 'white',
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const blue = {
  100: '#d6f5ec',
  200: '#adecd8',
  400: '#57d1b4',
  500: '#36c9a2',  // Base color
  600: '#31b58e',
  900: '#1f7961',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    &:hover {
      border-color: ${blue[400]};
    }
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const PostCommentComponent = (props) => {
  const translation = useTranslation();
  const drawerBleeding = 0;

  const [open, setOpen] = useState(false);
  const [gifsVisible, setGifsVisible] = useState(false);
  const [stickersVisible, setStickersVisible] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleChange = (event) => {
    setEditorState(event)
  };

  const createComment = (ev, gif) => {
    ev?.preventDefault();

    let payload = {};
    const gifId = gif ? gif.id : null;

    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    const html = draftToHtml(raw);
    payload = agent.Comments.createForFreak(
      props.slug,
      { body: html, gifId }
    );
    props.onSubmit(payload);
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{
          color: 'white',
          backgroundColor: DEFAULT_THEME_COLOR,
          borderColor: DEFAULT_THEME_COLOR,
          'z-index': '0',
          outline: 'none',
          '&:focus': {
            outline: 'none'
          },
          '&:hover': {
            color: 'black',
            borderColor: 'black',
          },
          marginBottom: 10,
          marginLeft: 1,
        }}
        variant="outlined"
        startIcon={<InsertCommentIcon />}
        onClick={() => {
          setOpen(true);
        }}
       >
       {translation.comments.postComment}
      </Button>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(100% - ${drawerBleeding}px)`,
            overflow: 'visible',
            // backgroundColor: 'white'
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            // borderTopLeftRadius: 8,
            // borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            // backgroundColor: 'white',
            marginTop: 'env(safe-area-inset-top, 50px)',
          }}
        >
          <Puller/>
          <Card sx={{
            height: '100vh',
            boxShadow: 'none',
            // backgroundColor: 'white'
          }}>
            <CardContent>
              <Container maxWidth="lg" sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '20px 0',
              }}>
                <ButtonGroup
                  fullWidth
                  orientation="horizontal"
                >
                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor: 'white',
                      color: DEFAULT_THEME_COLOR,
                      // borderColor: DEFAULT_THEME_COLOR,
                      'z-index': '0',
                      borderRadius: 4,
                      maxWidth: 200,
                      flexGrow: 1,
                      margin: 5,
                    }}
                    onClick={() => setOpen(false)}
                  >
                    {translation.comments.cancel}
                  </Button>

                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: DEFAULT_THEME_COLOR,
                      color: 'white',
                      borderColor: DEFAULT_THEME_COLOR,
                      'z-index': '0',
                      borderRadius: 4,
                      maxWidth: 200,
                      flexGrow: 1,
                      margin: 5,
                      '&:hover': {
                        color: DEFAULT_THEME_COLOR,
                        borderColor: DEFAULT_THEME_COLOR,
                      }
                    }}
                    onClick={() =>  {
                      createComment();
                      setOpen(false)
                    }}
                  >
                    {translation.comments.add}
                  </Button>
                </ButtonGroup>
              </Container>
              <Editor
                style={{
                  height: gifsVisible ? '30vh': '80vh',
                }}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName editor-border"
                onEditorStateChange={handleChange}
                toolbar={{
                  options: [
                    'link',
                    'emoji',
                  ]
                }}
                localization={{
                  locale: getLanguageByBrowser(),
                }}
              />
              {/*<div className="card-footer">*/}
                <IconButton
                  aria-label="gif"
                  onClick={() => {
                    setGifsVisible(!gifsVisible);
                    setStickersVisible(false);
                  }}
                  style={{
                    borderRadius: '50%',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    color: 'white',
                    backgroundColor: DEFAULT_THEME_COLOR,
                    width: 30,
                    height: 30,
                    marginLeft: 10,
                  }}
                >
                  <GifIcon />
                </IconButton>
              {/*</div>*/}
              {
                gifsVisible
                  ? <GiphyComponent
                    createComment={createComment}
                    setGifsVisible={setGifsVisible}
                  />
                  : null
              }
            </CardContent>
          </Card>
        </StyledBox>
      </SwipeableDrawer>
    </>
  )
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  onSubmit: payload =>
    dispatch({type: ADD_COMMENT, payload})
});

export default connect(mapStateToProps, mapDispatchToProps)(PostCommentComponent);

