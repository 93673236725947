import React from 'react';
import InfiniteScroll from "react-infinite-scroller";
import CommonLoader from "../Loaders/CommonLoader";
import FreakPreview from "./FreakPreview";

const FreakListForPlace = (props) => {
  const hasMore = false;
  const loadMoreFreaks = () => {};

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreFreaks}
        hasMore={hasMore}
        loader={
          <CommonLoader />
        }
      >
        {
          props.freaks?.map(freak => {
            return (
              <FreakPreview
                freak={freak}
                key={freak.slug}
              />
            );
          })
        }
      </InfiniteScroll>
    </>
  );
};

export default FreakListForPlace;
