import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import useTranslation from "../../customHooks/translations";
import Grid from "@mui/material/Grid";
import {Link, useNavigate} from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import {formatDate, formatDateAgo} from "../../utils/dateUtils";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ReadMoreComponent from "../ReadMoreComponent";
import Box from "@mui/material/Box";
import {
  COLOR_IN_PROGRESS,
  MESSAGE_IN_PROGRESS,
  STATUS_FINISHED,
  STATUS_IN_PROGRESS,
  STATUS_PLANNED
} from "../../constants/commonConstants";
import {
  getCampaignTypeLabel,
  getCampaignTypeLabelColor,
  getPreviewCarousel,
  getStatusMessage,
  getVisibilityLevel
} from "../../utils/commonUtils";
import { CAMPAIGN_FAVORITED, CAMPAIGN_UNFAVORITED } from "../../constants/actionTypes";
import agent from "../../agent";
import ButtonGroup from "@mui/material/ButtonGroup";
import {isFreakOwner} from "../../utils/freakUtils";
import {
  getVisibilityLevelColor,
} from '../../utils/commonUtils';

const FAVORITED_CLASS = 'btn btn-sm btn-primary';
const NOT_FAVORITED_CLASS = 'btn btn-sm btn-outline-primary';

const getCampaignTimeMessage = (campaign, translation) => {
  if (!campaign?.startDate) {
    return '';
  }

  switch (campaign.status) {
    case STATUS_IN_PROGRESS:
      return translation.freakPreview.messages.alreadyRunning;
    case STATUS_FINISHED:
      return `${translation.freakPreview.messages.finishedAt}:  ${formatDate(campaign.endDate)}`;
    case STATUS_PLANNED:
      return `${translation.freakPreview.messages.plannedTo}: ${formatDate(campaign.startDate)}`;
  }
};

const FreakCampaignsPreview = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const campaign = props.campaign;

  const favoriteButtonClass = campaign.favorited ? FAVORITED_CLASS : NOT_FAVORITED_CLASS;
  const [startButtonMessage, setStartButtonMessage] = useState(translation.freaks.operations.start);
  const [startButtonDisabled, setStartButtonDisabled] = useState(false);
  const [startButtonColor, setStartButtonColor] = useState(DEFAULT_THEME_COLOR);
  const statusButtonMessage = getStatusMessage(campaign, translation);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    // set proper message when campaign is in progress
    if (campaign && campaign.status === STATUS_IN_PROGRESS) {
      setStartButtonMessage(MESSAGE_IN_PROGRESS);
      setStartButtonColor(COLOR_IN_PROGRESS);
    }
  }, []);

  const handleDotsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDotsClose = () => {
    setAnchorEl(null);
  };

  const handleFavoritedClick = ev => {
    ev.preventDefault();
    if (campaign.favorited) {
      props.unfavorite(campaign.slug);
    } else {
      props.favorite(campaign.slug);
    }
  };

  const handleMessageClick = slug => {
    navigate(`/campaigns/${slug}`);
  };

  // const handleStartClick = freak => {
  //   agent.Campaigns.start(freak.id);
  //   setStartButtonMessage(translation.freaks.status.inProgress);
  //   setStartButtonDisabled(true);
  // };

  const isDesktop = props.device === 'desktop';
  return (
    <div
      className="article-preview"
      style={isDesktop ? {
        maxWidth: 500,
      } : {}}
    >
      <Grid container spacing={0} sx={{flexGrow: 1}}>
        <Grid item xs={12}>
          <div className="article-meta">
            <Grid container xs={12}>
              <Grid item xs={4} sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}>
                <Grid item>
                  <Link to={`/@${campaign.author.username}`}>
                    <Avatar
                      alt={campaign.author.username}
                      src={campaign.author.imageUrl}
                    />
                  </Link>
                </Grid>
                <Grid item>
                  <div className="info">
                    <Link className="author" to={`/@${campaign.author.username}`}>
                      {campaign.author.username}
                    </Link>
                    <span className="date">
                      {formatDateAgo(campaign.createdAt)}
                  </span>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton sx={{ color: 'white' }} aria-label="back" onClick={handleDotsClick}>
                    <MoreVertIcon
                      sx={{
                        color: DEFAULT_THEME_COLOR
                      }}
                    />
                  </IconButton>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleDotsClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem onClick={() => navigate(`/report-abuse/${campaign.slug}`)}>{translation.reportAbuse.report}</MenuItem>
                  </Menu>
                </div>
              </Grid>
            </Grid>

            <div
              className="info"
              style={{
                color: 'grey',
                fontSize: '.8rem',
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                marginTop: 10,
              }}
            >
              <Button
                variant="contained"
                style={{
                  marginTop: 0,
                  width: 80,
                  height: 10,
                  fontSize: 10,
                  backgroundColor: getVisibilityLevelColor(campaign),
                  color: 'white'
                }}
                disabled
              >
                {translation.freaks.visibility[(getVisibilityLevel(campaign))]}
              </Button>
              <Button
                variant="contained"
                style={{
                  marginTop: 0,
                  marginLeft: 10,
                  width: 80,
                  height: 10,
                  fontSize: 10,
                  backgroundColor: getCampaignTypeLabelColor(campaign),
                  color: 'white'
                }}
                disabled
              >
                {translation.freaks.visibility[(getCampaignTypeLabel(campaign))]}
              </Button>
              <br />
              {getCampaignTimeMessage(campaign, translation)}
            </div>
          </div>
          <Link to={`/campaigns/${campaign.slug}`} className="preview-link">
            <h1>{campaign.title}</h1>
            <ReadMoreComponent text={campaign.body} />
          </Link>

          <Box xs={12} sx={{ marginBottom: 2 }}>
            {
              getPreviewCarousel(campaign)
            }
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        sx={{flexGrow: 1}}
      >
        <Grid item xs={7}>
          <div className="padding-5">
            <button className={favoriteButtonClass} onClick={handleFavoritedClick}>
              <i className="ion-heart"></i> {campaign.favoritesCount}
            </button>
            {/*<button className="btn btn-sm btn-outline-primary" onClick={() => handleMessageClick(campaign.slug)}>*/}
            {/*  <i className="ion-chatbox"></i> {campaign.commentsCount}*/}
            {/*</button>*/}
          </div>
        </Grid>
        <Grid item xs={5}>
          <ButtonGroup
            orientation="vertical"
            variant="outlined"
          >
            <Button
              size="md"
              variant="contained"
              disabled
              style={{
                width: 150,
                backgroundColor: DEFAULT_THEME_COLOR,
                color: 'white',
                alignSelf: 'flex-end',
                'z-index': '0',
                borderRadius: 4,
              }}
            >
              {statusButtonMessage}
            </Button>
            {/*{*/}
            {/*  (campaign?.status === STATUS_PLANNED && isFreakOwner(props.currentUser.username, campaign))*/}
            {/*    ? <Button*/}
            {/*      disabled={startButtonDisabled}*/}
            {/*      onClick={() => handleStartClick(freak)}*/}
            {/*      style={{*/}
            {/*        color: startButtonColor,*/}
            {/*        borderColor: DEFAULT_THEME_COLOR,*/}
            {/*        width: 150,*/}
            {/*        alignSelf: 'flex-end',*/}
            {/*        'z-index': '0',*/}
            {/*        borderRadius: 4,*/}
            {/*      }}*/}
            {/*      variant="outlined"*/}
            {/*    >*/}
            {/*      {startButtonMessage}*/}
            {/*    </Button>*/}
            {/*    : null*/}
            {/*}*/}
          </ButtonGroup>
        </Grid>
      </Grid>
    </div>
  )
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  device: state.common.device,
});

const mapDispatchToProps = dispatch => ({
  favorite: slug => dispatch({
    type: CAMPAIGN_FAVORITED,
    payload: agent.Campaigns.favorite(slug)
  }),
  unfavorite: slug => dispatch({
    type: CAMPAIGN_UNFAVORITED,
    payload: agent.Campaigns.unfavorite(slug)
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakCampaignsPreview);
