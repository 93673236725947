import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';

import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
import {MapContainer, TileLayer, Marker, Popup, Polyline} from 'react-leaflet';

import {Icon} from 'leaflet'

import TextField from '@mui/material/TextField';
import useTranslation from "../../customHooks/translations";
import IconButton from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {DEFAULT_THEME_COLOR} from "../../constants/theme";

const FreakLocalization = (props) => {
  const translation = useTranslation();
  const [position, setPosition] = useState([50.258598, 19.020420]);
  const [searchValue, setSearchValue] = useState('');
  const [nominationSearchData, setNominationSearchData] = useState(null);
  const [showSugestions, setShowSugestions] = useState(false);
  const mapRef = useRef(null);
  const mapZoom = 14;

  useEffect(() => {
    if (props?.localization?.coordinates) {
      const lat = props.localization.coordinates.latitude;
      const lon = props.localization.coordinates.longitude;
      setPosition([lat, lon]);
      setSearchValue(props.localization.description);
    }
  }, [props?.localization])

  const nominatimSearchLatLon = async (lat, lon) => {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`);
    const results = await response.json();

    if (Array.isArray(results)) {
      setNominationSearchData(results);
      setSearchValue(results[0].display_name);
    } else {
      setNominationSearchData([results])
      setSearchValue(results.display_name);
    }

    setShowSugestions(false);
  };

  const nominatimSearchAddress = async (address) => {
    const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`);
    const results = await response.json();

    if (Array.isArray(results)) {
      setNominationSearchData(results);
    } else {
      setNominationSearchData([results])
    }

    setShowSugestions(true);

    return results;
  };

  const handleCurrentLocation = () => {
    const lat = props?.localization?.coordinates?.latitude;
    const lon = props?.localization?.coordinates?.longitude;
    if (lat && lon) {
      setPosition([lat, lon]);
      setSearchValue(props.localization.description);
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSelect = async (sugestion) => {
    setSearchValue(sugestion.display_name);
    setShowSugestions(false);

    try {
      const placeId = sugestion.place_id;
      const lat = parseFloat(sugestion.lat);
      const lng = parseFloat(sugestion.lon);
      setPosition([lat, lng]);
      const localizationMeta = {
        title: placeId,
        description: sugestion.display_name,
        coordinates: {
          latitude: lat,
          longitude: lng
        }
      };
      props.onSelect(localizationMeta);
    } catch (error) {
      console.error('Error selecting location:', error);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(position, mapZoom);
    }
  }, [position]);

  const freakLocationIcon = new Icon({
    iconUrl: '/icons/pin.png',
    iconSize: [32, 32],
    iconAnchor: [16, 32]
  });

  const myLocationIcon = new Icon({
    iconUrl: '/icons/location-pin.png',
    iconSize: [32, 32],
    iconAnchor: [16, 32]
  });

  function DraggableMarker() {
    const [draggable, setDraggable] = useState(false);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            const newPosition = marker.getLatLng();
            setPosition(newPosition);

            let localizationMeta = {};

            const currentNominationSearchData = Array.isArray(nominationSearchData) ? nominationSearchData[0] : nominationSearchData;
            if (currentNominationSearchData) {
              localizationMeta = {
                title: currentNominationSearchData.place_id,
                description: currentNominationSearchData.display_name,
                coordinates: {
                  latitude: newPosition.lat,
                  longitude: newPosition.lng
                }
              };
              props.onSelect(localizationMeta);
            } else {
              localizationMeta = {
                title: props.localization.id,
                description: props.localization.description,
                coordinates: {
                  latitude: newPosition.lat,
                  longitude: newPosition.lng
                }
              };
            }

            props.onSelect(localizationMeta);
          }
        },
      }),
      [],
    );
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d)
    }, []);

    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        icon={freakLocationIcon}
        ref={markerRef}
      >
        <Popup minWidth={90}>
        <span onClick={toggleDraggable}>
          {
            draggable
            ? translation.freakLocalization.markerIsDraggable
            : translation.freakLocalization.clickToDragMarker
          }
        </span>
        </Popup>
      </Marker>
    )
  }

  return (
    <div>
      { props.viewOnly
        ? null
        : <>
          <Grid container>
            <Grid item xs={2}>
              <IconButton
                style={{
                  backgroundColor: 'transparent',
                }}
                sx={{
                  outline: 'none',
                  '&:focus': {
                    outline: 'none'
                  },
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  },
                }}
                onClick={handleCurrentLocation}
              >
                <MyLocationIcon sx={{ color: DEFAULT_THEME_COLOR, height: 25, width: 25}}/>
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                id="outlined-basic"
                label={translation.freakLocalization.placeholders.search}
                variant="outlined"
                value={searchValue}
                onChange={handleSearchChange}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                style={{
                  backgroundColor: 'transparent',
                }}
                sx={{
                  outline: 'none',
                  '&:focus': {
                    outline: 'none'
                  },
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  },
                }}
                onClick={() => {
                  setSearchValue('');
                }}
              >
                <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR, height: 25, width: 25}}/>
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                style={{
                  backgroundColor: 'transparent',
                }}
                sx={{
                  outline: 'none',
                  '&:focus': {
                    outline: 'none'
                  },
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  },
                }}
                onClick={() => {
                  nominatimSearchAddress(searchValue)
                }}
              >
                <SearchIcon sx={{ color: DEFAULT_THEME_COLOR, height: 25, width: 25}}/>
              </IconButton>
            </Grid>
          </Grid>
          <ul>
            {showSugestions && nominationSearchData?.map((suggestion) => (
                <li key={suggestion.place_id} onClick={() => handleSelect(suggestion)}>
                  {suggestion.display_name}
                </li>
              ))}
          </ul>
        </>
      }
      <MapContainer
        center={position}
        zoom={mapZoom}
        style={{ height: '50vh', width: '100wh' }}
        ref={mapRef}
        attributionControl={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
        />
        {
          props.hideDraggableMarker
            ? null
            : <DraggableMarker />
        }
        {
          props.myLocation
            ? <Marker position={props.myLocation} icon={myLocationIcon} />
            : null
        }
        {
          props.markers
            ? props.markers.map(marker => (
              <Marker position={position}>
                <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
              </Marker>
            ))
           : null
        }
        {
          props.route
          ? <Polyline pathOptions={{color: 'blue'}} positions={props.route}/>
            : null
        }
      </MapContainer>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state.freak,
  currentUser: state.common.currentUser
});

export default connect(mapStateToProps, {})(FreakLocalization);
