import React from 'react';

// 1. show owner
// 2. show participants
// 3. show all information like in the list
// 4. Ask to join/cancel buttons
//    4.1 Ask to join -> send request to owner
//    4.2 Back to list of freaks
const FreakJoin = (props) => {
  return (
    <div>FreakJoin</div>
  )
};

export default FreakJoin;
