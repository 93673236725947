import React, {useEffect, useState} from "react";
import agent from "../../agent";
import { FacebookEmbed, InstagramEmbed } from "react-social-media-embed";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import {IconButton} from "@mui/material";
import ConfirmationModal from "../ConfirmationModal";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";

const ThumbnailsEmbed = ({ embeds, handleDeleteFn, embedType }) => {
  const [open, setOpen] = useState(false);
  const [selectedEmbed, setSelectedEmbed] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [embedToDelete, setEmbedToDelete] = useState(null);

  useEffect(() => {
    setThumbnails(embeds);
  }, [embeds.length]);

  const handleOpen = (embed) => {
    setSelectedEmbed(embed);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (embed, handleDeleteFn, embedType) => {
    let updatedThumbnails = [];
    if (embed?.id) {
      switch (embedType) {
        case 'facebook':
          agent.Freaks.deleteFacebookLink(embed.freakId, embed.id);
          break;
        case 'instagram':
          agent.Freaks.deleteInstagramLink(embed.freakId, embed.id);
          break;
      }
      updatedThumbnails = thumbnails.filter(thumbnail => thumbnail.id !== embed.id);
    } else {
      updatedThumbnails = thumbnails.filter(thumbnail => thumbnail.name !== embed.name);
    }

    setThumbnails(updatedThumbnails);
    handleDeleteFn(updatedThumbnails);
  };

  const handleOpenModal = (embedToDelete) => {
    setModalOpen(true);
    setEmbedToDelete(embedToDelete);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const prepareEmbed = (embedType, thumbnail) => {
    switch (embedType) {
      case 'facebook':
        return (
          <FacebookEmbed url={thumbnail.imageUrl} width={150} height={200} />
        );
      case 'instagram':
        return (
          <InstagramEmbed url={thumbnail.imageUrl} width={150} height={200} />
        );
    }
  };

  return (
    <ImageList cols={3} rowHeight={164}>
      {
        thumbnails?.map((thumbnail, index) => (
          <>
            <ImageListItem key={thumbnail.id}>
              { prepareEmbed(embedType, thumbnail) }
              <IconButton
                style={{ position: 'absolute', top: '10px', right: '5px', color: DEFAULT_THEME_COLOR }}
                onClick={() => handleOpenModal(thumbnail)}
              >
                <DeleteIcon />
              </IconButton>
              <ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => handleCloseModal()}
                onConfirm={() => {
                  handleDelete(embedToDelete, handleDeleteFn, embedType);
                  setModalOpen(false);
                }}
              />
            </ImageListItem>
          </>
        ))
      }
    </ImageList>
  )
};

export default ThumbnailsEmbed;
