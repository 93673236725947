import React, {useState} from 'react';
import {
  Drawer,
  Box,
  CssBaseline,
} from "@mui/material";
import FreakPlaceCard from "./FreakPlaceCard";
import TextField from '@mui/material/TextField';
import useTranslation from "../../customHooks/translations";

const FreakPlacesList = (props) => {
  const translation = useTranslation();

  const [filteredFreakPlaces, setFilteredFreakPlaces] = useState(props.freakPlaces);
  const drawerWidth = 500;

  const filterPlaces = filterValue => {
    if (!filterValue) {
      setFilteredFreakPlaces(props.freakPlaces);
      return;
    }

    const loweredFilterValue = filterValue.toLowerCase();
    const filteredPlaces = props.freakPlaces?.filter(freakPlace => {
      // check description
      // check title
      // check type.name
      if (freakPlace?.description?.toLowerCase()?.includes(loweredFilterValue) ||
        freakPlace?.title?.toLowerCase()?.includes(loweredFilterValue) ||
        freakPlace?.type?.name?.toLowerCase()?.includes(loweredFilterValue)
      ) {
        return freakPlace;
      }
    });

    setFilteredFreakPlaces(filteredPlaces || []);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            height: '100vh',
            overflowY: 'auto',
          },
        }}
        variant="persistent"
        anchor="right"
        open
      >

        <Box sx={{ padding: 2 }}>
          <div>
            <TextField
              label={translation.freakPlacesList.filter}
              id="filter-freak-places"
              defaultValue={''}
              size="normal"
              sx={{
                width: '100%',
                marginBottom: 2,
              }}
              onChange={(event) => {
                filterPlaces(event.target.value);
              }}
            />
          </div>
          {filteredFreakPlaces?.map(freakPlace => (
            <FreakPlaceCard freakPlace={freakPlace} handleCardClick={props.handleCardClick} />
          ))}
        </Box>
      </Drawer>
    </Box>
  );
};

export default FreakPlacesList;