import React, {useEffect, useState} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {Dialog, DialogContent, IconButton} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import agent from '../agent';
import ConfirmationModal from "./ConfirmationModal";
import {DEFAULT_THEME_COLOR} from "../constants/theme";

const Thumbnails = ({ images, handleDeleteFn }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  useEffect(() => {
    setThumbnails(images);
  }, [images.length]);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (image, handleDeleteFn) => {
    let updatedThumbnails = [];
    if (image?.id) {
      // @todo refactor this
      image.original
        ? agent.Freaks.deleteYouTubeLink(image.freakId, image.id)
        : agent.Images.del(image.id);
      updatedThumbnails = thumbnails.filter(thumbnail => thumbnail.id !== image.id);
    } else {
      updatedThumbnails = thumbnails.filter(thumbnail => thumbnail.name !== image.name);
    }

    setThumbnails(updatedThumbnails);
    handleDeleteFn(updatedThumbnails);
  };

  const handleOpenModal = (imageToDelete) => {
    setModalOpen(true);
    setImageToDelete(imageToDelete);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <ImageList cols={3} rowHeight={164}>
      {thumbnails?.map((item, idx) => (
        <>
          <ImageListItem key={idx}>
            <img
              srcSet={`${item.imageUrl}`}
              src={`${item.imageUrl}`}
              alt={item.name}
              loading="lazy"
            />
            <IconButton
              style={{ position: 'absolute', top: '10px', right: '5px', color: DEFAULT_THEME_COLOR }}
              onClick={() => handleOpenModal(item)}
            >
              <DeleteIcon />
            </IconButton>
            <ConfirmationModal
              isOpen={isModalOpen}
              onClose={() => handleCloseModal()}
              onConfirm={() => {
                handleDelete(imageToDelete, handleDeleteFn);
                setModalOpen(false);
              }}
            />
          </ImageListItem>
          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent sx={{padding: 0}}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{position: 'absolute', top: 8, right: 10}}
              >
                {/*<CloseIcon />*/}
              </IconButton>
              <img
                src={selectedImage?.imageUrl}
                alt={selectedImage?.name}
                style={{maxWidth: '100%', maxHeight: '100%'}}
              />
            </DialogContent>
          </Dialog>
        </>
      ))}
    </ImageList>
  );
}

export default Thumbnails;
