import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";

import FreakPreview from './FreakPreview';
import useTranslation from "../../customHooks/translations";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Button from "@mui/material/Button";
import RssFeedIcon from '@mui/icons-material/RssFeed';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InfiniteScroll from "react-infinite-scroller";
import agent from "../../agent";
import {FREAKS_SET_PAGE} from "../../constants/actionTypes";
import CommonLoader from "../Loaders/CommonLoader";

const FreakList = props => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [freaks, setFreaks] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
    setFreaks([]);
    setHasMore(true);
    setIsLoading(true);

    loadMoreFreaks(0);
  }, [
    props.tab,
    props.context,
    props.latitudeFilter,
    props.longitudeFilter,
    props.distanceFilter,
    props.sortByLikesFilter,
    props.sortByDateFilter,
    props.filterByNameFilter,
    props.filterByDateFromFilter,
    props.filterByDateToFilter,
    props.filterByStatusFilter,
    props.filterByParticipantFilter,
  ]);

  const loadMoreFreaks = async (__page) => {
    if (hasMore) {
      setIsLoading(true);

      let results = { freaks: [] };

      if (!props.tab) {
        results = await agent.Freaks.feed(
          page,
          props.context,
          props.device,
          props.latitudeFilter,
          props.longitudeFilter,
          props.distanceFilter,
          props.sortByLikesFilter,
          props.sortByDateFilter,
          props.filterByNameFilter,
          props.filterByDateFromFilter,
          props.filterByDateToFilter,
          props.filterByStatusFilter,
          props.filterByParticipantFilter,
          props.profileUsername || props.currentUser.username
        );
      } else if (props.tab === 'daily') {
         results = await agent.Feeds.daily(
           page,
           props.context,
           props.device,
           props.latitudeFilter,
           props.longitudeFilter,
           props.distanceFilter,
           props.sortByLikesFilter,
           props.sortByDateFilter,
           props.filterByNameFilter,
           props.filterByDateFromFilter,
           props.filterByDateToFilter,
           props.filterByStatusFilter,
           props.filterByParticipantFilter,
           props.currentUser.username,
         );
      } else if (props.tab === 'favourites') {
        results = await agent.Freaks.favoritedBy(props.profileUsername, page, props.context, props.device)
      } else {
        results = await agent.Freaks[props.tab](
          page,
          props.context,
          props.device,
          props.latitudeFilter,
          props.longitudeFilter,
          props.distanceFilter,
          props.sortByLikesFilter,
          props.sortByDateFilter,
          props.filterByNameFilter,
          props.filterByDateFromFilter,
          props.filterByDateToFilter,
          props.filterByStatusFilter,
          props.filterByParticipantFilter,
          props.currentUser.username,
        );
      }

      setFreaks([...freaks, ...results.freaks]);
      setPage(page + 1);

      setIsLoading(false);

      if (results?.freaks?.length === 0) {
        setHasMore(false);
      }
    }
  };

  if (isLoading === false && freaks?.length === 0 && !props.errorGeoLocation) {
    return (
      <div style={{
             minHeight: 150,
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             textAlign: 'center',
          }}>
        <span style={{ fontSize: '15px' }}>
          {
            props.tab === 'daily'
             ? <Button
                sx={{
                  backgroundColor: DEFAULT_THEME_COLOR,
                  color: 'white',
                  borderColor: 'white',
                  'z-index': '0',
                  marginLeft: 1,
                  marginRight: 1,
                  marginTop: 2,
                  marginBottom: 2,
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  }
                }}
                variant="outlined"
                startIcon={<RssFeedIcon />}
                onClick={() => {
                  navigate('/settings');
                }}
              >
                {translation.freaks.emptyListDaily}
              </Button>
            : null
          }
          {
            props.tab === 'followed'
            ? <Button
                sx={{
                  backgroundColor: DEFAULT_THEME_COLOR,
                  color: 'white',
                  borderColor: 'white',
                  'z-index': '0',
                  marginLeft: 1,
                  marginRight: 1,
                  marginTop: 2,
                  marginBottom: 2,
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  }
                }}
                variant="outlined"
                startIcon={<PersonSearchIcon />}
                onClick={() => {
                  navigate('/top-freaks');
                }}
              >
                {translation.freaks.emptyListFollowed}
              </Button>
            : null
          }
          {
            props.tab !== 'daily' && props.tab !== 'followed'
             ? <Button
                sx={{
                  backgroundColor: DEFAULT_THEME_COLOR,
                  color: 'white',
                  borderColor: 'white',
                  'z-index': '0',
                  marginLeft: 1,
                  marginRight: 1,
                  marginTop: 2,
                  marginBottom: 2,
                  '&:hover': {
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_THEME_COLOR,
                  }
                }}
                variant="outlined"
              >
                {translation.freaks.emptyList}
              </Button>
             : null
          }
        </span>
      </div>
    );
  }

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreFreaks}
        hasMore={hasMore}
        loader={
          <CommonLoader />
        }
      >
        {
          freaks?.map(freak => {
            return (
              <FreakPreview
                freak={freak}
                key={freak.slug}
              />
            );
          })
        }
      </InfiniteScroll>
    </>
  );
};

const mapStateToProps = state => ({
  ...state.freakList,
  device: state.common.device,
  currentUser: state.common.currentUser,
  distanceFilter: state.common.distanceFilter,
  sortByLikesFilter: state.common.sortByLikesFilter,
  sortByDateFilter: state.common.sortByDateFilter,
  filterByNameFilter: state.common.filterByNameFilter,
  filterByDateFromFilter: state.common.filterByDateFromFilter,
  filterByDateToFilter: state.common.filterByDateToFilter,
  filterByStatusFilter: state.common.filterByStatusFilter,
  filterByParticipantFilter: state.common.filterByParticipantFilter,
  latitudeFilter: state.common.latitudeFilter,
  longitudeFilter: state.common.longitudeFilter,
});

const mapDispatchToProps = dispatch => ({
  onSetPage: (page, payload) =>
    dispatch({ type: FREAKS_SET_PAGE, page, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakList);
