import React from 'react';
import Box from "@mui/material/Box";
import TopBar from "../TopBar";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Button from "@mui/material/Button";
import useTranslation from "../../customHooks/translations";
import {useNavigate} from "react-router-dom";
import Avatar from "@mui/material/Avatar";

const Error404View = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <TopBar />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div style={{
          alignItems: 'center'
        }}>
          <Avatar
            alt={'logo'}
            src={'./logo.png'}
            sx={{
              width: 250,
              height: 250,
            }}
          />
          <Button
            sx={{
              backgroundColor: DEFAULT_THEME_COLOR,
              color: 'white',
              borderColor: DEFAULT_THEME_COLOR,
              'z-index': '0',
              marginLeft: 1,
              marginTop: 2,
              marginRight: 1,
              marginBottom: 2,
              '&:hover': {
                color: 'black',
                borderColor: 'white',
              },
              width: 250,
            }}
            variant="outlined"
            onClick={() => {
              navigate('/freak');
            }}
          >
            {translation.errors.error404Message}
          </Button>
        </div>
      </Box>
    </>
  );
};

export default Error404View;
