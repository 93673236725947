import {
  FREAK_FAVORITED,
  FREAK_UNFAVORITED,
  FREAKS_CHANGE_TAB,
  FREAKS_PAGE_UNLOADED,
  FREAKS_SET_PAGE,
  FREAKS_PAGE_LOADED,

  PROFILE_PAGE_LOADED,
  PROFILE_FAVORITES_PAGE_LOADED,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch(action.type) {
    case FREAK_FAVORITED:
    case FREAK_UNFAVORITED:
      return {
        ...state,
        freaks: state.freaks?.map(freak => {
          if (action.payload && action.payload.freak && freak.slug === action.payload.freak.slug) {
            return {
              ...freak,
              favorited: action.payload.freak.favorited,
              favoritesCount: action.payload.freak.favoritesCount
            };
          }
          return freak;
        })
      };
    case FREAKS_CHANGE_TAB:
      return {
        ...state,
        pager: action.pager,
        freaks: action.payload.freaks,
        freaksCount: action.payload.freaksCount,
        tab: action.tab,
        currentPage: 0,
        tag: null
      };
    case FREAKS_PAGE_LOADED:
    case PROFILE_PAGE_LOADED:
    case PROFILE_FAVORITES_PAGE_LOADED:
      return {
        ...state,
        pager: action.pager,
        tags: action.payload[0] && action.payload[0].tags,
        freaks: action.payload[1] && action.payload[1].freaks,
        freaksCount: action.payload[1] && action.payload[1].freaksCount,
        currentPage: 0,
        tab: action.tab
      };
    case FREAKS_PAGE_UNLOADED:
      return {};
    case FREAKS_SET_PAGE:
      return {
        ...state,
        freaks: action.payload.freaks,
        freaksCount: action.payload.freaksCount,
        currentPage: action.page
      };
    default:
      return state;
  }
};
