import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import useTranslation from "../../customHooks/translations";
import { useNavigate, useParams } from "react-router-dom";
import agent from "../../agent";
import NotFoundComponent from "../NotFoundComponent";
import {
  CAMPAIGN_PAGE_LOADED,
  CAMPAIGN_PAGE_UNLOADED,
  CAMPAIGN_SUBMITTED,
  UPLOAD_FREAK_IMAGE,
  UPLOAD_FREAK_VIDEO
} from "../../constants/actionTypes";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Typography from "@mui/material/Typography";
import FreakMeta from "./FreakMeta";
import ImageViewComponent from "../ImageView/ImageViewComponent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import InfoIcon from "@mui/icons-material/Info";
import Grid from "@mui/material/Grid";
import AccordionDetails from "@mui/material/AccordionDetails";
import {calculateDuration, formatDate} from "../../utils/dateUtils";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FreakParticipantsList from "./FreakParticipantsList";
import {useMediaQuery} from "react-responsive";
import {isFeed} from "../../utils/freakUtils";
import marked from "marked";
import HandshakeIcon from '@mui/icons-material/Handshake';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ConfirmationModal from "../ConfirmationModal";

const FreakCampaignDetails = (props) => {
  const translation = useTranslation();
  const {id: campaignId} = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [oldTitle, setOldTitle] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [linksYoutube, setLinksYoutube] = useState([]);
  const [linksFacebook, setLinksFacebook] = useState([]);
  const [linksInstagram, setLinksInstagram] = useState([]);
  const [body, setBody] = useState('');
  const [participants, setParticipants] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);

  const device = props.device;

  const [isLoaded, setIsLoaded] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    const fetchData = async () => {
      const formData = await agent.Campaigns.get(campaignId, device);

      if (formData) {
        setTitle(formData?.campaign?.title || '');
        setOldTitle(formData?.campaign?.title || '');
        setImages(formData?.campaign?.images || []);
        setVideos(formData?.campaign?.videos || []);
        setLinksYoutube(formData?.campaign?.linksYoutube || []);
        setLinksFacebook(formData?.campaign?.linksFacebook || []);
        setLinksInstagram(formData?.campaign?.linksInstagram || []);
        setBody(formData?.campaign?.body || '');
        setParticipants(formData?.campaign?.participants || []);
        setStartDate(formData?.campaign?.startDate || null);
        setIsPrivate(!formData?.campaign?.public);

        setIsLoaded(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    props.onLoad(Promise.all([
      agent.Campaigns.get(campaignId, device),
      agent.Comments.forCampaign(campaignId)
    ]));

    props.onUnload();
  }, []);

  if (!isLoaded || !props.campaign || !props.campaign.body) {
    return (
      <NotFoundComponent text={"Loading..."}/>
    );
  }

  const markup = {
    __html: !isFeed(props.campaign)
      ? marked(props.campaign.body, {sanitize: true})
      : marked(props.campaign.body.replace(/\"/g, '').replace(/@/g, '\n- '), {sanitize: true})
  };
  const canModify = props.currentUser &&
    props.currentUser.username === props.campaign.author.username;
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div
      className="article-page"
      style={{
        paddingLeft,
        marginBottom: 100,
      }}
    >
      <div className="banner">
        <IconButton sx={{color: 'white'}} aria-label="back" onClick={() => {
          navigate('/campaigns');
        }}>
          <ArrowBackIosNewOutlinedIcon sx={{
            marginRight: 2,
            marginTop: 1,
            color: DEFAULT_THEME_COLOR
          }}
          />
          <Typography variant="h4" sx={{color: 'white'}}>
            {title}
          </Typography>
        </IconButton>
        <div className="container">
          <FreakMeta
            freak={props.campaign}
            canModify={canModify}
          />
        </div>
      </div>
      <div className="container page">
        <fieldset className="form-group">
          {
            images?.length
              ? <ImageViewComponent images={images} showThumbnails={true}/>
              : null
          }
        </fieldset>

        <fieldset className="form-group">
          <Accordion
            defaultExpanded
            sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Grid container>
                <Grid item xs={12}>
                  <h3>{translation.freakDetails.description}</h3>
                </Grid>
              </Grid>

            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div dangerouslySetInnerHTML={markup}></div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        <fieldset className="form-group">
          <Accordion
            defaultExpanded
            sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                }}
              >
                <InfoIcon sx={{color: DEFAULT_THEME_COLOR, width: 25, height: 25}}/>
              </IconButton>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h2" sx={{marginTop: 1, color: DEFAULT_THEME_COLOR}}>
                    {translation.freakDetails.information}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {translation.freakDetails.informationDescription}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      {translation.freakDetails.plannedTo}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="title1"
                        color="text.primary"
                        component="div"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {formatDate(props.campaign.startDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      {translation.freakDetails.startedAt}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="title1"
                        color="text.primary"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                      >
                        { props.campaign.startedAt ? formatDate(props.campaign.startedAt) : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      {translation.freakDetails.finishedAt}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="title1"
                        color="text.primary"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                      >
                        {props.campaign.finishedAt ? formatDate(props.campaign.finishedAt) : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      {translation.freakDetails.duration}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="title1"
                        color="text.primary"
                        component="div"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {props.campaign.startedAt && props.campaign.finishedAt ? calculateDuration(props.campaign.startedAt, props.campaign.finishedAt) : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        <fieldset className="form-group">
          <Accordion
            defaultExpanded
            sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                }}
              >
                <PeopleAltIcon sx={{color: DEFAULT_THEME_COLOR, width: 25, height: 25}}/>
              </IconButton>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h2" sx={{marginTop: 1, color: DEFAULT_THEME_COLOR}}>
                    {translation.freakDetails.participants}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {translation.freakCampaign.participantsDescription}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {
                props?.campaign?.participants?.length
                  ? <FreakParticipantsList
                    freakId={props.campaign.id}
                    author={props.campaign.author}
                    participants={props.campaign.participants}
                    isAuthor={props?.campaign?.author?.username === props?.currentUser?.username}
                  />
                  : <div>{translation.freakDetails.noParticipants}</div>
              }
            </AccordionDetails>
          </Accordion>
        </fieldset>

        <fieldset className="form-group">
          <Accordion
            sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
          >
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <IconButton
                sx={{
                  '&:focus': {
                    outline: 'none'
                  },
                }}
              >
                <HandshakeIcon sx={{color: DEFAULT_THEME_COLOR, width: 25, height: 25}}/>
              </IconButton>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h2" sx={{marginTop: 1, color: DEFAULT_THEME_COLOR}}>
                    {translation.freakCampaign.cooperation}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {translation.freakCampaign.cooperationBody}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <div>{translation.freakCampaign.contact} : <span style={{ color: DEFAULT_THEME_COLOR}}>
                freakland.app@gmail.com
              </span></div>
              <div>{translation.freakCampaign.requestContact}
                <IconButton
                  sx={{
                    '&:focus': {
                      outline: 'none'
                    },
                  }}
                onClick={handleOpenModal}
              >
                <ConnectWithoutContactIcon sx={{color: DEFAULT_THEME_COLOR, width: 25, height: 25}}/>
              </IconButton></div>
            </AccordionDetails>
          </Accordion>
        </fieldset>

        {/*<div className="row">*/}
        {/*  <CommentContainer*/}
        {/*    type={'freaks'}*/}
        {/*    comments={props.comments || []}*/}
        {/*    errors={props.commentErrors}*/}
        {/*    slug={campaignId}*/}
        {/*    currentUser={props.currentUser}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        hideUndone={true}
        onConfirm={() => {
          agent.Campaigns.requestContact();
          handleCloseModal();
        }}/>
    </div>
  )
};

const mapStateToProps = state => ({
  ...state.campaign,
  currentUser: state.common.currentUser,
  device: state.common.device,
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload =>
    dispatch({type: CAMPAIGN_PAGE_LOADED, payload}),
  onUnload: () =>
    dispatch({type: CAMPAIGN_PAGE_UNLOADED}),
  onFileUpload: payload => {
    if (payload && payload.data) {
      const fileType = payload.type;
      const fileData = payload.data;
      const action = fileType === 'images' ? UPLOAD_FREAK_IMAGE : UPLOAD_FREAK_VIDEO;
      const prop = fileType === 'images' ? 'imagesToUpload' : 'videosToUpload';
      dispatch({
        type: action,
        payload: {
          [prop]: fileData.filename
        }
      });
    }
  },
  onSubmit: payload =>
    dispatch({type: CAMPAIGN_SUBMITTED, payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakCampaignDetails);
