import ListErrors from './ListErrors';
import React from 'react';
import agent from '../agent';
import {connect} from 'react-redux';
import {
  SETUP_PASSWORD,
  SETUP_PASSWORD_UNLOADED,
} from '../constants/actionTypes';
import { validatePassword } from '../utils/passwordUtils';
import TopBar from "./TopBar";
import useTranslation from "../customHooks/translations";

const urlParams = new URLSearchParams(window.location.search);

const PASSWORD_NOT_MATCH_MESSAGE = 'password not match';
const PASSWORD_MISSING_REQUIRED_PARAMS_IN_URL = 'missing required params in url';


const SetupPassword = (props) => {
  const translation = useTranslation();

  const changePassword = ev => props.onChangePassword(ev.target.value, props.confirm_password);
  const confirmPassword = ev => props.onConfirmPassword(ev.target.value, props.password);
  const submitForm = (email) => ev => {
    ev.preventDefault();
    props.onSubmit(email);
  };

  const password = props.password;

  return (
    <>
      <TopBar />
      <div className="auth-page">
        <div className="container page">
          <div className="row">

            <div className="col-md-6 offset-md-3 col-xs-12">
              <h1 className="text-xs-center">{translation.setupPassword.setupNew}</h1>

              <ListErrors errors={props.errors}/>

              <form onSubmit={submitForm(password)}>
                <fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="password"
                      placeholder={translation.setupPassword.placeholders.password}
                      value={props.password}
                      onChange={changePassword} />
                  </fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="password"
                      placeholder={translation.setupPassword.placeholders.confirmPassword}
                      value={props.confirm_password}
                      onChange={confirmPassword} />
                  </fieldset>

                  <button
                    className="btn btn-lg btn-primary pull-xs-right"
                    type="submit"
                    disabled={props.inProgress}>
                    {translation.setupPassword.confirm}
                  </button>

                </fieldset>
              </form>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({...state.auth});

const mapDispatchToProps = dispatch => ({
  onChangePassword: (password, confirmPassword) => {
    // @todo validate password: 8chars, 1number, 1special_char
    let error = false;
    let payload = { password, confirm_password: confirmPassword };
    if (confirmPassword !== password) {
      error = true;
      payload = { ...payload, errors: [PASSWORD_NOT_MATCH_MESSAGE]}
    }
    dispatch({type: SETUP_PASSWORD, error, payload});
  },
  onConfirmPassword: (confirmPassword, password) => {
    let error = false;
    let payload = { password, confirm_password: confirmPassword };
    if (confirmPassword !== password) {
      error = true;
      payload = { ...payload, errors: [PASSWORD_NOT_MATCH_MESSAGE] };
    }
    dispatch({type: SETUP_PASSWORD, error, payload })
  },
  onSubmit: password => {
    let payload = {};
    const userId = urlParams.get('user_id');
    const token = urlParams.get('token');

    const errors = validatePassword(password);
    if (errors && errors.length) {
      payload = { ...payload, errors };
      dispatch({type: SETUP_PASSWORD, error: true, payload})
    } else if (userId && token) {
      payload = agent.Auth.setupPassword(userId, token, password);
      dispatch({type: SETUP_PASSWORD, error: false, payload})
      dispatch({type: SETUP_PASSWORD_UNLOADED})
    } else {
      payload = { errors: [PASSWORD_MISSING_REQUIRED_PARAMS_IN_URL]}
      dispatch({type: SETUP_PASSWORD, error: true, payload})
    }
  },
  onUnload: () => {
    dispatch({type: SETUP_PASSWORD_UNLOADED})
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupPassword);
