import {
  ADD_TAG,
  FREAK_EDITOR_PAGE_UNLOADED,
  FREAK_EDITOR_PAGE_LOADED,
  UPDATE_FIELD_FREAK_EDITOR,
  UPLOAD_FREAK_IMAGE,
  UPLOAD_FREAK_VIDEO,
  FREAK_SUBMITTED,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case FREAK_EDITOR_PAGE_LOADED:
      return {
        ...state,
        freakSlug: action.payload ? action.payload.freak.slug : '',
        title: action.payload ? action.payload.freak.title : '',
        description: action.payload ? action.payload.freak.description : '',
        images: action.payload ? action.payload.freak.images : '',
        body: action.payload ? action.payload.freak.body : '',
        tagInput: '',
        tagList: action.payload ? action.payload.freak.tagList : [],
        startDate: action.payload ? action.payload.freak.startDate : null,
        participants: action.payload ? action.payload.freak?.participants : [],
        map: action.payload ? action.payload.freak?.map : {},
        isPrivate: action.payload ? !action.payload.freak.public : null
      };
    case FREAK_EDITOR_PAGE_UNLOADED:
      return {};
    case FREAK_SUBMITTED:
      return {
        ...state,
        inProgress: null,
        errors: action.error ? action.payload.errors : null
      };
    case UPDATE_FIELD_FREAK_EDITOR:
      return { ...state, [action.key]: action.value };
    case ADD_TAG:
      return {
        ...state,
        tagList: state.tagList.concat([state.tagInput]),
        tagInput: ''
      };
    case UPLOAD_FREAK_IMAGE:
      return {
        ...state,
        imagesToUpload: action.payload ? action.payload.imagesToUpload : '',
        errors: action.error ? action.payload.errors : null
      };
    case UPLOAD_FREAK_VIDEO:
      return {
        ...state,
        videosToUpload: action.payload ? (state.videosToUpload || []).concat(action.payload.videosToUpload) : '',
        errors: action.error ? action.payload.errors : null
      };
    default:
      return state;
  };
};
