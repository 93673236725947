import React, { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const FreakTimeTracker = (props) => {
  const { action, onTimeUpdate } = props;

  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [isRunning, setIsRunning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (isRunning) {
      const id = setInterval(() => {
        setTime(prevTime => {
          const newSeconds = prevTime.seconds + 1;
          const newMinutes = prevTime.minutes + Math.floor(newSeconds / 60);
          const newHours = prevTime.hours + Math.floor(newMinutes / 60);
          const updatedTime = {
            hours: newHours % 24,
            minutes: newMinutes % 60,
            seconds: newSeconds % 60
          };

          if (onTimeUpdate) {
            const formattedTime = `${formatTime(updatedTime.hours)}:${formatTime(updatedTime.minutes)}:${formatTime(updatedTime.seconds)}`;
            onTimeUpdate(formattedTime);
          }

          return updatedTime;
        });
      }, 1000);

      setIntervalId(id);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  useEffect(() => {
    handleAction(action);
  }, [action]);

  const handleStart = () => {
    setIsRunning(true);
  };

  const handlePause = () => {
    setIsRunning(false);
  };

  const handleFinish = () => {
    setIsRunning(false);
    setTime({ hours: 0, minutes: 0, seconds: 0 });
  };

  const handleAction = (action) => {
    switch (action) {
      case 'start':
        handleStart();
        break;
      case 'pause':
        handlePause();
        break;
      case 'finish':
        handleFinish();
        break;
      default:
        // Handle invalid action
        break;
    }
  };

  const formatTime = (value) => {
    return value.toString().padStart(2, '0');
  };

  return (
    <Grid item xs={12}
          sx={{
            marginTop: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
    >
      <Typography variant="h2" sx={{
        marginLeft: 1,
        // color: 'black'
      }}>
        {`${formatTime(time.hours)}:${formatTime(time.minutes)}:${formatTime(time.seconds)}`}
      </Typography>
    </Grid>
  )
};

export default FreakTimeTracker;
